import { useState } from "react";
import {
  Box,
  CircularProgress,
  Divider,
  IconButton,
  InputBase,
  List,
  ListItemButton,
  ListItemText,
  Modal,
  Paper,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { generatePath, useNavigate } from "react-router";
import {
  keepPreviousData,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { useDebounce } from "../../hooks/useDebounce";
import { orderAPI } from "../../api/orderAPI";
import { ORDER_PAGE_ROUTE } from "../../pages/Order/OrderPage";
import { Flag } from "../Flag";
import { useBreakpointDown } from "../../hooks/useBreakpoint";
import { AnimatePresence, motion } from "framer-motion";

interface Props {
  open: boolean;
  onClose: () => void;
}

const style = {
  position: "absolute",
  top: "10%",
  left: "50%",
  transform: "translate(-50%, 0)",
  maxWidth: "500px",
  width: "100%",
  bgcolor: "background.paper",
  boxShadow: 24,
  display: "flex",
  flexDirection: "column",
};

export const SearchModal = ({ open, onClose }: Props) => {
  const [search, setSearch] = useState<string>("");
  const debouncedSearch = useDebounce<typeof search>(search, 250);
  const isNarrow = useBreakpointDown("sm");

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const handleClose = () => {
    setSearch("");
    queryClient.resetQueries({ queryKey: [orderAPI.QUERY_KEY, "search"] });
    onClose();
  };

  const {
    data: results,
    isLoading,
    isFetched,
    isError,
    refetch: triggerSearch,
  } = useQuery({
    ...orderAPI.searchOrders(0, 999, debouncedSearch),
    enabled: debouncedSearch.length > 0,
    placeholderData: keepPreviousData,
  });

  return (
    <Modal open={open} onClose={handleClose}>
      <Paper
        sx={{
          ...style,
          ...(isNarrow && {
            transform: "none",
            top: 0,
            width: "100%",
            left: "auto",
            maxWidth: "none",
          }),
        }}
        component="form"
        onSubmit={(event) => {
          event.preventDefault();
          triggerSearch();
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            p: 2,
          }}
        >
          <InputBase
            fullWidth
            placeholder="Search..."
            value={search}
            onChange={(event) => {
              setSearch(event.target.value);
            }}
            autoFocus
            error={isError || results?.numberOfElements === 0}
            endAdornment={
              <Typography
                variant="overline"
                color="textSecondary"
                sx={{
                  whiteSpace: "nowrap",
                  px: 1,
                }}
              >
                {search && results && results?.numberOfElements > 0 && (
                  <span>{results?.numberOfElements} hits</span>
                )}
              </Typography>
            }
          />
          {isLoading && (
            <IconButton type="button" sx={{ p: 1, mr: 1 }} aria-label="search">
              <CircularProgress color="inherit" size={24} />
            </IconButton>
          )}
          {!isLoading && (
            <IconButton
              type="button"
              sx={{ p: 1 }}
              onClick={() => {
                triggerSearch();
              }}
            >
              <SearchIcon />
            </IconButton>
          )}
        </Box>
        {isFetched && results?.numberOfElements === 0 && (
          <>
            <Divider variant="middle" />
            <Typography
              variant="overline"
              align="center"
              color="textSecondary"
              sx={{
                p: 2,
              }}
            >
              No results
            </Typography>
          </>
        )}

        <AnimatePresence>
          {debouncedSearch && results && results.numberOfElements > 0 && (
            <motion.div
              initial={{
                opacity: 0,
                height: 0,
              }}
              animate={{
                opacity: 1,
                height: "auto",
              }}
              exit={{
                opacity: 0,
                height: 0,
              }}
              style={{ overflow: "hidden" }}
            >
              <>
                <Divider variant="middle" />
                <List
                  sx={{
                    maxHeight: "400px",
                    overflow: "auto",
                  }}
                >
                  {results.content?.map((result) => (
                    <ListItemButton
                      divider
                      key={result.orderId}
                      dense
                      onClick={() => {
                        navigate(
                          generatePath(ORDER_PAGE_ROUTE, {
                            orderId: result.orderId,
                          })
                        );
                        handleClose();
                      }}
                    >
                      <ListItemText
                        primary={
                          <>
                            <Flag country={result.country} /> {result.orderId}
                          </>
                        }
                        secondary={[result.email, result.city]
                          .filter(Boolean)
                          .join(" - ")}
                      />
                    </ListItemButton>
                  ))}
                </List>
              </>
            </motion.div>
          )}
        </AnimatePresence>
      </Paper>
    </Modal>
  );
};
