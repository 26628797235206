import { useParams } from "react-router";
import { CampaignId } from "../api/campaignAPI";

type Param = {
  campaignId: CampaignId;
};

export function useCampaignId() {
  const { campaignId } = useParams<Param>();
  return campaignId as CampaignId;
}
