import { useMutation, useQuery } from "@tanstack/react-query";
import { FunctionComponent, useState } from "react";
import {
  Button,
  ButtonProps,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import LogoutIcon from "@mui/icons-material/Logout";
import { useNavigate } from "react-router";
import { authAPI } from "../../api/authAPI";
import { LOGIN_PAGE_ROUTE } from "../../pages/Auth/LoginPage";

export const UserInfo: FunctionComponent<ButtonProps> = ({ ...props }) => {
  const navigate = useNavigate();

  const { data } = useQuery(authAPI.whoami());

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const { mutate: handleLogout } = useMutation({
    mutationFn: authAPI.logout,
    onSuccess: () => {
      handleClose();
      navigate(LOGIN_PAGE_ROUTE);
    },
  });

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        startIcon="👋"
        variant="outlined"
        color="inherit"
        disableElevation
        {...props}
      >
        {data?.displayName || "Not logged in"}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <MenuItem onClick={() => handleLogout()}>
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText>Logout</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};
