import {
  Button,
  ButtonProps,
  IconButton,
  IconButtonProps,
} from "@mui/material";
import { useSnackbar } from "../../hooks/useSnackbar";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

interface ClipboardButtonProps extends ButtonProps {
  value: string;
  feedbackMessage?: string;
}

export const ClipboardButton = ({
  feedbackMessage = "Copied to clipboard!",
  value,
  ...props
}: ClipboardButtonProps) => {
  const { showSnackbar } = useSnackbar();
  const handleClick = () => {
    try {
      navigator.clipboard.writeText(value);
      showSnackbar(feedbackMessage, "info");
    } catch (error) {
      console.log(error);
    }
  };

  return <Button {...props} onClick={handleClick} />;
};

interface ClipboardIconButtonProps extends IconButtonProps {
  value: string;
  feedbackMessage?: string;
}

export const ClipboardIconButton = ({
  feedbackMessage = "Copied to clipboard!",
  value,
  ...props
}: ClipboardIconButtonProps) => {
  const { showSnackbar } = useSnackbar();
  const handleClick = () => {
    try {
      navigator.clipboard.writeText(value);
      showSnackbar(feedbackMessage, "info");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <IconButton {...props} onClick={handleClick}>
      <ContentCopyIcon fontSize="inherit" />
    </IconButton>
  );
};
