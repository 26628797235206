import {
  Grid2 as Grid,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { useTransition } from "react";
import { SortDirection } from "../../api/network/Page";
import { Layout } from "../../layout/Layout";
import { printAPI } from "../../api/printAPI";
import { useSuspenseQuery } from "@tanstack/react-query";
import { FormattedTime } from "../../components/FormattedTime/FormattedTime";

const DEFAULT_PAGE_SIZE = 50;

export const PRINT_LIST_PAGE_ROUTE = "/print";

export const PrintListPage = () => {
  return (
    <Layout fullHeight title="Print queue" skeleton={<PageSkeleton />}>
      <InnerPrintListPage />
    </Layout>
  );
};

const InnerPrintListPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isPendingTransition, startTransition] = useTransition();

  const page = searchParams.get("page") || "0";
  const size = searchParams.get("size") || DEFAULT_PAGE_SIZE.toString();

  const { data: result } = useSuspenseQuery(
    printAPI.fetchPrintQueue(
      parseInt(page),
      parseInt(size),
      SortDirection.DESC,
      "created"
    )
  );

  // const handleSearch = (result: Search[]) => {
  //   if (result.length === 1) {
  //     navigate(
  //       generatePath(ORDER_PAGE, {
  //         articleId: result[0].articleId,
  //       })
  //     );
  //     return;
  //   }
  // };

  return (
    <>
      {/* <Search onSearch={handleSearch} /> */}
      <TableContainer sx={{ flex: 1 }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Print</TableCell>
              <TableCell>Created</TableCell>
              <TableCell>Printed</TableCell>
            </TableRow>
          </TableHead>
          <TableBody
            sx={{
              opacity: isPendingTransition ? 0.5 : 1,
              transition: "opacity 0.15s ease-in-out",
            }}
          >
            {result?.content?.map((item) => (
              <TableRow key={item.id}>
                <TableCell>{item.id}</TableCell>
                <TableCell>
                  <FormattedTime value={item.created} />
                </TableCell>
                <TableCell>
                  <FormattedTime value={item.printed} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, DEFAULT_PAGE_SIZE, 100]}
        component="div"
        count={result?.totalElements || 0}
        rowsPerPage={result?.size || DEFAULT_PAGE_SIZE}
        page={result?.number || 0}
        onPageChange={(_, page) => {
          startTransition(() => {
            setSearchParams({
              size: searchParams.get("size") || DEFAULT_PAGE_SIZE.toString(),
              page: page.toString(),
            });
          });
        }}
        onRowsPerPageChange={(event) => {
          startTransition(() => {
            setSearchParams({
              page: searchParams.get("page") || "0",
              size: event.target.value,
            });
          });
        }}
      />
    </>
  );
};

const PageSkeleton = () => {
  return (
    <>
      <Grid container spacing={2}>
        <Grid size="grow">
          <Skeleton variant="rounded" height={60} />
        </Grid>
        <Grid width={80}>
          <Skeleton variant="rounded" height={60} />
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid size={12}>
          <Skeleton variant="rounded" height={40} />
        </Grid>
        {[...Array(10)].map((_, i) => (
          <Grid key={i} size={12}>
            <Skeleton variant="rounded" height={50} />
          </Grid>
        ))}
      </Grid>
    </>
  );
};
