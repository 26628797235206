import { Typography, Box, Button } from "@mui/material";
import { motion } from "framer-motion";
import { Layout } from "../layout/Layout";
import { generatePath, Link } from "react-router-dom";

export const NotFoundPage = () => {
  return (
    <Layout fullHeight title="404 Not Found">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          flex: 1,
          m: 5,
        }}
      >
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
            component={motion.div}
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{
              type: "spring",
              stiffness: 100,
              damping: 8,
            }}
          >
            <Typography variant="h2" align="center" gutterBottom>
              😵
            </Typography>
          </Box>
          <Typography
            variant="h5"
            align="center"
            fontWeight={600}
            marginBottom={3}
          >
            Oops! Something went wrong.
          </Typography>
          <Typography
            variant="body2"
            align="center"
            color="textSecondary"
            marginBottom={3}
          >
            We couldn't find this page.
          </Typography>
          <Button component={Link} to={generatePath("/")} fullWidth>
            Go home
          </Button>
        </Box>
      </Box>
    </Layout>
  );
};
