import React, { useMemo } from "react";

interface Props {
  value: number;
  currency?: string;
  country?: string;
}

export const FormattedPrice: React.FC<Props> = ({
  value,
  currency = "EUR",
  country = "sv-SE",
}) => {
  const formattedPrice = useMemo(
    () =>
      new Intl.NumberFormat(country, {
        style: "currency",
        currency,
      }).format(value),
    [country, currency, value]
  );

  return <>{formattedPrice}</>;
};
