import {
  Box,
  Button,
  Paper,
  Skeleton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { QueryErrorResetBoundary } from "@tanstack/react-query";
import { forwardRef, PropsWithChildren, ReactNode, Suspense } from "react";
import { useLocation } from "react-router";
import { Helmet } from "../components/Helmet";
import { ErrorBoundary } from "react-error-boundary";

interface Props {
  fullHeight?: boolean;
  fullScreen?: boolean;
  title: string;
  skeleton?: ReactNode;
  skeletonGap?: number;
  background?: string;
}

export const Layout = forwardRef<HTMLDivElement, PropsWithChildren<Props>>(
  (
    {
      fullHeight = false,
      fullScreen = false,
      title,
      skeleton,
      skeletonGap = 0,
      children,
      background,
    },
    ref
  ) => {
    const theme = useTheme();
    const desktop = useMediaQuery(theme.breakpoints.up("md"));
    const location = useLocation();

    return (
      <>
        <Helmet title={title} />
        <Box
          ref={ref}
          sx={{
            px: fullScreen ? 0 : desktop ? 2 : 1,
            pt: fullScreen ? 0 : desktop ? 2 : 1,
            position: fullHeight ? "relative" : "static",
            maxWidth: (theme) => theme.breakpoints.values.lg,
            margin: "0 auto",
            backgroundColor: background || "transparent",
            ...(fullHeight
              ? {
                  height: fullScreen
                    ? "100vh"
                    : desktop
                    ? "100vh"
                    : "calc(100vh - 56px)",
                  // overflow: "scroll",
                  display: "flex",
                  flexDirection: "column",
                }
              : {
                  marginBottom: desktop ? 0 : "70px",
                  paddingBottom: "70px",
                }),
          }}
        >
          <QueryErrorResetBoundary>
            {({ reset }) => (
              <>
                <ErrorBoundary
                  key={location.pathname}
                  onReset={reset}
                  fallbackRender={({ error, resetErrorBoundary }) => (
                    <>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          height: "80vh",
                          p: fullScreen ? 0 : desktop ? 2 : 1,
                        }}
                      >
                        <Typography variant="h5" gutterBottom>
                          Oops, something went wrong
                        </Typography>
                        <Typography variant="subtitle1" gutterBottom>
                          Couldn't load the page, try again later
                        </Typography>
                        {/* if development */}
                        {import.meta.env.DEV && (
                          <Paper sx={{ p: 2 }}>
                            <pre
                              style={{
                                whiteSpace: "pre-wrap",
                                wordBreak: "break-word",
                                margin: 0,
                                maxHeight: "40vh",
                                overflowY: "auto",
                              }}
                            >
                              {error.stack}
                            </pre>
                          </Paper>
                        )}

                        <Button
                          onClick={resetErrorBoundary}
                          variant="outlined"
                          size="large"
                          sx={{ mt: 2 }}
                        >
                          Try again
                        </Button>
                      </Box>
                    </>
                  )}
                >
                  <Suspense
                    fallback={
                      <Box>
                        {skeleton ? (
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: skeletonGap,
                            }}
                          >
                            {skeleton}
                          </Box>
                        ) : (
                          <>
                            <h1>
                              <Skeleton variant="text" />
                            </h1>
                            <div>
                              <Skeleton variant="rectangular" />
                              <Skeleton variant="rectangular" height={120} />
                            </div>
                          </>
                        )}
                      </Box>
                    }
                  >
                    {children}
                  </Suspense>
                </ErrorBoundary>
              </>
            )}
          </QueryErrorResetBoundary>
        </Box>
      </>
    );
  }
);
