import { FunctionComponent, useCallback, useState } from "react";
import {
  Box,
  Button,
  FormHelperText,
  Grid2 as Grid,
  Input,
  SwipeableDrawer,
  TextField,
  Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { Controller, useForm, useWatch } from "react-hook-form";
import { LoadingButton } from "@mui/lab";
import { CreateOfferAttachmentRequest, offerAPI } from "../../../api/offerAPI";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useOfferId } from "../../../hooks/useOfferId";
import { useSnackbar } from "../../../hooks/useSnackbar";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const Puller = () => {
  return (
    <Box
      sx={{
        width: 30,
        height: 6,
        backgroundColor: grey[300],
        borderRadius: 3,
        position: "absolute",
        top: 8,
        left: "calc(50% - 15px)",
      }}
    />
  );
};

const drawerBleeding = 56;

export const CreateOfferAttachmentSheet: FunctionComponent<Props> = ({
  isOpen,
  onClose,
}) => {
  const [key, setKey] = useState(0);

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={isOpen}
      onClose={() => {
        onClose();
        setKey((prev) => prev + 1);
      }}
      onOpen={() => {
        // do nothing
      }}
      swipeAreaWidth={drawerBleeding}
      disableSwipeToOpen={true}
      PaperProps={{
        sx: {
          borderTopLeftRadius: 10,
          borderTopRightRadius: 10,
        },
      }}
    >
      <Box
        sx={{
          maxWidth: 600,
          margin: "0 auto",
          position: "relative",
        }}
      >
        <Puller />
        <Box sx={{ p: 2 }}>
          <InnerSheet {...{ onClose, isOpen }} key={key} />
        </Box>
      </Box>
    </SwipeableDrawer>
  );
};

const InnerSheet: FunctionComponent<Props> = ({ onClose }) => {
  const queryClient = useQueryClient();
  const offerId = useOfferId();
  const { showSnackbar } = useSnackbar();
  const [isSaving, _setIsSaving] = useState(false);

  const { mutate: saveAttachment } = useMutation({
    ...offerAPI.createAttachment(offerId),
    onSuccess: () =>
      queryClient.invalidateQueries(offerAPI.getAttachments(offerId)),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm<CreateOfferAttachmentRequest>({
    defaultValues: {
      title: "",
      description: "",
    },
  });

  const file = useWatch({ control, name: "file" });

  const onSubmit = useCallback(
    (data: CreateOfferAttachmentRequest) => {
      saveAttachment(data, {
        onSuccess: () => {
          showSnackbar("Created attachment", "success");
          onClose();
          reset();
        },
        onError: () => {
          showSnackbar("Failed to create attachment", "error");
        },
      });
    },
    [onClose, reset, saveAttachment, showSnackbar]
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        pt: 4,
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid size={12}>
            <Controller
              name="title"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  fullWidth
                  label="Title"
                  id="title"
                  error={!!errors[field.name]}
                  helperText={
                    (errors[field.name]?.message || errors[field.name]?.type) ??
                    " "
                  }
                  required
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid size={12}>
            <Controller
              name="description"
              control={control}
              rules={{ required: false }}
              render={({ field }) => (
                <TextField
                  multiline
                  minRows={3}
                  maxRows={10}
                  fullWidth
                  label="Description"
                  id="description"
                  error={!!errors[field.name]}
                  helperText={
                    (errors[field.name]?.message || errors[field.name]?.type) ??
                    " "
                  }
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid mb={2} size={12}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 2,
              }}
            >
              <Button
                component="label"
                role={undefined}
                variant="outlined"
                tabIndex={-1}
                startIcon={<CloudUploadIcon />}
              >
                Select file
                <Controller
                  name="file"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, ...field } }) => (
                    <Input
                      type="file"
                      id="file"
                      error={!!errors[field.name]}
                      onChange={(event) => {
                        if (event.target instanceof HTMLInputElement) {
                          const file = event.target?.files?.[0];
                          onChange(file);
                        }
                      }}
                      {...field}
                      value={""}
                      sx={{
                        clip: "rect(0 0 0 0)",
                        clipPath: "inset(50%)",
                        height: 1,
                        overflow: "hidden",
                        position: "absolute",
                        bottom: 0,
                        left: 0,
                        whiteSpace: "nowrap",
                        width: 1,
                      }}
                    />
                  )}
                />
              </Button>
              <Typography variant="body2" color="text.secondary">
                {file
                  ? file?.name + (file?.size ? ` (${file?.size} bytes)` : "")
                  : "No file selected"}
              </Typography>
            </Box>
            <FormHelperText error={!!errors.file} variant="filled">
              {(errors.file?.message || errors.file?.type) ?? " "}
            </FormHelperText>
          </Grid>
          <Grid size={12}>
            <LoadingButton
              fullWidth
              type="submit"
              variant="contained"
              color="primary"
              loading={isSaving}
              size="large"
            >
              Add attachment
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};
