import { PaletteMode, ThemeProvider, colors, createTheme } from "@mui/material";
import {
  PropsWithChildren,
  createContext,
  useEffect,
  useMemo,
  useState,
} from "react";

export type ThemeContextProps = {
  toggleTheme: () => void;
};

export const ThemeContext = createContext<ThemeContextProps>({
  toggleTheme: () => {
    // do nothing
  },
});

const baseTheme = createTheme({
  palette: {
    primary: {
      main: "#FFB367",
      contrastText: colors.common.white,
    },
    secondary: {
      main: "#926AFF",
      contrastText: colors.common.white,
    },
  },
});

export const ThemeContextProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const [mode, setMode] = useState<PaletteMode>(
    (localStorage.getItem("theme") as PaletteMode | null) || "dark"
  );

  const value = useMemo(
    () => ({
      toggleTheme: () => {
        setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
      },
    }),
    []
  );

  useEffect(() => {
    localStorage.setItem("theme", mode);
  }, [mode]);

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode,
          ...(mode === "light"
            ? {
                primary: {
                  main: "#ff8000",
                  contrastText: colors.common.white,
                },
                secondary: {
                  main: "#926AFF",
                  contrastText: colors.common.white,
                },
                background: {
                  default: "#f9f7fc",
                },
              }
            : {
                primary: {
                  main: "#FFB367",
                },
                secondary: {
                  main: "#926AFF",
                },
                background: {
                  default: "#05051F",
                  paper: "#01011c",
                },
              }),
        },
        shape: {
          borderRadius: 10,
        },
        typography: {
          overline: {
            fontWeight: 500,
          },
        },
        components: {
          MuiTableCell: {
            styleOverrides: {
              root:
                mode === "dark"
                  ? { borderColor: "rgba(255, 255, 255, 0.2)" }
                  : {},
            },
          },
          MuiListItemButton: {
            styleOverrides: {
              root: {
                borderTopRightRadius: 10,
                borderBottomRightRadius: 10,
                "&.Mui-selected": {
                  background: `linear-gradient(90deg, ${
                    baseTheme.palette.secondary.main + "50"
                  } 0%, transparent 100%)`,
                },
              },
            },
          },
          MuiCardContent: {
            styleOverrides: {
              root: {
                "&:last-child": {
                  paddingBottom: 16,
                },
              },
            },
          },
          MuiCard: {
            defaultProps: {
              variant: mode === "light" ? "outlined" : "elevation",
            },
          },
          MuiLink: {
            styleOverrides: {
              root: {
                fontWeight: "800",
              },
            },
          },
          MuiDialog: {
            styleOverrides: {
              container: {
                [baseTheme.breakpoints.down("sm")]: {
                  alignItems: "end",
                },
              },
            },
          },
        },
      }),
    [mode]
  );

  return (
    <ThemeContext.Provider value={value}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ThemeContext.Provider>
  );
};
