import { AnimatePresence, motion, useIsPresent } from "framer-motion";
import {
  Box,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Skeleton,
  Typography,
} from "@mui/material";
import { Flag } from "../../../components/Flag";
import { generatePath } from "react-router";
import { ORDER_PAGE_ROUTE } from "../../Order/OrderPage";
import { Link } from "react-router-dom";
import { FormattedPrice } from "../../../components/FormattedPrice/FormattedPrice";
import { FormattedTime } from "../../../components/FormattedTime/FormattedTime";
import { dashboardAPI, DashboardOrder } from "../../../api/dashboardAPI";
import { Suspense, useEffect, useRef } from "react";
import { useQueryClient, useSuspenseQuery } from "@tanstack/react-query";
import { FeedbackSound, useFeedback } from "../../../hooks/useFeedback";
import AdsClickRoundedIcon from "@mui/icons-material/AdsClickRounded";
import CampaignRoundedIcon from "@mui/icons-material/CampaignRounded";

const NUM_OF_ORDERS = 10;

export const LatestOrdersList: React.FunctionComponent = () => {
  return (
    <Suspense fallback={<ComponentSkeleton />}>
      <Card>
        <CardContent>
          <Typography
            sx={{ fontSize: 16 }}
            color="text.secondary"
            fontWeight={700}
            gutterBottom
          >
            Latest orders
          </Typography>
          <Inner />
        </CardContent>
      </Card>
    </Suspense>
  );
};

const ComponentSkeleton = () => {
  return (
    <Box display={"flex"} gap={2} flexDirection={"column"}>
      {[...Array(NUM_OF_ORDERS)].map((_, index) => (
        <Skeleton
          variant="rounded"
          height={52}
          sx={{ borderRadius: 1 }}
          key={index}
        />
      ))}
    </Box>
  );
};

const Inner = () => {
  const queryClient = useQueryClient();
  const isInitialDataFetched = useRef(false);
  const feedback = useFeedback(FeedbackSound.KACHING);

  const { data } = useSuspenseQuery({
    ...dashboardAPI.fetchLatestOrder(NUM_OF_ORDERS),
    refetchInterval: 1000 * 60,
  });

  useEffect(() => {
    if (!isInitialDataFetched.current) {
      isInitialDataFetched.current = true;
      return;
    }

    feedback();

    queryClient.invalidateQueries({
      queryKey: dashboardAPI.fetchStatistics().queryKey,
    });
    queryClient.invalidateQueries({
      queryKey: dashboardAPI.fetchWeeklyGraph().queryKey,
    });
  }, [queryClient, data, feedback]);

  return (
    <List
      component={motion.ul}
      initial="hidden"
      animate="show"
      sx={{
        listStyle: "none",
        padding: 0,
        margin: 0,
        ml: -2,
        mr: -2,
      }}
    >
      <AnimatePresence initial={false}>
        {data.map((item) => (
          <LatestOrderListItem key={item.orderId} order={item} />
        ))}
      </AnimatePresence>
    </List>
  );
};

export const LatestOrderListItem: React.FunctionComponent<{
  order: DashboardOrder;
}> = ({ order }) => {
  const isPresent = useIsPresent();

  return (
    <ListItem
      component={motion.li}
      sx={{
        position: isPresent ? "static" : "absolute",
        display: "flex",
        alignContent: "center",
        listStyle: "none",
        padding: 0,
        margin: 0,
      }}
      initial={{
        opacity: 0,
        scale: 0,
      }}
      animate={{
        opacity: 1,
        scale: 1,
      }}
      exit={{
        opacity: 0,
        scale: 0,
      }}
      transition={{ type: "spring", stiffness: 900, damping: 40 }}
    >
      <ListItemButton
        LinkComponent={Link}
        {...{
          to: generatePath(ORDER_PAGE_ROUTE, {
            orderId: order.orderId,
          }),
        }}
      >
        <ListItemIcon sx={{ minWidth: 0, pr: 2 }}>
          <Typography fontSize={24}>
            <Flag country={order.country} />
          </Typography>
        </ListItemIcon>

        <ListItemText
          primaryTypographyProps={{ fontWeight: 600 }}
          primary={
            <Box sx={{ display: "flex", gap: 1 }}>
              <Box>
                <FormattedPrice
                  value={order.finalPrice}
                  currency={order.currency}
                />
              </Box>
              {order.attribution && (
                <Box
                  sx={{
                    display: "flex",
                    gap: 0.5,
                    alignItems: "center",
                  }}
                >
                  <AdsClickRoundedIcon
                    sx={{ fontSize: 14, color: "text.secondary" }}
                  />
                  <Typography
                    component="span"
                    fontSize={12}
                    color="text.secondary"
                    noWrap
                  >
                    {order.attribution}
                  </Typography>
                </Box>
              )}
              {order.campaign && (
                <Box
                  sx={{
                    display: "flex",
                    gap: 0.5,
                    alignItems: "center",
                  }}
                >
                  <CampaignRoundedIcon
                    sx={{ fontSize: 14, color: "text.secondary" }}
                  />
                  <Typography
                    component="span"
                    fontSize={12}
                    color="text.secondary"
                    noWrap
                  >
                    {order.campaign}
                  </Typography>
                </Box>
              )}
            </Box>
          }
          secondary={
            <>
              <Typography
                component="span"
                fontSize={14}
                color="text.secondary"
                fontWeight={500}
              >
                <FormattedTime value={order.paid} />
              </Typography>
              {order.city ? (
                <Typography component="span" fontSize={14} color="text.primary">
                  {" "}
                  - {order.city}
                </Typography>
              ) : null}
            </>
          }
          sx={{ m: 0 }}
        />
      </ListItemButton>
    </ListItem>
  );
};
