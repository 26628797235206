import { RouterProvider } from "react-router-dom";
import { CssBaseline } from "@mui/material";
import { SnackbarProvider } from "./state/SnackbarContext";
import { ThemeContextProvider } from "./state/ThemeContext";
import { LoadingOverlay } from "./components/LoadingOverlay";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { router } from "./routes/router";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      networkMode: "always",
    },
  },
});

const App = () => {
  return (
    <ThemeContextProvider>
      <QueryClientProvider client={queryClient}>
        <CssBaseline />
        <SnackbarProvider>
          <LoadingOverlay>
            <RouterProvider router={router} />
          </LoadingOverlay>
        </SnackbarProvider>
      </QueryClientProvider>
    </ThemeContextProvider>
  );
};

export default App;
