import { FunctionComponent, useCallback, useEffect } from "react";
import { Box, Grid2 as Grid, SwipeableDrawer, TextField } from "@mui/material";
import { grey } from "@mui/material/colors";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "../../../hooks/useSnackbar";
import {
  Campaign,
  UpdateCampaign,
  campaignAPI,
} from "../../../api/campaignAPI";
import { numberValidator } from "../../../utils";

interface Props {
  campaign?: Campaign;
  onClose: () => void;
}

const Puller = () => {
  return (
    <Box
      sx={{
        width: 30,
        height: 6,
        backgroundColor: grey[300],
        borderRadius: 3,
        position: "absolute",
        top: 8,
        left: "calc(50% - 15px)",
      }}
    />
  );
};

const drawerBleeding = 56;

export const UpdateCampaignSheet: FunctionComponent<Props> = ({
  campaign,
  onClose,
}) => {
  return (
    <SwipeableDrawer
      anchor="bottom"
      open={!!campaign}
      onClose={onClose}
      onOpen={() => {
        // do nothing
      }}
      swipeAreaWidth={drawerBleeding}
      disableSwipeToOpen
      PaperProps={{
        sx: {
          borderTopLeftRadius: 10,
          borderTopRightRadius: 10,
        },
      }}
    >
      <Box
        sx={{
          maxWidth: 600,
          margin: "0 auto",
          position: "relative",
        }}
      >
        <Puller />
        <Box sx={{ p: 2 }}>
          <InnerSheet {...{ onClose, campaign }} />
        </Box>
      </Box>
    </SwipeableDrawer>
  );
};

const InnerSheet: FunctionComponent<Props> = ({ onClose, campaign }) => {
  const queryClient = useQueryClient();
  const { showSnackbar } = useSnackbar();

  const {
    handleSubmit,
    reset: resetForm,
    control,
    formState: { errors },
  } = useForm<UpdateCampaign>({
    defaultValues: {
      campaignId: "",
      displayName: "",
      rebatePercentage: 0,
      expiry: "",
    },
  });

  useEffect(() => {
    if (campaign) {
      resetForm({ ...campaign });
    }
  }, [campaign, resetForm]);

  const { mutate: updateCampaign, isPending: isSaving } = useMutation({
    mutationFn: campaignAPI.updateCampaign,
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [campaignAPI.QUERY_KEY],
      });
    },
  });

  const onSubmit = useCallback(
    (data: UpdateCampaign) => {
      updateCampaign(data, {
        onSuccess: () => {
          onClose();
          resetForm();
          showSnackbar("Updated campaign", "success");
        },
        onError: () => {
          showSnackbar("Failed to update campaign", "error");
        },
      });
    },
    [updateCampaign, onClose, resetForm, showSnackbar]
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        pt: 4,
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid
            size={{
              xs: 12,
              sm: 6,
            }}
          >
            <Controller
              name="campaignId"
              control={control}
              disabled
              rules={{
                required: true,
                pattern: {
                  value: /^\S+$/,
                  message: "Entered value can't contain whitespace",
                },
              }}
              render={({ field }) => (
                <TextField
                  fullWidth
                  label="Campaign ID"
                  id="campaignId"
                  error={!!errors.campaignId}
                  helperText={
                    (errors[field.name]?.message || errors[field.name]?.type) ??
                    " "
                  }
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid
            size={{
              xs: 12,
              sm: 6,
            }}
          >
            <Controller
              name="displayName"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  fullWidth
                  label="Display Name"
                  id="displayName"
                  error={!!errors.displayName}
                  helperText={
                    (errors[field.name]?.message || errors[field.name]?.type) ??
                    " "
                  }
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid
            size={{
              xs: 12,
              sm: 6,
            }}
          >
            <Controller
              name="rebatePercentage"
              control={control}
              rules={{
                required: true,
                validate: {
                  number: numberValidator,
                  between: (value) => {
                    if (Number(value) <= 0 || Number(value) > 50) {
                      return "Must be between 1 and 50";
                    }
                  },
                },
              }}
              render={({ field }) => (
                <TextField
                  fullWidth
                  label="Rebate"
                  id="rebatePercentage"
                  InputProps={{
                    endAdornment: "%",
                  }}
                  error={!!errors.rebatePercentage}
                  helperText={
                    (errors[field.name]?.message || errors[field.name]?.type) ??
                    " "
                  }
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid
            size={{
              xs: 12,
              sm: 6,
            }}
          >
            <Controller
              name="expiry"
              control={control}
              render={({ field }) => (
                <TextField
                  fullWidth
                  label="Expiry date"
                  id="expiry"
                  type="date"
                  error={!!errors.expiry}
                  helperText={
                    (errors[field.name]?.message || errors[field.name]?.type) ??
                    " "
                  }
                  InputLabelProps={{ shrink: true }}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid size={12}>
            <LoadingButton
              fullWidth
              type="submit"
              variant="contained"
              color="primary"
              loading={isSaving}
              size="large"
            >
              Update campaign
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};
