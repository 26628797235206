import { LoadingButton } from "@mui/lab";
import {
  Box,
  Grid2 as Grid,
  MenuItem,
  SwipeableDrawer,
  TextField,
} from "@mui/material";
import grey from "@mui/material/colors/grey";
import { useQueryClient } from "@tanstack/react-query";
import { FunctionComponent, useCallback, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useSnackbar } from "../../hooks/useSnackbar";
import { numberValidator } from "../../utils";
import { CreateVouchers, voucherAPI } from "../../api/voucherAPI";
import { DateTime } from "luxon";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const Puller = () => {
  return (
    <Box
      sx={{
        width: 30,
        height: 6,
        backgroundColor: grey[300],
        borderRadius: 3,
        position: "absolute",
        top: 8,
        left: "calc(50% - 15px)",
      }}
    />
  );
};

const drawerBleeding = 56;
export const CreateVoucherSheet: FunctionComponent<Props> = ({
  isOpen,
  onClose,
}) => (
  <SwipeableDrawer
    anchor="bottom"
    open={isOpen}
    onClose={onClose}
    onOpen={() => {
      // do nothing
    }}
    swipeAreaWidth={drawerBleeding}
    disableSwipeToOpen={true}
    PaperProps={{
      sx: {
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
      },
    }}
  >
    <Box
      sx={{
        maxWidth: 600,
        margin: "0 auto",
        position: "relative",
      }}
    >
      <Puller />
      <Box sx={{ p: 5 }}>
        <InnerSheet {...{ onClose, isOpen }} />
      </Box>
    </Box>
  </SwipeableDrawer>
);

function defaultExpiry() {
  const inTheFuture = DateTime.now().plus({ year: 1 });
  return inTheFuture.toFormat("yyyy-MM-dd");
}

const InnerSheet: FunctionComponent<Props> = ({ onClose }) => {
  const queryClient = useQueryClient();
  const { showSnackbar } = useSnackbar();

  const {
    handleSubmit,
    reset: resetForm,
    control,
    formState: { errors },
  } = useForm<CreateVouchers>({
    defaultValues: {
      count: 20,
      type: "",
      expiry: defaultExpiry(),
      attribution: ""
    },
  });

  const [isGenerating, setIsGenerating] = useState(false);

  const onSubmit = useCallback(
    (data: CreateVouchers, download: boolean) => {
      setIsGenerating(true);
      if (download) {
        voucherAPI
          .createAndDownload(data)
          .then((blob) => {
            const url = window.URL.createObjectURL(blob); // create a new object url
            const a = document.createElement("a"); // create a new anchor element
            a.href = url; // set its href to the object URL
            a.download = "vouchers.pdf";
            a.click();
            showSnackbar("Created " + data.count + " vouchers", "success");
            queryClient.invalidateQueries({
              queryKey: [voucherAPI.QUERY_KEY],
            });
            resetForm();
            onClose();
          })
          .finally(() => setIsGenerating(false));
      } else {
        voucherAPI
          .createAndSentToPrint(data)
          .then(() => {
            showSnackbar("Created " + data.count + " vouchers", "success");
            queryClient.invalidateQueries({
              queryKey: [voucherAPI.QUERY_KEY],
            });
            resetForm();
            onClose();
          })
          .finally(() => setIsGenerating(false));
      }
    },
    [onClose, queryClient, resetForm, showSnackbar]
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        pt: 4,
      }}
    >
      <form>
        <Grid container spacing={3} mb={3}>
          <Grid size={12}>
            <Controller
              name="count"
              defaultValue={20}
              control={control}
              rules={{
                required: true,
                validate: numberValidator,
                min: 1,
                max: 100,
              }}
              render={({ field }) => (
                <TextField
                  fullWidth
                  label="Vouchers to generate"
                  id="count"
                  error={!!errors[field.name]}
                  helperText={
                    (errors[field.name]?.message || errors[field.name]?.type) ??
                    " "
                  }
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid size={12}>
            <Controller
              name="type"
              control={control}
              rules={{
                required: true,
              }}
              render={({ field }) => (
                <TextField
                  fullWidth
                  select
                  label="Voucher type"
                  id="voucherType"
                  error={!!errors[field.name]}
                  helperText={
                    (errors[field.name]?.message || errors[field.name]?.type) ??
                    " "
                  }
                  {...field}
                >
                  <MenuItem value={"NAMNISAR"}>Namnisar</MenuItem>
                  <MenuItem value={"VARDA_UZLIMES"}>Varda Uzlimes</MenuItem>
                </TextField>
              )}
            />
          </Grid>
          <Grid size={12}>
            <Controller
              name="attribution"
              control={control}
              rules={{
                required: true,
              }}
              render={({ field }) => (
                <TextField
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  label="Attribution"
                  id="attribution"
                  type="string"
                  error={!!errors[field.name]}
                  helperText={
                    (errors[field.name]?.message || errors[field.name]?.type) ??
                    " "
                  }
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid size={12}>
            <Controller
              name="expiry"
              control={control}
              rules={{
                required: false,
              }}
              render={({ field }) => (
                <TextField
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  label="Expiry date (optional)"
                  id="expiry"
                  type="date"
                  error={!!errors[field.name]}
                  helperText={
                    (errors[field.name]?.message || errors[field.name]?.type) ??
                    " "
                  }
                  {...field}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid size={6}>
            <LoadingButton
              fullWidth
              name="download"
              id="download"
              type="submit"
              variant="contained"
              color="primary"
              loading={isGenerating}
              size="large"
              onClick={handleSubmit((data) => {
                onSubmit(data, true);
              })}
            >
              Download
            </LoadingButton>
          </Grid>
          <Grid size={6}>
            <LoadingButton
              name="print"
              fullWidth
              id="print"
              type="submit"
              variant="contained"
              color="secondary"
              loading={isGenerating}
              size="large"
              onClick={handleSubmit((data) => {
                onSubmit(data, false);
              })}
            >
              Send to print
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};
