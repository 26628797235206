import { Layout } from "../../layout/Layout";
import { useSuspenseQuery } from "@tanstack/react-query";
import { useMemo, useState } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Skeleton,
  TableCellProps,
  TextField,
  Button,
  Grid2,
  ButtonGroup,
} from "@mui/material";
import { AttributionRow } from "./components/AttributionRow";
import { SortedTableCell } from "../../components/Table/SortedTableCell";
import { SortDirection } from "../../api/network/Page";
import { createSortFunction } from "../../utils";
import { Attribution, attributionAPI } from "../../api/attributionAPI";
import { useSearchParams } from "react-router-dom";
import { SuspenseWrapper } from "../../components/SuspenseWrapper";

export const ATTRIBUTION_LIST_PAGE_ROUTE = "/attributions";

export const AttributionListPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const from = searchParams.get("from") || undefined;
  const to = searchParams.get("to") || undefined;

  return (
    <Layout fullHeight title="Attributions">
      <Grid2 container sx={{ pt: 2, mb: 2 }} spacing={2}>
        <Grid2
          size={{
            xs: 6,
            md: 3,
          }}
        >
          <TextField
            fullWidth
            label="From date"
            id="from"
            type="date"
            InputLabelProps={{ shrink: true }}
            value={from || ""}
            onChange={(e) =>
              setSearchParams({
                ...(to && { to }),
                from: e.target.value,
              })
            }
          />
        </Grid2>
        <Grid2
          size={{
            xs: 6,
            md: 3,
          }}
        >
          <TextField
            fullWidth
            label="To date"
            id="to"
            type="date"
            InputLabelProps={{ shrink: true }}
            value={to || ""}
            onChange={(e) =>
              setSearchParams({
                ...(from && { from }),
                to: e.target.value,
              })
            }
          />
        </Grid2>
        <Grid2
          size={{
            xs: 12,
            md: 6,
          }}
          sx={{
            display: "flex",
            gap: 2,
          }}
        >
          <ButtonGroup
            variant="outlined"
            aria-label="Basic button group"
            fullWidth
            size="small"
          >
            <Button
              onClick={() =>
                setSearchParams({
                  from: new Date(
                    new Date().setFullYear(new Date().getFullYear() - 1)
                  )
                    .toISOString()
                    .split("T")[0],
                  to: new Date(new Date().setDate(new Date().getDate() + 1))
                    .toISOString()
                    .split("T")[0],
                })
              }
            >
              Last year
            </Button>
            <Button
              onClick={() =>
                setSearchParams({
                  from: new Date(new Date().setDate(new Date().getDate() - 30))
                    .toISOString()
                    .split("T")[0],
                  to: new Date(new Date().setDate(new Date().getDate() + 1))
                    .toISOString()
                    .split("T")[0],
                })
              }
            >
              Last 30 days
            </Button>
            <Button
              onClick={() =>
                setSearchParams({
                  from: new Date(new Date().setDate(new Date().getDate() - 7))
                    .toISOString()
                    .split("T")[0],
                  to: new Date(new Date().setDate(new Date().getDate() + 1))
                    .toISOString()
                    .split("T")[0],
                })
              }
            >
              Last week
            </Button>
          </ButtonGroup>
        </Grid2>
      </Grid2>
      <SuspenseWrapper skeleton={<PageSkeleton />} gap={2}>
        <Inner from={from} to={to} />
      </SuspenseWrapper>
    </Layout>
  );
};

interface InnerProps {
  from?: string;
  to?: string;
}

const Inner: React.FC<InnerProps> = ({ from, to }) => {
  const [sortField, setSortField] = useState<keyof Attribution>("orderValue");
  const [sortDirection, setSortDirection] = useState<SortDirection>(
    SortDirection.DESC
  );

  const { data: unsortedData } = useSuspenseQuery(
    attributionAPI.fetchAttributions({
      from,
      to,
    })
  );

  const data = useMemo(
    () => unsortedData?.sort(createSortFunction(sortField, sortDirection)),
    [unsortedData, sortField, sortDirection]
  );

  const handleSortChange = (field: keyof Attribution) => {
    if (field === sortField) {
      setSortDirection(
        sortDirection === SortDirection.DESC
          ? SortDirection.ASC
          : SortDirection.DESC
      );
    } else {
      setSortField(field);
      setSortDirection(SortDirection.DESC);
    }
  };

  return (
    <TableContainer sx={{ flex: 1 }}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            {sortableHeaders.map((header) => (
              <SortedTableCell
                key={header.id}
                field={header.id}
                currentSortField={sortField}
                currentSortDirection={sortDirection}
                onSortChange={handleSortChange}
                align={header.align}
              >
                {header.label}
              </SortedTableCell>
            ))}
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((entry) => (
            <AttributionRow
              key={`${entry.name}-${entry.orderCount}-${entry.orderValue}`}
              attribution={entry}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const sortableHeaders: {
  id: keyof Attribution;
  label: string;
  align?: TableCellProps["align"];
}[] = [
  { id: "name", label: "Name", align: "left" },
  { id: "country", label: "Country", align: "left" },
  {
    id: "orderCount",
    label: "Orders",
    align: "right",
  },
  {
    id: "orderValue",
    label: "Revenue",
    align: "right",
  },
  {
    id: "lastOrder",
    label: "Latest order",
    align: "right",
  },
];

const PageSkeleton = () => {
  return (
    <>
      <Skeleton variant="rounded" height={40} />
      {[...Array(5)].map((_, i) => (
        <Skeleton key={i} variant="rounded" height={50} />
      ))}
    </>
  );
};
