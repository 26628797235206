import { FunctionComponent, useState, useTransition } from "react";
import { SuspenseWrapper } from "../../../components/SuspenseWrapper";
import {
  Chip,
  Grid2 as Grid,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { SortDirection } from "../../../api/network/Page";
import { MuiLink } from "../../../components/MuiLink/MuiLink";
import { Link, generatePath } from "react-router-dom";
import { ORDER_PAGE_ROUTE } from "../OrderPage";
import { FormattedPrice } from "../../../components/FormattedPrice/FormattedPrice";
import { FormattedTime } from "../../../components/FormattedTime/FormattedTime";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { useSuspenseQuery } from "@tanstack/react-query";
import { OrderId, orderAPI } from "../../../api/orderAPI";

interface Props {
  email: string;
  orderId: OrderId;
}

export const RelatedOrdersTable: FunctionComponent<Props> = ({ ...props }) => {
  return (
    <SuspenseWrapper skeleton={<SkeletonLoader />}>
      <Inner {...props} />
    </SuspenseWrapper>
  );
};

const Inner: FunctionComponent<Props> = ({ email }) => {
  const [isPendingTransition, startTransition] = useTransition();
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);

  const { data: data } = useSuspenseQuery(
    orderAPI.fetchOrdersByEmail(
      page,
      size,
      SortDirection.DESC,
      "created",
      email
    )
  );

  return (
    <>
      <TableContainer>
        <Table size="medium">
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell align="right">Created</TableCell>
              <TableCell align="right">Total</TableCell>
              <TableCell align="right">Paid</TableCell>
              <TableCell align="right">Printed</TableCell>
            </TableRow>
          </TableHead>
          <TableBody
            sx={{
              opacity: isPendingTransition ? 0.5 : 1,
              transition: "opacity 0.15s ease-in-out",
            }}
          >
            {data?.content?.map((order) => (
              <TableRow key={order.orderId}>
                <TableCell title={order.orderId}>
                  <MuiLink
                    noWrap
                    component={Link}
                    to={generatePath(ORDER_PAGE_ROUTE, {
                      orderId: order.orderId,
                    })}
                  >
                    {order.orderId}
                  </MuiLink>
                </TableCell>
                <TableCell title={order.created} align="right">
                  <Typography noWrap variant="caption">
                    <FormattedTime value={order.created} />
                  </Typography>
                </TableCell>
                <TableCell title={order.finalPrice.toString()} align="right">
                  <FormattedPrice
                    value={order.finalPrice}
                    currency={order.currency}
                  />
                </TableCell>
                <TableCell title={order.paid ? "paid" : "unpaid"} align="right">
                  {order.paid ? (
                    <Chip
                      icon={<CheckCircleIcon />}
                      label="paid"
                      variant="filled"
                      color="success"
                      size="small"
                    />
                  ) : (
                    <Chip
                      icon={<CancelIcon />}
                      label="paid"
                      variant="filled"
                      size="small"
                    />
                  )}
                </TableCell>
                <TableCell
                  title={order.printed ? "printed" : "unprinted"}
                  align="right"
                >
                  {order.printed ? (
                    <Chip
                      icon={<CheckCircleIcon />}
                      label="printed"
                      variant="filled"
                      color="success"
                      size="small"
                    />
                  ) : (
                    <Chip
                      icon={<CancelIcon />}
                      label="printed"
                      variant="filled"
                      size="small"
                    />
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={data?.totalElements || 0}
        rowsPerPage={data?.size || 10}
        page={data?.number || 0}
        onPageChange={(_, page) => {
          startTransition(() => {
            setPage(page);
          });
        }}
        onRowsPerPageChange={(event) => {
          startTransition(() => {
            setSize(parseInt(event.target.value, 10));
          });
        }}
      />
    </>
  );
};

const SkeletonLoader = () => {
  return (
    <Grid container spacing={2}>
      {[...Array(5)].map((_, i) => (
        <Grid key={i} size={12}>
          <Skeleton variant="rounded" height={40} />
        </Grid>
      ))}
    </Grid>
  );
};
