import { useMemo } from "react";

interface Props {
  country: string | undefined;
}

export const Flag: React.FC<Props> = ({ country }) => {
  const flag = useMemo(() => {
    if (!country) {
      return "🏳️‍🌈";
    }
    const codePoints = country
      .toUpperCase()
      .split("")
      .map((char) => 127397 + char.charCodeAt(0));
    return String.fromCodePoint(...codePoints);
  }, [country]);

  return <span>{flag}</span>;
};
