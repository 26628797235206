import React, { createContext, useCallback, useState } from "react";
import { SearchModal } from "../components/SearchModal/SearchModal";
import { useKeyPress } from "../hooks/useKeyPress";

export type GlobalSearchContextActions = {
  openSearch: () => void;
  closeSearch: () => void;
  isSearchOpen: boolean;
};

export const GlobalSearchContext = createContext(
  {} as GlobalSearchContextActions
);

interface GlobalSearchContextProviderProps {
  children: React.ReactNode;
}

export const GlobalSearchProvider: React.FC<
  GlobalSearchContextProviderProps
> = ({ children }) => {
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  const openSearch = useCallback(() => {
    setIsSearchOpen(true);
  }, []);

  const closeSearch = useCallback(() => {
    setIsSearchOpen(false);
  }, []);

  useKeyPress(["f"], true, () => {
    if (isSearchOpen) {
      setIsSearchOpen(false);
    } else {
      setIsSearchOpen(true);
    }
  });

  return (
    <GlobalSearchContext.Provider
      value={{ openSearch, closeSearch, isSearchOpen }}
    >
      <SearchModal open={isSearchOpen} onClose={closeSearch} />
      {children}
    </GlobalSearchContext.Provider>
  );
};
