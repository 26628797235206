import { Breadcrumbs } from "@mui/material";
import { FunctionComponent } from "react";
import { MuiLink } from "../MuiLink/MuiLink";
import { generatePath } from "react-router";
import { Link } from "react-router-dom";

export interface BreadcrumbItem {
  label: string;
  to?: string;
}

interface Props {
  breadcrumbs: BreadcrumbItem[];
}

export const LayoutBreadcrumbs: FunctionComponent<Props> = ({
  breadcrumbs,
}) => {
  return (
    <Breadcrumbs
      sx={(theme) => ({
        fontSize: 16,
        position: "sticky",
        top: 0,
        py: 2,
        px: {
          xs: 1,
          sm: 0,
        },
        // background: (theme) => theme.palette.background.default,
        zIndex: theme.zIndex.appBar,
        backdropFilter: "blur(10px)",
      })}
    >
      {breadcrumbs.map((breadcrumb) =>
        breadcrumb.to ? (
          <MuiLink
            key={breadcrumb.to}
            to={generatePath(breadcrumb.to)}
            component={Link}
          >
            {breadcrumb.label}
          </MuiLink>
        ) : (
          <span key={breadcrumb.label}>{breadcrumb.label}</span>
        )
      )}
    </Breadcrumbs>
  );
};
