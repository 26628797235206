import React, { useMemo } from "react";
import { UTCDate } from "../../api/orderAPI";

interface Props {
  value: string | UTCDate | undefined;
}

export const FormattedTime: React.FC<Props> = ({ value }) => {
  const formattedTime = useMemo(
    () =>
      value
        ? new Date(value).toLocaleTimeString("sv-SE", {
            hour: "numeric",
            minute: "numeric",
            year: "numeric",
            month: "numeric",
            day: "numeric",
          })
        : "-",
    [value]
  );

  return <>{formattedTime}</>;
};
