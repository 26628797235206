import { useContext } from "react";
import {
  SnackbarContext,
  SnackbarContextActions,
} from "../state/SnackbarContext";

export const useSnackbar = (): SnackbarContextActions => {
  const context = useContext(SnackbarContext);

  if (!context) {
    throw new Error("useSnackbar must be used within an SnackbarProvider");
  }

  return context;
};
