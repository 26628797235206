import { Navigate, createBrowserRouter } from "react-router-dom";
import { AppRoot } from "./AppRoot";
import { ErrorPage } from "./ErrorPage";
import {
  ORDER_LIST_PAGE_ROUTE,
  OrderListPage,
} from "../pages/Order/OrderListPage";
import { LOGIN_PAGE_ROUTE, LoginPage } from "../pages/Auth/LoginPage";
import { NotFoundPage } from "./NotFoundPage";
import {
  REMOTE_AUTH_ROUTE,
  RemoteAuthPage,
} from "../pages/Auth/RemoteAuthPage";
import { ORDER_PAGE_ROUTE, OrderPage } from "../pages/Order/OrderPage";
import { USERS_PAGE_ROUTE, UsersPage } from "../pages/Users/UsersPage";
import {
  PRINT_LIST_PAGE_ROUTE,
  PrintListPage,
} from "../pages/Print/PrintListPage";
import { PRICING_PAGE_ROUTE, PricingPage } from "../pages/Price/PricingPage";
import {
  CAMPAIGN_LIST_PAGE_ROUTE,
  CampaignListPage,
} from "../pages/Campaign/CampaignListPage";
import {
  CAMPAIGN_PAGE_ROUTE,
  CampaignPage,
} from "../pages/Campaign/CampaignPage";
import {
  OFFER_LIST_PAGE_ROUTE,
  OfferListPage,
} from "../pages/Offers/OfferListPage";
import { OFFER_PAGE_ROUTE, OfferPage } from "../pages/Offers/OfferPage";
import {
  VOUCHER_PAGE_ROUTE,
  VoucherListPage,
} from "../pages/Vouchers/VoucherListPage";
import {
  DASHBOARD_PAGE_ROUTE,
  DashboardPage,
} from "../pages/Dashboard/DashboardPage";
import { SCANNER_PAGE_ROUTE, ScannerPage } from "../pages/Scanner/ScannerPage";
import {
  ATTRIBUTION_LIST_PAGE_ROUTE,
  AttributionListPage,
} from "../pages/Attribution/AttributionListPage";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <AppRoot />,
    ErrorBoundary: ErrorPage,
    children: [
      {
        index: true,
        element: <Navigate to={DASHBOARD_PAGE_ROUTE} replace />,
      },
      {
        path: DASHBOARD_PAGE_ROUTE,
        element: <DashboardPage />,
      },
      {
        path: ORDER_LIST_PAGE_ROUTE,
        element: <OrderListPage />,
      },
      {
        path: ORDER_PAGE_ROUTE,
        element: <OrderPage />,
      },
      {
        path: PRINT_LIST_PAGE_ROUTE,
        element: <PrintListPage />,
      },
      {
        path: PRICING_PAGE_ROUTE,
        element: <PricingPage />,
      },
      {
        path: CAMPAIGN_LIST_PAGE_ROUTE,
        element: <CampaignListPage />,
      },
      {
        path: ATTRIBUTION_LIST_PAGE_ROUTE,
        element: <AttributionListPage />,
      },
      {
        path: CAMPAIGN_PAGE_ROUTE,
        element: <CampaignPage />,
      },
      {
        path: USERS_PAGE_ROUTE,
        element: <UsersPage />,
      },
      {
        path: OFFER_LIST_PAGE_ROUTE,
        element: <OfferListPage />,
      },
      {
        path: OFFER_PAGE_ROUTE,
        element: <OfferPage />,
      },
      {
        path: VOUCHER_PAGE_ROUTE,
        element: <VoucherListPage />,
      },
      {
        path: SCANNER_PAGE_ROUTE,
        element: <ScannerPage />,
      },
    ],
  },
  {
    path: LOGIN_PAGE_ROUTE,
    element: <LoginPage />,
  },
  {
    path: REMOTE_AUTH_ROUTE,
    element: <RemoteAuthPage />,
  },
  {
    path: "*",
    element: <NotFoundPage />,
  },
]);
