import { Grid2 as Grid, Skeleton } from "@mui/material";
import { StatisticsCard } from "./StatisticsCard";
import { Suspense } from "react";
import { useSuspenseQuery } from "@tanstack/react-query";
import { dashboardAPI } from "../../../api/dashboardAPI";

export const StatisticsGrid = () => {
  return (
    <Suspense fallback={<ComponentSkeleton />}>
      <Inner />
    </Suspense>
  );
};

const Inner = () => {
  const {
    data: { today, yesterday, thisMonth, previousMonth },
  } = useSuspenseQuery({
    ...dashboardAPI.fetchStatistics(),
  });

  return (
    <>
      <Grid
        size={{
          xs: 12,
          md: 6,
          lg: 4,
        }}
      >
        <StatisticsCard
          label="Sales today"
          value={today.orderValue}
          comparisonValue={yesterday.orderValue}
          comparisonLabel="compared to yesterday"
          type="currency"
        />
      </Grid>
      <Grid
        size={{
          xs: 12,
          md: 6,
          lg: 4,
        }}
      >
        <StatisticsCard
          label="Orders today"
          value={today.orderCount}
          comparisonValue={yesterday.orderCount}
          comparisonLabel="compared to yesterday"
          type="number"
          decimals={0}
        />
      </Grid>
      <Grid
        size={{
          xs: 12,
          md: 6,
          lg: 4,
        }}
      >
        <StatisticsCard
          label="AOV today"
          value={
            today.orderCount === 0 ? 0 : today.orderValue / today.orderCount
          }
          comparisonValue={yesterday.orderValue / yesterday.orderCount}
          comparisonLabel="compared to yesterday"
          type="currency"
        />
      </Grid>
      <Grid
        size={{
          xs: 12,
          md: 6,
          lg: 4,
        }}
      >
        <StatisticsCard
          label="Sales this month"
          value={thisMonth.orderValue}
          comparisonValue={previousMonth.orderValue}
          comparisonLabel="compared to last month"
          type="currency"
        />
      </Grid>
      <Grid
        size={{
          xs: 12,
          md: 6,
          lg: 4,
        }}
      >
        <StatisticsCard
          label="Orders this month"
          value={thisMonth.orderCount}
          comparisonValue={previousMonth.orderCount}
          comparisonLabel="compared to last month"
          type="number"
          decimals={0}
        />
      </Grid>
      <Grid
        size={{
          xs: 12,
          md: 6,
          lg: 4,
        }}
      >
        <StatisticsCard
          label="AOV this month"
          value={
            thisMonth.orderCount === 0
              ? 0
              : thisMonth.orderValue / thisMonth.orderCount
          }
          comparisonValue={previousMonth.orderValue / previousMonth.orderCount}
          comparisonLabel="compared to last month"
          type="currency"
        />
      </Grid>
    </>
  );
};

const ComponentSkeleton = () => {
  return (
    <>
      {[...Array(6)].map((_, i) => (
        <Grid
          key={i}
          size={{
            xs: 12,
            md: 4,
          }}
        >
          <Skeleton variant="rounded" height={140} />
        </Grid>
      ))}
    </>
  );
};
