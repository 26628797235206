import {
  Avatar,
  Box,
  Card,
  CardContent,
  Chip,
  Divider,
  Grid2 as Grid,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";

import SimCardDownloadIcon from "@mui/icons-material/SimCardDownload";
import { Layout } from "../../layout/Layout";
import { useOrderId } from "../../hooks/useOrderId";
import { useSuspenseQueries } from "@tanstack/react-query";
import {
  Address,
  DetailedOrder,
  OrderId,
  OrderItemId,
  PAYMENT_STATUS_LABEL,
  PaymentStatus,
  orderAPI,
} from "../../api/orderAPI";
import { Helmet } from "../../components/Helmet";
import { FormattedTime } from "../../components/FormattedTime/FormattedTime";
import { FormattedPrice } from "../../components/FormattedPrice/FormattedPrice";
import { Flag } from "../../components/Flag";
import { LayoutBreadcrumbs } from "../../components/Breadcrumbs/LayoutBreadcrumbs";
import { ORDER_LIST_PAGE_ROUTE } from "./OrderListPage";
import { countryFromCountryCode } from "../../utils";
import { RelatedOrdersTable } from "./components/RelatedOrdersTable";
import { ClipboardButton } from "../../components/CliboardButton/ClipboardButton";
import LanguageIcon from "@mui/icons-material/Language";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { OrderStepperDisplay } from "./components/OrderStepperDisplay";
import { LoadingButton } from "@mui/lab";
import { useState } from "react";

export const ORDER_PAGE_ROUTE = "/orders/:orderId";

export const OrderPage = () => {
  return (
    <Layout title="Order" skeletonGap={3} skeleton={<PageSkeleton />}>
      <InnerOrderPage />
    </Layout>
  );
};

const InnerOrderPage = () => {
  const orderId = useOrderId();

  const [{ data: order }] = useSuspenseQueries({
    queries: [
      orderAPI.fetchOrder(orderId),
      // ... do more related order queries if needed
    ],
  });

  const [isGenerating, setIsGenerating] = useState(false);

  const onTriggerDownload = (orderId: OrderId, itemId: OrderItemId) => {
    setIsGenerating(true);
    orderAPI
      .getOrderPdf(orderId, itemId)
      .then((blobResponse) => {
        const url = window.URL.createObjectURL(blobResponse.blob); // create a new object url
        const a = document.createElement("a"); // create a new anchor element
        a.href = url; // set its href to the object URL
        a.setAttribute(
          "download",
          blobResponse.filename ? blobResponse.filename : "file.pdf"
        );
        a.click();
      })
      .finally(() => setIsGenerating(false));
  };

  const printAddress = (address?: Address) => {
    if (address === undefined) {
      return null;
    }
    return (
      <>
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            flex: 1,
          }}
        >
          Street
        </Typography>
        <Typography variant="body2" textAlign="left">
          {address.street}
        </Typography>
        {address.streetAdditional ? (
          <>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{
                flex: 1,
              }}
            ></Typography>
            <Typography variant="body2" textAlign="left">
              {address.streetAdditional}
            </Typography>
          </>
        ) : null}
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            flex: 1,
          }}
        >
          City
        </Typography>
        <Typography variant="body2" textAlign="left">
          {address.city}
        </Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            flex: 1,
          }}
        >
          Postal Code
        </Typography>
        <Typography variant="body2" textAlign="left">
          {address.postalCode}
        </Typography>
      </>
    );
    //TODO google maps link?
  };

  return (
    <>
      <Helmet title={order.orderId} />
      <LayoutBreadcrumbs
        breadcrumbs={[
          { label: "Orders", to: ORDER_LIST_PAGE_ROUTE },
          { label: order.orderId },
        ]}
      />
      <Box
        sx={(theme) => ({
          display: "flex",
          justifyContent: "space-between",
          alignItems: {
            xs: "stretch",
            sm: "flex-start",
          },
          my: 4,
          gap: 1,
          flexDirection: { xs: "column", sm: "row" },
          position: "sticky",
          top: {
            xs: 0,
            sm: 56,
          },
          backdropFilter: {
            xs: "none",
            sm: "blur(10px)",
          },
          zIndex: theme.zIndex.appBar - 1,
          py: 2,
          textAlign: "center",
        })}
      >
        <Typography
          variant="h5"
          component="h1"
          fontWeight={600}
          sx={{
            position: "sticky",
            backdropFilter: {
              xs: "blur(10px)",
              sm: "none",
            },
            borderRadius: 10,
            top: 56,
            pb: 1,
          }}
        >
          Order{" "}
          <Typography
            variant="inherit"
            color="primary"
            display="inline"
            component="span"
          >
            {order.orderId}
          </Typography>
        </Typography>
        <Typography
          variant="h5"
          component="h3"
          color="text.secondary"
          display="inline-flex"
          alignItems="center"
          justifyContent="center"
        >
          {order.origin}
          <LanguageIcon
            fontSize="inherit"
            sx={{
              ml: 1,
              display: {
                xs: "none",
                sm: "inline-flex",
              },
            }}
            color="secondary"
          />
        </Typography>
      </Box>
      <Box sx={{ my: 6 }}>
        <OrderStepperDisplay order={order} />
      </Box>
      <Grid container spacing={3}>
        <Grid
          sx={{ maxWidth: "100%" }}
          size={{
            sm: 12,
            md: 8,
          }}
        >
          <Grid container spacing={3}>
            <Grid sx={{ width: "100%" }}>
              <Typography variant="overline" marginLeft={2} lineHeight={3}>
                Articles & pricing
              </Typography>
              <Card>
                <CardContent>
                  <TableContainer>
                    <Table size="small">
                      <TableBody>
                        {order.items.map((item) => (
                          <TableRow key={item.id}>
                            <TableCell
                              sx={{
                                display: "flex",
                                gap: {
                                  xs: 1,
                                  sm: 2,
                                },
                                alignItems: {
                                  xs: "flex-start",
                                  sm: "center",
                                },
                                borderColor: "transparent",
                                pl: 0,
                                flexDirection: {
                                  xs: "column-reverse",
                                  sm: "row",
                                },
                              }}
                            >
                              <Box
                                src={item.productImage}
                                alt="preview"
                                maxWidth={160}
                                component="img"
                                sx={{
                                  borderRadius: 1,
                                }}
                              />
                              <Typography variant="body2">
                                {item.stickerType}
                              </Typography>
                            </TableCell>
                            <TableCell
                              align="right"
                              sx={{
                                borderColor: "transparent",
                                verticalAlign: {
                                  xs: "top",
                                  sm: "middle",
                                },
                              }}
                            >
                              <LoadingButton
                                fullWidth
                                name="download"
                                id="download"
                                type="submit"
                                variant="contained"
                                color="primary"
                                loading={isGenerating}
                                size="large"
                                onClick={(_) => {
                                  onTriggerDownload(orderId, item.id);
                                }}
                              >
                                <SimCardDownloadIcon />
                              </LoadingButton>
                            </TableCell>
                            <TableCell
                              align="right"
                              sx={{
                                borderColor: "transparent",
                                pr: 0,
                                verticalAlign: {
                                  xs: "top",
                                  sm: "middle",
                                },
                              }}
                            >
                              <FormattedPrice
                                value={item.price}
                                currency={order.currency}
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Divider
                    sx={{
                      my: 2,
                    }}
                  />
                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        alignItems: "center",
                        textAlign: "right",
                        gap: 1,
                      }}
                    >
                      {order.vouchers?.map((voucher) => (
                        <Box
                          key={voucher.voucherId}
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            width: {
                              xs: "100%",
                              sm: "auto",
                            },
                            justifyContent: "space-between",
                            marginLeft: "auto",
                          }}
                        >
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            fontWeight={400}
                          >
                            Voucher
                          </Typography>
                          <Typography
                            variant="body2"
                            fontWeight={400}
                            sx={{
                              width: 160,
                            }}
                          >
                            {voucher.voucherId}
                          </Typography>
                        </Box>
                      ))}
                      {summaryValues(order).map(({ label, value, total }) => (
                        <Box
                          key={label}
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            width: {
                              xs: "100%",
                              sm: "auto",
                            },
                            justifyContent: "space-between",
                            marginLeft: "auto",
                          }}
                        >
                          <Typography
                            variant={total ? "body1" : "body2"}
                            color={total ? "" : "text.secondary"}
                            fontWeight={total ? 600 : 400}
                          >
                            {label}
                          </Typography>
                          <Typography
                            variant={total ? "body1" : "body2"}
                            fontWeight={total ? 600 : 400}
                            sx={{
                              width: 160,
                            }}
                          >
                            <FormattedPrice
                              value={value ?? 0}
                              currency={order.currency}
                            />
                          </Typography>
                        </Box>
                      ))}
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
            <Grid sx={{ width: "100%" }}>
              <Typography variant="overline" marginLeft={2} lineHeight={3}>
                Related orders
              </Typography>
              <Card>
                <CardContent>
                  {order.email ? (
                    <RelatedOrdersTable
                      email={order.email}
                      orderId={order.orderId}
                    />
                  ) : (
                    <Typography variant="body2" color="text.secondary">
                      No related orders found
                    </Typography>
                  )}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          size={{
            sm: 12,
            md: 4,
          }}
        >
          <Grid container spacing={3}>
            <Grid sx={{ width: "100%" }}>
              <Typography variant="overline" marginLeft={2} lineHeight={3}>
                Customer
              </Typography>
              <Card>
                <CardContent>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: 2,
                      alignItems: "center",
                    }}
                  >
                    <Avatar
                      sx={(theme) => ({
                        bgcolor: theme.palette.secondary.main,
                        color: theme.palette.secondary.contrastText,
                      })}
                    >
                      FL
                    </Avatar>
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Typography variant="body2">
                        {order.address ? order.address.name : "Anonymized"}
                      </Typography>
                      <Typography variant="caption" color="text.secondary">
                        {order.email ? order.email : "-"}
                      </Typography>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "1fr 1fr",
                      gap: 1,
                      alignItems: "center",
                      justifyItems: "start",
                      mt: 2,
                    }}
                  >
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{
                        flex: 1,
                      }}
                    >
                      Marketing consent
                    </Typography>
                    <Chip
                      sx={{ width: "auto" }}
                      icon={<CheckCircleIcon />}
                      label={order.approvedMarketing ? "Yes" : "No"}
                      size="small"
                      variant="outlined"
                      color={order.approvedMarketing ? "success" : "error"}
                    />
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{
                        flex: 1,
                      }}
                    >
                      Attribution
                    </Typography>
                    <Typography variant="body2" textAlign="left">
                      {order.attribution || "-"}
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
            <Grid sx={{ width: "100%" }}>
              <Typography variant="overline" marginLeft={2} lineHeight={3}>
                Delivery
              </Typography>
              <Card>
                <CardContent>
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "1fr 1fr",
                      gap: 1,
                    }}
                  >
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      component="span"
                      sx={{
                        flex: 1,
                      }}
                    >
                      Country
                    </Typography>
                    <Typography variant="body2" textAlign="left">
                      <Flag country={order.country} />{" "}
                      {order.country
                        ? countryFromCountryCode(order.country)
                        : "Unknown"}
                    </Typography>
                    {printAddress(order.address)}
                  </Box>
                </CardContent>
              </Card>
            </Grid>
            <Grid sx={{ width: "100%" }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography variant="overline" marginLeft={2} lineHeight={3}>
                  Payment
                </Typography>
                <Chip
                  color={
                    order.payment?.status === PaymentStatus.SUCCESS
                      ? "success"
                      : order.payment?.status === PaymentStatus.FAILED
                      ? "error"
                      : "warning"
                  }
                  size="small"
                  variant="outlined"
                  sx={{
                    textTransform: "lowercase",
                  }}
                  label={
                    order.payment?.status
                      ? PAYMENT_STATUS_LABEL[order.payment?.status]
                      : "Unknown"
                  }
                />
              </Box>
              <Card>
                <CardContent>
                  <Typography variant="body2" color="text.secondary">
                    <Box
                      sx={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr",
                        gap: 1,
                      }}
                    >
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        component="span"
                        sx={{
                          flex: 1,
                        }}
                      >
                        Paid at
                      </Typography>
                      <Typography
                        variant="body2"
                        textAlign="left"
                        noWrap
                        component="span"
                      >
                        <FormattedTime value={order.paid} />
                      </Typography>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{
                          flex: 1,
                        }}
                        component="span"
                      >
                        Transaction ID
                      </Typography>
                      <Tooltip title={order.payment?.transactionId}>
                        <Typography
                          variant="body2"
                          textAlign="left"
                          noWrap
                          component="span"
                        >
                          {order.payment?.transactionId ? (
                            <>{order.payment?.transactionId}</>
                          ) : (
                            "-"
                          )}
                        </Typography>
                      </Tooltip>
                    </Box>
                    {order.payment?.transactionId && (
                      <ClipboardButton
                        value={order.payment?.transactionId}
                        feedbackMessage="Copied Transaction ID to clipboard!"
                        variant="text"
                        fullWidth
                        color="secondary"
                        size="small"
                        sx={{ mt: 2 }}
                      >
                        Copy Transaction ID
                      </ClipboardButton>
                    )}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

const summaryValues = (
  order: DetailedOrder
): {
  label: string;
  value: number | undefined;
  total?: boolean;
}[] => [
  {
    label: "Subtotal",
    value: order.originalPrice,
  },
  {
    label: "Shipping",
    value: 0,
  },
  {
    label: "Discount",
    value: -Math.abs(order.originalPrice - order.finalPrice),
  },
  {
    label: "Taxes",
    value: undefined,
  },
  {
    label: "Total",
    value: order.finalPrice,
    total: true,
  },
];

const PageSkeleton = () => {
  return (
    <>
      <Skeleton variant="rounded" height={40} width={220} />
      <Skeleton variant="rounded" height={300} />
      <Box>
        <Grid container spacing={3}>
          <Grid
            display="flex"
            flexDirection="column"
            gap={3}
            size={{
              xs: 12,
              md: 8,
            }}
          >
            <Skeleton variant="rounded" height={304} />
            <Skeleton variant="rounded" height={304} />
          </Grid>
          <Grid
            display="flex"
            flexDirection="column"
            gap={3}
            size={{
              xs: 12,
              md: 4,
            }}
          >
            <Skeleton variant="rounded" height={140} />
            <Skeleton variant="rounded" height={140} />
            <Skeleton variant="rounded" height={140} />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
