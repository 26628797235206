import { API, RequiredQueryOptions, STALE_TIME } from "./network/API";
import { UTCDate } from "./orderAPI";

export type CampaignId = string & { isCampaignId: true };

export interface Campaign {
  campaignId: CampaignId;
  displayName: string;
  createdBy: string;
  rebatePercentage: number;
  created: UTCDate;
  expiry: UTCDate;
  count: number; // number of orders with this campaign
  amount: number; // total sold amount with this campaign
}

export interface CreateCampaign {
  campaignId: CampaignId;
  displayName: string;
  rebatePercentage: number;
  expiry: UTCDate;
}

export interface BaseCampaign {
  campaignId: CampaignId;
  displayName: string;
}

export type UpdateCampaign = CreateCampaign;

const QUERY_KEY = "campaigns";

export const campaignAPI = {
  QUERY_KEY: QUERY_KEY,

  fetchCampaigns: (): RequiredQueryOptions<Campaign[]> => ({
    queryKey: [QUERY_KEY],
    queryFn: () => API.get<Campaign[]>(`/admin/campaigns`),
  }),

  fetchCampaign: (campaignId: CampaignId): RequiredQueryOptions<Campaign> => ({
    queryKey: [QUERY_KEY, { campaignId }],
    queryFn: () => API.get<Campaign>(`/admin/campaigns/${campaignId}`),
  }),

  fetchListOfCampaigns: (): RequiredQueryOptions<BaseCampaign[]> => ({
    queryKey: [QUERY_KEY, "list"],
    queryFn: () => API.get<BaseCampaign[]>(`/admin/campaigns/list`),
    staleTime: STALE_TIME,
  }),

  createCampaign: (newCampaign: CreateCampaign) =>
    API.post<Campaign>(`/admin/campaigns`, newCampaign),

  updateCampaign: (updatedCampaign: UpdateCampaign) =>
    API.post<Campaign>(`/admin/campaigns/update`, updatedCampaign),

  deleteCampaign: (id: CampaignId) => API.delete(`/admin/campaigns/${id}`),
};
