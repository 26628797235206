import {
  Box,
  Chip,
  Grid2 as Grid,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import {
  Link,
  generatePath,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useTransition } from "react";
import { Layout } from "../../layout/Layout";
import { useSuspendedOrders } from "../../hooks/useOrders";
import { SortDirection } from "../../api/network/Page";
import { MuiLink } from "../../components/MuiLink/MuiLink";
import { ORDER_PAGE_ROUTE } from "./OrderPage";
import { Flag } from "../../components/Flag";
import { FormattedPrice } from "../../components/FormattedPrice/FormattedPrice";
import { FormattedTime } from "../../components/FormattedTime/FormattedTime";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { Search } from "../../components/Search/Search";
import { BaseOrder, BaseOrderFilters } from "../../api/orderAPI";
import { SortedTableCell } from "../../components/Table/SortedTableCell";
import { OrderFilters } from "./components/OrderFilters";
import { useBreakpointDown } from "../../hooks/useBreakpoint";

const DEFAULT_PAGE_SIZE = 50;

export const ORDER_LIST_PAGE_ROUTE = "/orders";

export const ORDER_LIST_PAGE_ROUTE_WITH_FILTER = (filter: BaseOrderFilters) => {
  const params = new URLSearchParams();
  Object.entries(filter).forEach(([key, value]) => {
    if (value) {
      params.set(key, value.toString());
    }
  });
  return `${ORDER_LIST_PAGE_ROUTE}?${params.toString()}`;
};

export const OrderListPage = () => {
  return (
    <Layout fullHeight title="All orders" skeleton={<PageSkeleton />}>
      <InnerOrderListPage />
    </Layout>
  );
};

const InnerOrderListPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isPendingTransition, startTransition] = useTransition();
  const navigate = useNavigate();
  const isMobile = useBreakpointDown("sm");

  const page = searchParams.get("page") || "0";
  const size = searchParams.get("size") || DEFAULT_PAGE_SIZE.toString();
  const sortField: keyof BaseOrder =
    (searchParams.get("sortField") as keyof BaseOrder) || "created";
  const sortDirection = (searchParams.get("sortDirection") ||
    SortDirection.DESC) as SortDirection;

  const filter: BaseOrderFilters = {
    country: searchParams.get("country") ?? undefined,
    includeUnpaid:
      searchParams.get("includeUnpaid") === "true" ? true : undefined,
    campaignId: searchParams.get("campaignId") ?? undefined,
    origin: searchParams.get("origin") ?? undefined,
    attribution: searchParams.get("attribution") ?? undefined,
  };

  const { data: result } = useSuspendedOrders(
    parseInt(page),
    parseInt(size),
    sortDirection,
    sortField,
    filter
  );

  const handleSelectOrder = ({ orderId }: BaseOrder) => {
    navigate(
      generatePath(ORDER_PAGE_ROUTE, {
        orderId,
      })
    );
  };

  const handleSortChange = (field: keyof BaseOrder) => {
    if (sortField === field) {
      startTransition(() => {
        setSearchParams({
          page: searchParams.get("page") || "0",
          size: searchParams.get("size") || DEFAULT_PAGE_SIZE.toString(),
          ...filterToParams(
            filter,
            field,
            sortDirection === SortDirection.DESC
              ? SortDirection.ASC
              : SortDirection.DESC
          ),
        });
      });
    } else {
      startTransition(() => {
        setSearchParams({
          page: searchParams.get("page") || "0",
          size: searchParams.get("size") || DEFAULT_PAGE_SIZE.toString(),
          ...filterToParams(filter, field, SortDirection.DESC),
        });
      });
    }
  };

  const handleFilterChange = (filter: BaseOrderFilters = {}) => {
    startTransition(() => {
      setSearchParams({
        page: searchParams.get("page") || "0",
        size: searchParams.get("size") || DEFAULT_PAGE_SIZE.toString(),
        ...filterToParams(filter, sortField, sortDirection),
      });
    });
  };

  return (
    <>
      <Search
        onSelect={handleSelectOrder}
        additionalElements={
          <OrderFilters filter={filter} onChange={handleFilterChange} />
        }
      />
      <TableContainer sx={{ flex: 1 }}>
        <Table stickyHeader size={isMobile ? "small" : "medium"}>
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <SortedTableCell
                field="finalPrice"
                currentSortField={sortField}
                currentSortDirection={sortDirection}
                onSortChange={handleSortChange}
              >
                Total
              </SortedTableCell>
              <SortedTableCell
                field="created"
                currentSortField={sortField}
                currentSortDirection={sortDirection}
                onSortChange={handleSortChange}
              >
                Created
              </SortedTableCell>
              <SortedTableCell
                field="paid"
                currentSortField={sortField}
                currentSortDirection={sortDirection}
                onSortChange={handleSortChange}
              >
                Paid
              </SortedTableCell>
              <TableCell>Printed</TableCell>
            </TableRow>
          </TableHead>
          <TableBody
            sx={{
              opacity: isPendingTransition ? 0.5 : 1,
              transition: "opacity 0.15s ease-in-out",
            }}
          >
            {result?.content?.map((order) => (
              <TableRow key={order.orderId}>
                <TableCell title={order.orderId}>
                  <Box display="flex" alignItems="center" gap={1}>
                    <Flag country={order.country} />
                    <MuiLink
                      noWrap
                      component={Link}
                      to={generatePath(ORDER_PAGE_ROUTE, {
                        orderId: order.orderId,
                      })}
                    >
                      {order.orderId}
                    </MuiLink>
                  </Box>
                </TableCell>
                <TableCell title={order.finalPrice.toString()}>
                  <FormattedPrice
                    value={order.finalPrice}
                    currency={order.currency}
                  />
                </TableCell>
                <TableCell title={order.created}>
                  <Typography noWrap variant="caption">
                    <FormattedTime value={order.created} />
                  </Typography>
                </TableCell>
                <TableCell title={order.paid ? "paid" : "unpaid"}>
                  {order.paid ? (
                    <Chip
                      icon={<CheckCircleIcon />}
                      label="paid"
                      variant="filled"
                      color="success"
                      size="small"
                    />
                  ) : (
                    <Chip
                      icon={<CancelIcon />}
                      label="paid"
                      variant="filled"
                      size="small"
                    />
                  )}
                </TableCell>
                <TableCell title={order.printed ? "printed" : "unprinted"}>
                  {order.printed ? (
                    <Chip
                      icon={<CheckCircleIcon />}
                      label="printed"
                      variant="filled"
                      color="success"
                      size="small"
                    />
                  ) : (
                    <Chip
                      icon={<CancelIcon />}
                      label="printed"
                      variant="filled"
                      size="small"
                    />
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, DEFAULT_PAGE_SIZE, 100]}
        component="div"
        count={result?.totalElements || 0}
        rowsPerPage={result?.size || DEFAULT_PAGE_SIZE}
        page={result?.number || 0}
        onPageChange={(_, page) => {
          startTransition(() => {
            setSearchParams({
              size: searchParams.get("size") || DEFAULT_PAGE_SIZE.toString(),
              page: page.toString(),
              ...filterToParams(filter, sortField, sortDirection),
            });
          });
        }}
        onRowsPerPageChange={(event) => {
          startTransition(() => {
            setSearchParams({
              page: searchParams.get("page") || "0",
              size: event.target.value,
              ...filterToParams(filter, sortField, sortDirection),
            });
          });
        }}
      />
    </>
  );
};

const PageSkeleton = () => {
  return (
    <>
      <Grid container spacing={2}>
        <Grid size="grow">
          <Skeleton variant="rounded" height={60} />
        </Grid>
        <Grid width={80}>
          <Skeleton variant="rounded" height={60} />
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid size={12}>
          <Skeleton variant="rounded" height={40} />
        </Grid>
        {[...Array(10)].map((_, i) => (
          <Grid key={i} size={12}>
            <Skeleton variant="rounded" height={50} />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

const filterToParams = (
  filter: BaseOrderFilters,
  sortField?: keyof BaseOrder,
  sortDirection?: SortDirection
): Record<string, string> => ({
  ...(sortField && { sortField }),
  ...(sortDirection && { sortDirection }),
  ...Object.fromEntries(
    Object.entries(filter)
      .filter(([, value]) => value !== undefined)
      .map(([key, value]) => [key, value.toString()])
  ),
});
