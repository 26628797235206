import { useTheme } from "@mui/material";
import {
  areaElementClasses,
  SparkLineChart,
  useDrawingArea,
} from "@mui/x-charts";
import { useSuspenseQuery } from "@tanstack/react-query";
import { dashboardAPI } from "../../../api/dashboardAPI";
import { useMemo } from "react";

const formatter = new Intl.NumberFormat("sv-SE", {
  style: "currency",
  currency: "EUR",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export const WeeklyOrdersChartSimple = () => {
  const theme = useTheme();

  const { data } = useSuspenseQuery({
    ...dashboardAPI.fetchWeeklyGraph(),
  });

  const dataSet = useMemo(() => {
    return [...data].map((item) => item.orderValue).reverse() ?? [];
  }, [data]);

  return (
    <SparkLineChart
      showHighlight
      valueFormatter={(val) => formatter.format(val ?? 0)}
      showTooltip
      slotProps={{
        popper: {
          sx: {
            pb: {
              xs: 4,
              sm: 0,
            },
          },
        },
      }}
      area
      height={300}
      xAxis={{
        disableTicks: false,
        // display date string, e.g. "2024-01-01" val === 0 would equal todays date - 7 days, val of 1 would equal 6 days back, etc.
        valueFormatter: (val) => {
          const today = new Date();
          const targetDate = new Date(today);
          // Subtract 7 days, then add the val
          targetDate.setDate(today.getDate() - 7 + val);
          // Format the date as "YYYY-MM-DD"
          const year = targetDate.getFullYear();
          const month = String(targetDate.getMonth() + 1).padStart(2, "0");
          const day = String(targetDate.getDate()).padStart(2, "0");

          return `${year}-${month}-${day}`;
        },
      }}
      colors={[theme.palette.primary.main]}
      curve="natural"
      data={dataSet}
      sx={{
        [`& .${areaElementClasses.root}`]: {
          fill: "url(#swich-color-id-1)",
        },
      }}
    >
      <ColorSwich
        color1={theme.palette.primary.light}
        color2={theme.palette.primary.light}
        id="swich-color-id-1"
      />
    </SparkLineChart>
  );
};

interface ColorSwichProps {
  id: string;
  color1: string;
  color2: string;
}

function ColorSwich({ id, color1, color2 }: ColorSwichProps) {
  const { top, height, bottom } = useDrawingArea();
  const svgHeight = top + bottom + height;

  return (
    <defs>
      <linearGradient
        id={id}
        x1="0"
        x2="0"
        y1="0"
        y2={`${svgHeight}px`}
        gradientUnits="userSpaceOnUse" // Use the SVG coordinate instead of the component ones.
      >
        <stop offset={0} stopColor={color1} stopOpacity={0.25} />
        <stop offset={1} stopColor={color2} stopOpacity={0} />
      </linearGradient>
    </defs>
  );
}
