import { useQuery, useSuspenseQuery } from "@tanstack/react-query";
import { OrderId, orderAPI } from "../api/orderAPI";

export const useOrder = (orderId: OrderId) => {
  return useQuery({
    ...orderAPI.fetchOrder(orderId),
  });
};

export const useSuspenseOrder = (orderId: OrderId) => {
  return useSuspenseQuery({
    ...orderAPI.fetchOrder(orderId),
  });
};

export const useOptionalOrder = (orderId?: OrderId) => {
  return useQuery({
    ...(orderId
      ? orderAPI.fetchOrder(orderId)
      : {
          queryKey: [],
        }),
    enabled: typeof orderId !== "undefined",
  });
};
