import { useRef, useLayoutEffect, useCallback, useEffect } from "react";

const DEFAULT_TIMEOUT = 1000;

export const useKeyPress = (
  keys: string[],
  ctrl = false,
  callback: (event: KeyboardEvent) => void,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options: { node?: any; timeout?: number } = {}
) => {
  const callbackRef = useRef(callback);
  const lastKeyPressTimeRef = useRef(0);

  useLayoutEffect(() => {
    callbackRef.current = callback;
  });

  const handleKeyPress = useCallback(
    (event: KeyboardEvent) => {
      if (keys.some((key) => event.key === key)) {
        if (ctrl && !(event.ctrlKey || event.metaKey)) {
          return;
        }
        const currentTime = Date.now();
        if (
          currentTime - lastKeyPressTimeRef.current <
          (options.timeout ?? DEFAULT_TIMEOUT)
        ) {
          return;
        }
        lastKeyPressTimeRef.current = currentTime;
        event.preventDefault();
        callbackRef.current(event);
      }
    },
    [ctrl, keys, options.timeout]
  );

  useEffect(() => {
    const targetNode = options.node ?? document;
    targetNode && targetNode.addEventListener("keydown", handleKeyPress);

    return () =>
      targetNode && targetNode.removeEventListener("keydown", handleKeyPress);
  }, [handleKeyPress, options.node]);
};
