import AddIcon from "@mui/icons-material/Add";
import {
  Fab,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { useSuspenseQuery } from "@tanstack/react-query";
import { useState, useTransition } from "react";
import { Link, generatePath, useSearchParams } from "react-router-dom";
import { SortDirection } from "../../api/network/Page";
import { BaseVoucherFilters, Voucher, voucherAPI } from "../../api/voucherAPI";
import { FormattedDate } from "../../components/FormattedDate/FormattedDate";
import { MuiLink } from "../../components/MuiLink/MuiLink";
import { SortedTableCell } from "../../components/Table/SortedTableCell";
import { Layout } from "../../layout/Layout";
import { ORDER_PAGE_ROUTE } from "../Order/OrderPage";
import { CreateVoucherSheet } from "./CreateVoucherSheet";

const DEFAULT_PAGE_SIZE = 25;

export const VOUCHER_PAGE_ROUTE = "/vouchers";

export const VoucherListPage = () => {
  return (
    <Layout
      fullHeight
      title="Vouchers"
      skeleton={<PageSkeleton />}
      skeletonGap={2}
    >
      <Inner />
    </Layout>
  );
};

const Inner = () => {
  const [isCreateSheetOpen, setIsCreateSheetOpen] = useState(false);
  const [isPendingTransition, startTransition] = useTransition();

  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get("page") || "0";
  const size = searchParams.get("size") || DEFAULT_PAGE_SIZE.toString();
  const sortField: keyof Voucher =
    (searchParams.get("sortField") as keyof Voucher) || "created";
  const sortDirection = (searchParams.get("sortDirection") ||
    SortDirection.DESC) as SortDirection;

  const filter: BaseVoucherFilters = {
    type: searchParams.get("type") ?? undefined,
  };

  const { data: data } = useSuspenseQuery(
    voucherAPI.fetchVouchersPage(
      parseInt(page),
      parseInt(size),
      sortDirection,
      sortField,
      filter
    )
  );

  const handleSortChange = (field: keyof Voucher) => {
    if (sortField === field) {
      startTransition(() => {
        setSearchParams({
          page: searchParams.get("page") || "0",
          size: searchParams.get("size") || DEFAULT_PAGE_SIZE.toString(),
          ...filterToParams(
            filter,
            field,
            sortDirection === SortDirection.DESC
              ? SortDirection.ASC
              : SortDirection.DESC
          ),
        });
      });
    } else {
      startTransition(() => {
        setSearchParams({
          page: searchParams.get("page") || "0",
          size: searchParams.get("size") || DEFAULT_PAGE_SIZE.toString(),
          ...filterToParams(filter, field, SortDirection.DESC),
        });
      });
    }
  };

  return (
    <>
      <CreateVoucherSheet
        onClose={() => setIsCreateSheetOpen(false)}
        isOpen={isCreateSheetOpen}
      />
      <TableContainer sx={{ flex: 1 }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Voucher ID</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Attribution</TableCell>
              <TableCell>Created By</TableCell>
              {sortableHeaders.map((header) => (
                <SortedTableCell
                  key={header.id}
                  field={header.id}
                  currentSortField={sortField}
                  currentSortDirection={sortDirection}
                  onSortChange={handleSortChange}
                  align={header.align}
                >
                  {header.label}
                </SortedTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody
            sx={{
              opacity: isPendingTransition ? 0.5 : 1,
              transition: "opacity 0.15s ease-in-out",
            }}
          >
            {data?.content?.map((row) => (
              <TableRow key={row.voucherId}>
                <TableCell>{row.voucherId}</TableCell>
                {/*  TODO click to filter eller ha en droppe? */}
                <TableCell>{row.type}</TableCell>
                <TableCell>{row.attribution ?? ''}</TableCell>
                <TableCell>{row.createdBy}</TableCell>

                <TableCell align="right">
                  <Typography noWrap variant="caption">
                    {/*  TODO click to expire now? */}
                    <FormattedDate value={row.expiry} />
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  {row.used ? (
                    <MuiLink
                      component={Link}
                      to={generatePath(ORDER_PAGE_ROUTE, {
                        orderId: row.orderId,
                      })}
                    >
                      <Typography noWrap variant="caption">
                        <FormattedDate value={row.used} />
                      </Typography>
                    </MuiLink>
                  ) : (
                    <Typography noWrap variant="caption">
                      -
                    </Typography>
                  )}
                </TableCell>
                <TableCell align="right">
                  <Typography noWrap variant="caption">
                    <FormattedDate value={row.created} />
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, DEFAULT_PAGE_SIZE, 50, 100]}
        component="div"
        count={data?.totalElements || 0}
        rowsPerPage={data?.size || DEFAULT_PAGE_SIZE}
        page={data?.number || 0}
        onPageChange={(_, page) => {
          startTransition(() => {
            setSearchParams({
              size: searchParams.get("size") || DEFAULT_PAGE_SIZE.toString(),
              page: page.toString(),
              ...filterToParams(filter, sortField, sortDirection),
            });
          });
        }}
        onRowsPerPageChange={(event) => {
          startTransition(() => {
            setSearchParams({
              page: searchParams.get("page") || "0",
              size: event.target.value,
              ...filterToParams(filter, sortField, sortDirection),
            });
          });
        }}
      />
      <Fab
        color="primary"
        sx={{ position: "absolute", bottom: 60, right: 20 }}
        variant="extended"
        onClick={() => setIsCreateSheetOpen(true)}
        size="large"
      >
        <AddIcon sx={{ mr: 1 }} /> Create Vouchers
      </Fab>
    </>
  );
};

const sortableHeaders: {
  id: keyof Voucher;
  label: string;
  align?: TableCellProps["align"];
}[] = [
  {
    id: "expiry",
    label: "Expiry",
    align: "right",
  },
  {
    id: "used",
    label: "Used",
    align: "right",
  },
  {
    id: "created",
    label: "Created",
    align: "right",
  },
];

const PageSkeleton = () => {
  return (
    <>
      <Skeleton variant="rounded" height={30} />
      {[...Array(8)].map((_, i) => (
        <Skeleton key={i} variant="rounded" height={50} />
      ))}
    </>
  );
};

const filterToParams = (
  filter: BaseVoucherFilters,
  sortField?: keyof Voucher,
  sortDirection?: SortDirection
): Record<string, string> => ({
  ...(sortField && { sortField }),
  ...(sortDirection && { sortDirection }),
  ...Object.fromEntries(
    Object.entries(filter)
      .filter(([, value]) => value !== undefined)
      .map(([key, value]) => [key, value.toString()])
  ),
});
