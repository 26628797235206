import { FunctionComponent } from "react";
import { Box, SwipeableDrawer } from "@mui/material";
import { grey } from "@mui/material/colors";
import { outline, Scanner } from "@yudiel/react-qr-scanner";
import { OrderId } from "../../../api/orderAPI";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onScanSuccess: (orderId: OrderId) => void;
}

const Puller = () => {
  return (
    <Box
      sx={{
        width: 30,
        height: 6,
        backgroundColor: grey[300],
        borderRadius: 3,
        position: "absolute",
        top: 8,
        left: "calc(50% - 15px)",
      }}
    />
  );
};

const drawerBleeding = 56;

export const ScannerSheet: FunctionComponent<Props> = ({
  isOpen,
  onClose,
  onScanSuccess,
}) => {
  return (
    <SwipeableDrawer
      anchor="bottom"
      open={isOpen}
      onClose={onClose}
      onOpen={() => {
        // do nothing
      }}
      swipeAreaWidth={drawerBleeding}
      disableSwipeToOpen={true}
      PaperProps={{
        sx: {
          borderTopLeftRadius: 10,
          borderTopRightRadius: 10,
        },
      }}
    >
      <Box
        sx={{
          maxWidth: 600,
          margin: "0 auto",
          position: "relative",
          minHeight: "min(100vw, 600px)",
        }}
      >
        <Puller />
        <Box sx={{ p: 2 }}>
          {isOpen && <InnerSheet {...{ onClose, isOpen, onScanSuccess }} />}
        </Box>
      </Box>
    </SwipeableDrawer>
  );
};

const InnerSheet: FunctionComponent<Props> = ({
  onScanSuccess,
  onClose: _onClose,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        py: 2,
      }}
    >
      <Scanner
        components={{
          tracker: outline,
          torch: false,
          zoom: false,
          audio: true,
        }}
        onScan={(val) => {
          if (val.length > 0 && isOrderId(val[0].rawValue)) {
            onScanSuccess(val[0].rawValue);
          }
        }}
        formats={["data_matrix"]}
        constraints={{ facingMode: "environment" }}
        allowMultiple={false}
        styles={{
          container: {
            borderRadius: 12,
          },
          video: {
            borderRadius: 12,
          },
        }}
      />
    </Box>
  );
};

const isOrderId = (val: string): val is OrderId => {
  return /^[a-zA-Z0-9]{2}-[a-zA-Z0-9]{3}-[a-zA-Z0-9]{3}$/.test(val);
};
