import { useFeedback } from "../../hooks/useFeedback";
import { Layout } from "../../layout/Layout";
import {
  centerText,
  IDetectedBarcode,
  Scanner,
} from "@yudiel/react-qr-scanner";
import styles from "./ScannerPage.module.scss";
import { useState } from "react";

export const SCANNER_PAGE_ROUTE = "/scanner";

export const ScannerPage = () => {
  return (
    <Layout title="Scanner" fullHeight fullScreen>
      <Inner />
    </Layout>
  );
};

const Inner = () => {
  const feedback = useFeedback();
  const [codes, setCodes] = useState<IDetectedBarcode[]>([]);

  return (
    <>
      <Scanner
        classNames={{ container: styles.scanner, video: styles.video }}
        components={{
          tracker: centerText,
          // torch: false,
          // audio: true,
          // zoom: false,
        }}
        onScan={(val) => {
          setCodes(val);
          feedback();
        }}
        // constraints={{ facingMode: "environment" }}
      />
      {codes.map((code, index) => (
        <div key={index + "-" + code.rawValue} className={styles.code}>
          {code.rawValue}
        </div>
      ))}
    </>
  );
};
