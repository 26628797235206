import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { Layout } from "../../layout/Layout";
import { useSuspenseQuery } from "@tanstack/react-query";
import { useMemo, useState } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Fab,
  Skeleton,
  TableCellProps,
} from "@mui/material";
import { Campaign, campaignAPI } from "../../api/campaignAPI";
import { CreateCampaignSheet } from "./components/CreateCampaignSheet";
import { CampaignRow } from "./components/CampaignRow";
import { SortedTableCell } from "../../components/Table/SortedTableCell";
import { SortDirection } from "../../api/network/Page";
import { createSortFunction } from "../../utils";
import { UpdateCampaignSheet } from "./components/UpdateCampaignSheet";

export const CAMPAIGN_LIST_PAGE_ROUTE = "/campaigns";

export const CampaignListPage = () => {
  return (
    <Layout
      fullHeight
      title="Campaigns"
      skeleton={<PageSkeleton />}
      skeletonGap={2}
    >
      <InnerCampaignListPage />
    </Layout>
  );
};

const InnerCampaignListPage = () => {
  const [isCreateCampaignSheetOpen, setIsCreateCampaignSheetOpen] =
    useState(false);
  const [sortField, setSortField] = useState<keyof Campaign>("created");
  const [sortDirection, setSortDirection] = useState<SortDirection>(
    SortDirection.DESC
  );
  const [updateCampaign, setUpdateCampaign] = useState<Campaign>();

  const { data: unsortedData } = useSuspenseQuery(campaignAPI.fetchCampaigns());

  const data = useMemo(
    () => unsortedData?.sort(createSortFunction(sortField, sortDirection)),
    [unsortedData, sortField, sortDirection]
  );

  const handleSortChange = (field: keyof Campaign) => {
    if (field === sortField) {
      setSortDirection(
        sortDirection === SortDirection.DESC
          ? SortDirection.ASC
          : SortDirection.DESC
      );
    } else {
      setSortField(field);
      setSortDirection(SortDirection.DESC);
    }
  };

  return (
    <>
      <CreateCampaignSheet
        onClose={() => setIsCreateCampaignSheetOpen(false)}
        isOpen={isCreateCampaignSheetOpen}
      />
      <UpdateCampaignSheet
        onClose={() => setUpdateCampaign(undefined)}
        campaign={updateCampaign}
      />
      <TableContainer sx={{ flex: 1 }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {sortableHeaders.map((header) => (
                <SortedTableCell
                  key={header.id}
                  field={header.id}
                  currentSortField={sortField}
                  currentSortDirection={sortDirection}
                  onSortChange={handleSortChange}
                  align={header.align}
                >
                  {header.label}
                </SortedTableCell>
              ))}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((campaign) => (
              <CampaignRow
                key={campaign.campaignId}
                campaign={campaign}
                onUpdate={setUpdateCampaign}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Fab
        color="primary"
        sx={{ position: "absolute", bottom: 20, right: 20 }}
        variant="extended"
        onClick={() => setIsCreateCampaignSheetOpen(true)}
        size="medium"
      >
        <PersonAddIcon sx={{ mr: 1 }} /> new campaign
      </Fab>
    </>
  );
};

const sortableHeaders: {
  id: keyof Campaign;
  label: string;
  align?: TableCellProps["align"];
}[] = [
  { id: "campaignId", label: "#", align: "left" },
  {
    id: "displayName",
    label: "Name",
    align: "left",
  },
  {
    id: "rebatePercentage",
    label: "Rebate",
    align: "right",
  },
  {
    id: "count",
    label: "Usage",
    align: "right",
  },
  {
    id: "amount",
    label: "Total",
    align: "right",
  },
  {
    id: "created",
    label: "Created",
    align: "right",
  },
  {
    id: "expiry",
    label: "Expiry",
    align: "right",
  },
];

const PageSkeleton = () => {
  return (
    <>
      <Skeleton variant="rounded" height={30} />
      {[...Array(5)].map((_, i) => (
        <Skeleton key={i} variant="rounded" height={50} />
      ))}
    </>
  );
};
