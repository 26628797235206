import { TableCell, TableCellProps, TableSortLabel } from "@mui/material";
import { SortDirection } from "../../api/network/Page";

interface Props<T extends string> {
  field: T;
  currentSortField: T;
  currentSortDirection: SortDirection;
  onSortChange: (field: T) => void;
  children: React.ReactNode;
  align?: TableCellProps["align"];
}

export const SortedTableCell = <T extends string>({
  field,
  currentSortField,
  currentSortDirection,
  onSortChange,
  children,
  align,
}: Props<T>) => {
  return (
    <TableCell onClick={() => onSortChange(field)} align={align}>
      <TableSortLabel
        active={currentSortField === field}
        direction={currentSortDirection === SortDirection.DESC ? "desc" : "asc"}
      >
        {children}
      </TableSortLabel>
    </TableCell>
  );
};
