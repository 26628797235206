import {
  keepPreviousData,
  useQuery,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { SortDirection } from "../api/network/Page";
import { Offer, offerAPI } from "../api/offerAPI";

export const useOffers = (
  page: number,
  size: number,
  direction: SortDirection,
  field: keyof Offer
  // filters?: string
) => {
  return useQuery({
    ...offerAPI.getAll(page, size, direction, field),
    placeholderData: keepPreviousData,
  });
};

export const useSuspendedOffers = (
  page: number,
  size: number,
  direction: SortDirection,
  field: keyof Offer
  // filters?: string
) => {
  return useSuspenseQuery(offerAPI.getAll(page, size, direction, field));
};
