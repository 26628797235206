import { API, RequiredQueryOptions } from "./network/API";
import { Page, SortDirection } from "./network/Page";
import { OrderId, UTCDate } from "./orderAPI";

export type VoucherId = string & { isVoucherId: true };

//TODO ska vi ladda den här från BE istället?
export const AVAILABLE_TYPES = [
  {
    key: "NAMNISAR",
    name: "Namnisar",
  },
  {
    key: "VARDA_UZLIMES",
    name: "Varda Uzlimes",
  },
];

export interface Voucher {
  voucherId: VoucherId;
  created: UTCDate;
  createdBy: string;
  type: string;
  used?: UTCDate;
  orderId: OrderId;
  orderItemId: number;
  expiry: UTCDate;
  attribution?: string;
}

export type BaseVoucherFilters = Partial<{
  type: string;
}>;

export interface CreateVouchers {
  count: number;
  type: string;
  expiry: string;
  attribution: string;
}

const QUERY_KEY = "vouchers";

export const voucherAPI = {
  QUERY_KEY: QUERY_KEY,
  fetchVoucher: (voucherId: VoucherId): RequiredQueryOptions<Voucher> => ({
    queryKey: [QUERY_KEY, { voucherId }],
    queryFn: () => API.get<Voucher>(`/admin/vouchers/${voucherId}`),
  }),

  fetchVouchersByOrder: (
    orderId: OrderId
  ): RequiredQueryOptions<Voucher[]> => ({
    queryKey: [QUERY_KEY, "orders", { orderId }],
    queryFn: () => API.get<Voucher[]>(`/admin/vouchers/order/${orderId}`),
  }),

  fetchVouchersPage: (
    page: number,
    size: number,
    direction: SortDirection,
    field: keyof Voucher,
    filters?: BaseVoucherFilters
  ): RequiredQueryOptions<Page<Voucher>> => ({
    queryKey: [QUERY_KEY, { page, size, direction, field, ...filters }],
    queryFn: () => {
      const filterParams = Object.entries(filters ?? {})
        .filter(([, value]) => value !== undefined)
        .map(([key, value]) => {
          return `${key}=${value}`;
        });

      return API.get<Page<Voucher>>(
        `/admin/vouchers/all?page=${page}&size=${size}&sortDirection=${direction}&sortField=${field}${
          filterParams.length > 0 ? `&${filterParams.join("&")}` : ""
        }`
      );
    },
  }),

  //När den här returnerar 200 så finns filen i print-mappen
  createAndSentToPrint: (request: CreateVouchers) =>
    API.post("/admin/vouchers/print", request),

  createAndDownload: (request: CreateVouchers) =>
    API.post<Blob>("/admin/vouchers/download", request),
};
