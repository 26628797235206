import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import CloseIcon from "@mui/icons-material/Close";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import DescriptionIcon from "@mui/icons-material/Description";
import DiscountIcon from "@mui/icons-material/Discount";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import LightModeIcon from "@mui/icons-material/LightMode";
import PeopleIcon from "@mui/icons-material/People";
import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Skeleton,
  SwipeableDrawer,
  ToggleButton,
  ToggleButtonGroup,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material";
import { useSuspenseQuery } from "@tanstack/react-query";
import { FunctionComponent, useContext, useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import { authAPI } from "../api/authAPI";
import { UserRight } from "../api/userAPI";
import logo from "../assets/logo.png";
import { SuspenseWrapper } from "../components/SuspenseWrapper";
import { UserInfo } from "../components/UserInfo/UserInfo";
import { CAMPAIGN_LIST_PAGE_ROUTE } from "../pages/Campaign/CampaignListPage";
import { OFFER_LIST_PAGE_ROUTE } from "../pages/Offers/OfferListPage";
import { ORDER_LIST_PAGE_ROUTE } from "../pages/Order/OrderListPage";
import { USERS_PAGE_ROUTE } from "../pages/Users/UsersPage";
import { VOUCHER_PAGE_ROUTE } from "../pages/Vouchers/VoucherListPage";
import { ThemeContext } from "../state/ThemeContext";
import { DASHBOARD_PAGE_ROUTE } from "../pages/Dashboard/DashboardPage";
import { Dashboard } from "@mui/icons-material";
import { ATTRIBUTION_LIST_PAGE_ROUTE } from "../pages/Attribution/AttributionListPage";
import HubRoundedIcon from "@mui/icons-material/HubRounded";

export const DRAWER_WIDTH = 240;

interface Props {
  isOpen: boolean;
  onClose: () => void;
  isNarrow?: boolean;
}

export const Sidebar: FunctionComponent<Props> = ({
  isOpen,
  onClose,
  isNarrow,
}) => {
  const theme = useTheme();
  const { toggleTheme } = useContext(ThemeContext);

  return (
    <SwipeableDrawer
      sx={{
        width: {
          xs: "100vw",
          sm: DRAWER_WIDTH,
        },
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: {
            xs: "100vw",
            sm: DRAWER_WIDTH,
          },
        },
      }}
      PaperProps={{
        sx: {
          border: "none",
          background: (theme) => theme.palette.background.default,
        },
      }}
      variant={isNarrow ? "temporary" : "permanent"}
      anchor={isNarrow ? "bottom" : "left"}
      open={isOpen}
      onClose={onClose}
      onOpen={() => {
        // do nothing
      }}
      disableSwipeToOpen={true}
    >
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "space-between",
          textDecoration: "none",
          color: "inherit",
          position: "sticky",
          backdropFilter: "blur(10px)",
          top: 0,
          zIndex: theme.zIndex.appBar,
          // backgroundColor: (theme) => theme.palette.background.paper,
          transition: (theme) => theme.transitions.create("background-color"),
          "&:hover": {
            backgroundColor: (theme) => theme.palette.action.hover,
          },
        }}
        component={Link}
        to="/"
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 2,
          }}
        >
          <Box component="img" src={logo} alt="logo" height={"2.4em"} />
          <Typography variant="overline" noWrap fontWeight={800} lineHeight={1}>
            Sticker Admin
          </Typography>
        </Box>

        {isNarrow && (
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        )}
      </Toolbar>
      <SuspenseWrapper
        gap={1}
        skeleton={
          <>
            <Skeleton variant="rounded" height={42} sx={{ mt: 4 }} />
            <Skeleton variant="rounded" height={42} />
            <Skeleton variant="rounded" height={42} />
            <Skeleton variant="rounded" height={42} />
          </>
        }
      >
        <SidebarNav onClose={onClose} />
      </SuspenseWrapper>
      <Box
        sx={{
          marginTop: "auto",
          p: 2,
          pt: 0,
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <Box>
          <Typography gutterBottom variant="overline" color="textSecondary">
            User
          </Typography>
          <Box>
            <UserInfo fullWidth />
          </Box>
        </Box>
        <ToggleButtonGroup
          fullWidth
          size="small"
          onChange={toggleTheme}
          color="secondary"
        >
          <ToggleButton
            value="check"
            selected={theme.palette.mode === "light"}
            color="primary"
          >
            <LightModeIcon />
          </ToggleButton>
          <ToggleButton
            value="check"
            selected={theme.palette.mode === "dark"}
            color="secondary"
          >
            <DarkModeIcon />
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
    </SwipeableDrawer>
  );
};

export const SidebarNav: FunctionComponent<Pick<Props, "onClose">> = ({
  onClose,
}) => {
  const location = useLocation();

  const {
    data: { rights },
  } = useSuspenseQuery(authAPI.whoami());

  // sort rights by the order of UserRight enum
  // ensures the sidebar items always has the same order
  const sortedRights = useMemo(
    () => Object.values(UserRight).filter((right) => rights.includes(right)),
    [rights]
  );

  const links = useMemo(
    () =>
      [...LINKS, ...sortedRights.map((right) => RIGHTS_LINKS[right])].flat(),
    [sortedRights]
  );

  return (
    <List>
      {links.map(({ key, label, icon, matchFn, disabled }) => (
        <ListItem
          key={key}
          disablePadding
          sx={{
            my: 1,
          }}
        >
          <ListItemButton
            selected={matchFn?.(location.pathname) || key === location.pathname}
            LinkComponent={Link}
            disabled={disabled}
            onClick={onClose}
            {...{
              to: key,
            }}
          >
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText primary={label} />
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );
};

interface LinkItem {
  key: string;
  label: string;
  icon: JSX.Element;
  matchFn?: (pathname: string) => boolean;
  mobileOnly?: boolean;
  disabled?: boolean;
}

const RIGHTS_LINKS: Record<UserRight, LinkItem[]> = {
  [UserRight.PRINT]: [
    // {
    //   key: PRINT_LIST_PAGE_ROUTE,
    //   label: "Print queue",
    //   icon: <LocalPrintshopIcon />,
    //   disabled: true,
    // },
  ],
  [UserRight.REBATE]: [
    {
      key: CAMPAIGN_LIST_PAGE_ROUTE,
      label: "Campaigns",
      icon: <DiscountIcon />,
      matchFn: (pathname) => pathname.startsWith(CAMPAIGN_LIST_PAGE_ROUTE),
    },
  ],
  [UserRight.VOUCHER]: [
    {
      key: VOUCHER_PAGE_ROUTE,
      label: "Vouchers",
      icon: <CardGiftcardIcon />,
      matchFn: (pathname) => pathname.startsWith(VOUCHER_PAGE_ROUTE),
    },
  ],
  [UserRight.OFFERS]: [
    {
      key: OFFER_LIST_PAGE_ROUTE,
      label: "Offers",
      icon: <DescriptionIcon />,
      matchFn: (pathname) => pathname.startsWith(OFFER_LIST_PAGE_ROUTE),
    },
  ],
  [UserRight.USERS]: [
    {
      key: USERS_PAGE_ROUTE,
      label: "Users",
      icon: <PeopleIcon />,
    },
  ],
};

const LINKS: LinkItem[] = [
  {
    key: DASHBOARD_PAGE_ROUTE,
    label: "Dashboard",
    icon: <Dashboard />,
    matchFn: (pathname) => pathname.startsWith(DASHBOARD_PAGE_ROUTE),
  },
  {
    key: ORDER_LIST_PAGE_ROUTE,
    label: "All orders",
    icon: <FormatListBulletedIcon />,
    matchFn: (pathname) => pathname.startsWith(ORDER_LIST_PAGE_ROUTE),
  },
  {
    key: ATTRIBUTION_LIST_PAGE_ROUTE,
    label: "Attributions",
    icon: <HubRoundedIcon />,
  },
];
