import { Backdrop, Box, LinearProgress, Typography } from "@mui/material";
import {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useState,
} from "react";
import { ServerError, ping } from "../api/network/API";
import logo from "../assets/logo.png";
import { useQuery } from "@tanstack/react-query";
import { AnimatePresence, motion } from "framer-motion";

export const LoadingOverlay: FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const { isLoading } = useQuery({
    queryFn: ping,
    queryKey: ["ping"],
    staleTime: 0,
    refetchOnWindowFocus: false,
    retry(failureCount, error: ServerError<unknown>) {
      if (error?.status === 401) {
        return false;
      }
      return failureCount < 3;
    },
  });

  const [loadingText, setLoadingText] = useState(
    LOADING_TEXTS[Math.floor(Math.random() * LOADING_TEXTS.length)]
  );

  useEffect(() => {
    if (!isLoading) return;
    const interval = setInterval(() => {
      setLoadingText(
        LOADING_TEXTS[
          (LOADING_TEXTS.indexOf(loadingText) + 1) % LOADING_TEXTS.length
        ]
      );
    }, Math.floor(Math.random() * 3000) + 3000);
    return () => clearInterval(interval);
  }, [isLoading, loadingText]);

  return (
    <>
      {!isLoading && children}
      <Backdrop
        sx={(theme) => ({
          zIndex: theme.zIndex.drawer + 1,
          display: "flex",
          flexDirection: "column",
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.background.default,
        })}
        open={isLoading}
        color="primary"
        transitionDuration={isLoading ? 0 : 500}
      >
        <AnimatePresence>
          {isLoading && (
            <Box
              component={motion.div}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.5 }}
              sx={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
                width: "100%",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  maxWidth: "min(250px, 50vw)",
                  mx: 4,
                  mt: "15vh",
                  position: "relative",
                }}
              >
                <Box
                  component="img"
                  src={logo}
                  alt="logo"
                  sx={{
                    position: "absolute",
                    width: "100%",
                    filter: "blur(10px)",
                    animation: "scale 5s ease-in-out infinite",
                  }}
                />

                <Box
                  component="img"
                  src={logo}
                  alt="logo"
                  sx={{
                    position: "absolute",
                    width: "100%",
                  }}
                />
              </Box>

              <Box
                sx={{
                  mt: "auto",
                  mb: 4,
                  px: 4,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "100%",
                  maxWidth: "400px",
                }}
              >
                <AnimatePresence mode="wait">
                  <Box
                    component={motion.div}
                    key={loadingText}
                    initial={{
                      opacity: 1,
                      y: 10,
                    }}
                    animate={{
                      opacity: 1,
                      y: 0,
                    }}
                    exit={{
                      opacity: 0,
                      y: -10,
                    }}
                  >
                    <Typography
                      variant="overline"
                      component="span"
                      fontSize={14}
                      fontWeight={700}
                      align="center"
                    >
                      {loadingText}
                    </Typography>
                  </Box>
                </AnimatePresence>
                <LinearProgress color="inherit" sx={{ m: 2, width: "100%" }} />
              </Box>
            </Box>
          )}
        </AnimatePresence>
      </Backdrop>
    </>
  );
};

const LOADING_TEXTS = [
  "Peeling off creativity...",
  "Sticking to fun designs...",
  "Printing sticker magic...",
  "Cutting to the chase...",
  "Adhering to awesomeness...",
  "Aligning adhesive art...",
  "Pasting colorful dreams...",
  "Unrolling whimsical wonders...",
  "Layering lively designs...",
  "Embossing excitement...",
  "Inking imaginative ideas...",
  "Crafting sticker stories...",
  "Designing delightful decals...",
  "Smoothing out smiles...",
  "Picking perfect patterns...",
  "Coloring outside the lines...",
  "Shaping sticker sensations...",
  "Laminating layers of fun...",
  "Sketching sticky surprises...",
  "Rolling out the rad...",
  "Gluing together greatness...",
  "Illustrating iconic images...",
  "Weaving whimsy and wonder...",
  "Sealing sensational scenes...",
  "Capturing creative charm...",
];
