import {
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Paper,
} from "@mui/material";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import { FunctionComponent, useState } from "react";
import { useGlobalSearch } from "../hooks/useGlobalSearch";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import QrCodeScannerRoundedIcon from "@mui/icons-material/QrCodeScannerRounded";
import { ScannerSheet } from "../pages/Scanner/components/ScannerSheet";
import { OrderId } from "../api/orderAPI";
import { ScannedOrderSheet } from "../pages/Scanner/components/ScannedOrderSheet";

interface Props {
  isMobile: boolean;
  onOpenSidebar: () => void;
}

export const BottomBar: FunctionComponent<Props> = ({ onOpenSidebar }) => {
  const { openSearch } = useGlobalSearch();
  const [isScannerOpen, setIsScannerOpen] = useState(false);
  const [scannedOrderId, setScannedOrderId] = useState<OrderId>();

  return (
    <>
      <ScannerSheet
        isOpen={isScannerOpen}
        onClose={() => setIsScannerOpen(false)}
        onScanSuccess={(orderId) => {
          setIsScannerOpen(false);
          setScannedOrderId(orderId);
        }}
      />
      <ScannedOrderSheet
        onClose={() => setScannedOrderId(undefined)}
        onMarkAsSent={() => {
          setIsScannerOpen(true);
        }}
        orderId={scannedOrderId}
      />
      <Paper
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: "transparent",
        }}
        elevation={4}
      >
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: -50,
            backdropFilter: "blur(10px)",
            background: (theme) => theme.palette.background.paper,
            opacity: 1,
            backgroundColor: "transparent",
            maskImage:
              "linear-gradient(to top, black 0%, black 50px, transparent 150%)",
          }}
        />
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            opacity: 0.75,
            backgroundColor: (theme) => theme.palette.background.paper,
          }}
        />
        <BottomNavigation
          sx={{
            background: "transparent",
          }}
        >
          <BottomNavigationAction
            label="Search"
            icon={<SearchRoundedIcon color="action" />}
            onClick={openSearch}
          />
          <BottomNavigationAction
            label="Scan"
            icon={<QrCodeScannerRoundedIcon color="action" />}
            onClick={() => setIsScannerOpen(true)}
            color="primary"
          />
          <BottomNavigationAction
            label="Menu"
            icon={<MenuRoundedIcon color="action" />}
            onClick={onOpenSidebar}
          />
        </BottomNavigation>
      </Paper>
    </>
  );
};
