import { FunctionComponent, useCallback } from "react";
import { Box, Grid2 as Grid, SwipeableDrawer, TextField } from "@mui/material";
import { grey } from "@mui/material/colors";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "../../../hooks/useSnackbar";
import { CreateOfferRequest, offerAPI } from "../../../api/offerAPI";
import { generatePath, useNavigate } from "react-router";
import { OFFER_PAGE_ROUTE } from "../OfferPage";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const Puller = () => {
  return (
    <Box
      sx={{
        width: 30,
        height: 6,
        backgroundColor: grey[300],
        borderRadius: 3,
        position: "absolute",
        top: 8,
        left: "calc(50% - 15px)",
      }}
    />
  );
};

const drawerBleeding = 56;

export const CreateOfferSheet: FunctionComponent<Props> = ({
  isOpen,
  onClose,
}) => {
  return (
    <SwipeableDrawer
      anchor="bottom"
      open={isOpen}
      onClose={onClose}
      onOpen={() => {
        // do nothing
      }}
      swipeAreaWidth={drawerBleeding}
      disableSwipeToOpen={true}
      PaperProps={{
        sx: {
          borderTopLeftRadius: 10,
          borderTopRightRadius: 10,
        },
      }}
    >
      <Box
        sx={{
          maxWidth: 600,
          margin: "0 auto",
          position: "relative",
        }}
      >
        <Puller />
        <Box sx={{ p: 2 }}>
          <InnerSheet {...{ onClose, isOpen }} />
        </Box>
      </Box>
    </SwipeableDrawer>
  );
};

const InnerSheet: FunctionComponent<Props> = ({ onClose }) => {
  const queryClient = useQueryClient();
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const {
    handleSubmit,
    reset: resetForm,
    control,
    formState: { errors },
  } = useForm<CreateOfferRequest>({
    defaultValues: {
      header: "",
    },
  });

  const { mutate: createOffer, isPending: isSaving } = useMutation({
    mutationFn: offerAPI.create,
    onSuccess: ({ offerId }) => {
      navigate(generatePath(OFFER_PAGE_ROUTE, { offerId }));
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [offerAPI.QUERY_KEY],
      });
    },
  });

  const onSubmit = useCallback(
    (data: CreateOfferRequest) => {
      createOffer(data, {
        onSuccess: () => {
          onClose();
          resetForm();
          showSnackbar("Created offer", "success");
        },
        onError: () => {
          showSnackbar("Failed to create offer", "error");
        },
      });
    },
    [createOffer, onClose, resetForm, showSnackbar]
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        pt: 4,
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid size={12}>
            <Controller
              name="header"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  fullWidth
                  label="Project title"
                  id="header"
                  error={!!errors[field.name]}
                  helperText={
                    (errors[field.name]?.message || errors[field.name]?.type) ??
                    " "
                  }
                  required
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid size={12}>
            <LoadingButton
              fullWidth
              type="submit"
              variant="contained"
              color="primary"
              loading={isSaving}
              size="large"
            >
              Create offer
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};
