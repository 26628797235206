import { API, RequiredQueryOptions } from "./network/API";
import { Page, SortDirection } from "./network/Page";
import { UTCDate } from "./orderAPI";

export type OfferId = string & { isOfferId: true };
export type AttachmentId = string & { isAttachmentId: true };

const QUERY_KEY = "offers";

export type BaseOfferFilters = Partial<{
  createdBy: string;
  email: string;
  phone: string;
  companyId: string;
}>;

export const offerAPI = {
  QUERY_KEY: QUERY_KEY,

  getAll: (
    page: number,
    size: number,
    direction: SortDirection,
    field: keyof Offer,
    filters?: BaseOfferFilters
  ): RequiredQueryOptions<Page<Offer>> => ({
    queryKey: [QUERY_KEY, { page, size, direction, field, ...filters }],
    queryFn: () => {
      const filterParams = Object.entries(filters ?? {})
        .filter(([, value]) => value !== undefined)
        .map(([key, value]) => {
          return `${key}=${value}`;
        });

      return API.get<Page<Offer>>(
        `/admin/offers?page=${page}&size=${size}&sortDirection=${direction}&sortField=${field}${
          filterParams.length > 0 ? `&${filterParams.join("&")}` : ""
        }`
      );
    },
  }),

  get: (offerId: OfferId): RequiredQueryOptions<Offer> => ({
    queryKey: [QUERY_KEY, { offerId }],
    queryFn: () => API.get<Offer>(`/admin/offers/${offerId}`),
  }),

  getAttachments: (
    offerId: OfferId
  ): RequiredQueryOptions<OfferAttachment[]> => ({
    queryKey: [QUERY_KEY, "attachments", { offerId }],
    queryFn: () =>
      API.get<OfferAttachment[]>(`/admin/offers/${offerId}/attachments`),
  }),

  create: (request: CreateOfferRequest) =>
    API.post<Offer>(`/admin/offers`, request),

  update: (request: UpdateOfferRequest) =>
    API.post<Offer>(`/admin/offers/update`, request),

  delete: (id: OfferId) => API.delete(`/admin/offers/${id}`),

  closeOffer: (id: OfferId) => API.post<Offer>(`/admin/offers/${id}/closed`),

  createAttachment: (offerId: OfferId) => ({
    mutationFn: (request: CreateOfferAttachmentRequest) => {
      const formData = new FormData();
      formData.append("file", request.file);
      formData.append("title", request.title);
      formData.append("description", request.description || "");
      return API.postFormData<OfferAttachment>(
        `/admin/offers/${offerId}/attachments`,
        formData
      );
    },
  }),

  deleteAttachment: (offerId: OfferId, attachmentId: AttachmentId) =>
    API.delete(`/admin/offers/${offerId}/attachments/${attachmentId}`),
};

export interface Offer {
  offerId: OfferId;
  createdBy: string;
  created: UTCDate;
  updated: UTCDate;
  lastViewed?: UTCDate; //När kunden senast besökte sidan
  paid?: UTCDate;
  closed?: UTCDate; //Den är färdig, stängd av projektledaren
  header: string;
  description: string;
  price: number;
  currency: string;
  shippingCost?: number;
  includesVat: boolean;
  email?: string;
  phone?: string;
  language: string;
  companyName?: string;
  companyId?: string;
  vatNumber?: string;
  address?: Address;
}

export interface Address {
  name: string;
  street: string;
  city: string;
  postalCode: string;
  country: string; //Country Code
}

export interface OfferAttachment {
  id: AttachmentId;
  title: string;
  description?: string;
  url: string; //Bara URI, ingen host
  mimeType: MimeType;
  created: UTCDate;
}

export enum MimeType {
  PDF = "application/pdf",
  IMAGE = "image/*",
}

export interface CreateOfferAttachmentRequest {
  title: string;
  description?: string;
  file: File;
}

export interface CreateOfferRequest {
  header: string;
}

export type UpdateOfferRequest = Omit<
  Offer,
  "offerId" | "createdBy" | "created" | "updated" | "address"
>;
