import { LoadingButton } from "@mui/lab";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid2 as Grid,
  Skeleton,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import {
  useMutation,
  useQueryClient,
  useSuspenseQueries,
} from "@tanstack/react-query";
import { useCallback, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { UpdateOfferRequest, offerAPI } from "../../api/offerAPI";
import { LayoutBreadcrumbs } from "../../components/Breadcrumbs/LayoutBreadcrumbs";
import { ClipboardButton } from "../../components/CliboardButton/ClipboardButton";
import { FormattedTime } from "../../components/FormattedTime/FormattedTime";
import { Helmet } from "../../components/Helmet";
import { useOfferId } from "../../hooks/useOfferId";
import { useSnackbar } from "../../hooks/useSnackbar";
import { Layout } from "../../layout/Layout";
import { OFFER_LIST_PAGE_ROUTE } from "./OfferListPage";
import { CreateOfferAttachmentSheet } from "./components/CreateOfferAttachmentSheet";
import { OfferAttachmentCard } from "./components/OfferAttachmentCard";
import { OfferForm } from "./components/OfferForm";
import { useNavigate } from "react-router";
import { ConfirmLoadingButton } from "../../components/ConfirmButton/ConfirmButton";

export const OFFER_PAGE_ROUTE = "/offers/:offerId";

export const OfferPage = () => {
  return (
    <Layout
      fullHeight
      title="Offers"
      skeleton={<PageSkeleton />}
      skeletonGap={2}
    >
      <Inner />
    </Layout>
  );
};

const Inner = () => {
  const offerId = useOfferId();
  const [isAttachmentSheetOpen, setIsAttachmentSheetOpen] = useState(false);
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [{ data: offer }, { data: attachments }] = useSuspenseQueries({
    queries: [offerAPI.get(offerId), offerAPI.getAttachments(offerId)],
  });

  const {
    handleSubmit,
    reset: resetForm,
    control,
    formState: { errors },
  } = useForm<UpdateOfferRequest>({
    defaultValues: {
      ...offer,
    },
  });

  const currency = useWatch({ control, name: "currency" });

  const { mutate: saveOffer, isPending: isSaving } = useMutation({
    mutationFn: offerAPI.update,
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [offerAPI.QUERY_KEY],
      });
    },
  });

  const { mutate: closeOffer } = useMutation({
    mutationFn: offerAPI.closeOffer,
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [offerAPI.QUERY_KEY],
      });
    },
  });

  const deleteOffer = () => {
    offerAPI.delete(offerId).then(() => {
      showSnackbar("Deleted offer", "success", 5000);
      navigate(OFFER_LIST_PAGE_ROUTE);
    });
  };

  const onSubmit = useCallback(
    (data: UpdateOfferRequest) => {
      saveOffer(data, {
        onSuccess: (data) => {
          resetForm(data);
          showSnackbar("Saved offer", "success");
        },
        onError: () => {
          showSnackbar("Failed to save offer", "error");
        },
      });
    },
    [resetForm, saveOffer, showSnackbar]
  );

  const customerLink = import.meta.env.DEV
    ? `localhost:5174/offer/${offer.offerId}`
    : `https://offer.vardauzlimes.lv/offer/${offer.offerId}`;

  return (
    <>
      <CreateOfferAttachmentSheet
        isOpen={isAttachmentSheetOpen}
        onClose={() => setIsAttachmentSheetOpen(false)}
      />
      <Helmet title={offer.header} />
      <LayoutBreadcrumbs
        breadcrumbs={[
          { label: "Offers", to: OFFER_LIST_PAGE_ROUTE },
          { label: offer.header },
        ]}
      />
      <Box sx={{ my: 6 }}>
        <Stepper alternativeLabel>
          <Step
            completed={
              offer.lastViewed !== undefined ||
              (offer.paid !== undefined && offer.closed !== undefined)
            }
            active={
              offer.lastViewed === undefined &&
              offer.paid === undefined &&
              offer.closed === undefined
            }
          >
            <StepLabel>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography variant="inherit" gutterBottom>
                  Creating
                </Typography>
                {offer.created && (
                  <Typography variant="caption" color="text.secondary">
                    <FormattedTime value={offer.created} />
                  </Typography>
                )}
              </Box>
            </StepLabel>
          </Step>
          <Step
            completed={offer.lastViewed !== undefined}
            active={offer.lastViewed === undefined && offer.paid === undefined}
          >
            <StepLabel>
              <Typography variant="inherit" gutterBottom>
                Customer review
              </Typography>
              {offer.lastViewed && (
                <Typography variant="caption" color="text.secondary">
                  <FormattedTime value={offer.lastViewed} />
                </Typography>
              )}
            </StepLabel>
          </Step>
          <Step
            completed={offer.paid !== undefined}
            active={offer.lastViewed !== undefined && offer.paid === undefined}
          >
            <StepLabel>
              <Typography variant="inherit" gutterBottom>
                Paid
              </Typography>
              {offer.paid && (
                <Typography variant="caption" color="text.secondary">
                  <FormattedTime value={offer.paid} />
                </Typography>
              )}
            </StepLabel>
          </Step>
          <Step
            completed={offer.closed !== undefined}
            active={
              offer.lastViewed !== undefined &&
              offer.paid !== undefined &&
              offer.closed === undefined
            }
          >
            <StepLabel>
              <Typography variant="inherit" gutterBottom>
                Completed
              </Typography>
              {offer.closed && (
                <Typography variant="caption" color="text.secondary">
                  <FormattedTime value={offer.closed} />
                </Typography>
              )}
            </StepLabel>
          </Step>
        </Stepper>
      </Box>
      <Grid container spacing={3} paddingBottom={8}>
        <Grid
          sx={{ maxWidth: "100%" }}
          size={{
            sm: 12,
            md: 8,
          }}
        >
          <Grid container spacing={3}>
            <Grid sx={{ width: "100%" }}>
              <Box marginBottom={1}>
                <Typography variant="overline" marginLeft={2} lineHeight={3}>
                  Offer details
                </Typography>
              </Box>
              <Card
                hidden={offer.paid === undefined || offer.closed !== undefined}
                sx={{
                  marginBottom: "10px",
                }}
              >
                <CardContent>
                  <LoadingButton
                    fullWidth
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={() => closeOffer(offerId)}
                  >
                    Complete order
                  </LoadingButton>
                </CardContent>
              </Card>
              <Card>
                <CardContent>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <OfferForm
                      disableAll={offer.paid !== undefined}
                      control={control}
                      errors={errors}
                      currency={currency}
                    />
                    <LoadingButton
                      fullWidth
                      disabled={offer.paid !== undefined}
                      type="submit"
                      variant="contained"
                      color="primary"
                      loading={isSaving}
                      size="large"
                    >
                      Update offer
                    </LoadingButton>
                  </form>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          size={{
            sm: 12,
            md: 4,
          }}
        >
          <Grid container spacing={3}>
            <Grid sx={{ width: "100%" }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
                marginBottom={1}
              >
                <Typography variant="overline" marginLeft={2} lineHeight={3}>
                  Customer link
                </Typography>

                <ClipboardButton
                  value={customerLink}
                  disabled={offer.paid !== undefined}
                  feedbackMessage="Copied customer link to clipboard!"
                  color="secondary"
                  size="small"
                  variant="outlined"
                >
                  Copy link
                </ClipboardButton>

                {/* <Button
                size="small"
                color="success"
                disabled
                onClick={() => {
                  // TODO: link to customer
                }}
              >
                Resend
              </Button> */}
              </Box>
              <Card>
                <CardContent
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 1,
                  }}
                >
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "1fr 1fr",
                      gap: 1,
                    }}
                  >
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{
                        flex: 1,
                      }}
                    >
                      Sales agent
                    </Typography>
                    <Typography variant="body2" textAlign="right">
                      <b>{offer.createdBy}</b>
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "1fr 1fr",
                      gap: 1,
                    }}
                  >
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{
                        flex: 1,
                      }}
                    >
                      Last viewed
                    </Typography>
                    <Typography variant="body2" textAlign="right">
                      <FormattedTime value={offer.lastViewed} />
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "1fr 1fr",
                      gap: 1,
                    }}
                  >
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{
                        flex: 1,
                      }}
                    >
                      Paid
                    </Typography>
                    <Typography variant="body2" textAlign="right">
                      <FormattedTime value={offer.paid} />
                    </Typography>
                  </Box>
                  <ConfirmLoadingButton
                    fullWidth
                    endIcon={<DeleteOutlineIcon />}
                    disabled={!!offer.paid}
                    type="button"
                    variant="outlined"
                    color="error"
                    size="small"
                    confirmText="Are you sure you want to delete this offer?"
                    onConfirm={deleteOffer}
                  >
                    Delete
                  </ConfirmLoadingButton>
                </CardContent>
              </Card>
            </Grid>
            <Grid sx={{ width: "100%" }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
                marginBottom={1}
              >
                <Typography variant="overline" marginLeft={2} lineHeight={3}>
                  Attachments
                </Typography>
                <Button
                  disabled={offer.paid !== undefined}
                  variant="outlined"
                  size="small"
                  onClick={() => setIsAttachmentSheetOpen(true)}
                >
                  New attachment
                </Button>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                }}
              >
                {attachments?.length ? (
                  attachments.map((attachment) => (
                    <OfferAttachmentCard
                      disableAll={offer.paid !== undefined}
                      key={attachment.id}
                      offerId={offerId}
                      attachment={attachment}
                    />
                  ))
                ) : (
                  <Card>
                    <CardContent>
                      <Typography color="text.secondary">
                        There are no attachments
                      </Typography>
                    </CardContent>
                  </Card>
                )}
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

const PageSkeleton = () => {
  return (
    <>
      <Skeleton variant="rounded" height={30} />
      <Skeleton variant="rounded" height={160} />
      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid
          size={{
            xs: 12,
            md: 8,
          }}
        >
          <Skeleton variant="rounded" height={600} />
        </Grid>
        <Grid
          sx={{
            gap: 2,
            display: "flex",
            flexDirection: "column",
          }}
          size={{
            xs: 12,
            md: 4,
          }}
        >
          <Skeleton variant="rounded" height={120} />
          <Skeleton variant="rounded" height={120} />
        </Grid>
      </Grid>
    </>
  );
};
