import AddIcon from "@mui/icons-material/Add";
import {
  Fab,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { useState, useTransition } from "react";
import { SortDirection } from "../../api/network/Page";
import { BaseOfferFilters, Offer } from "../../api/offerAPI";
import { SortedTableCell } from "../../components/Table/SortedTableCell";
import { Layout } from "../../layout/Layout";
import { CreateOfferSheet } from "./components/CreateOfferSheet";
import { OfferRow } from "./components/OfferRow";
import { useSearchParams } from "react-router-dom";
import { useSuspendedOffers } from "../../hooks/useOffers";

export const OFFER_LIST_PAGE_ROUTE = "/offers";

const DEFAULT_PAGE_SIZE = 25;

export const OfferListPage = () => {
  return (
    <Layout
      fullHeight
      title="Offers"
      skeleton={<PageSkeleton />}
      skeletonGap={2}
    >
      <Inner />
    </Layout>
  );
};

const Inner = () => {
  const [isCreateOfferSheetOpen, setIsCreateOfferSheetOpen] = useState(false);
  const [isPendingTransition, startTransition] = useTransition();

  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get("page") || "0";
  const size = searchParams.get("size") || DEFAULT_PAGE_SIZE.toString();
  const sortField: keyof Offer =
    (searchParams.get("sortField") as keyof Offer) || "created";
  const sortDirection = (searchParams.get("sortDirection") ||
    SortDirection.DESC) as SortDirection;

  const filter: BaseOfferFilters = {
    createdBy: searchParams.get("createdBy") ?? undefined,
    email: searchParams.get("email") ?? undefined,
    phone: searchParams.get("phone") ?? undefined,
    companyId: searchParams.get("companyId") ?? undefined,
  };

  const { data: data } = useSuspendedOffers(
    parseInt(page),
    parseInt(size),
    sortDirection,
    sortField
    // TODO: filters
  );

  const handleSortChange = (field: keyof Offer) => {
    if (sortField === field) {
      startTransition(() => {
        setSearchParams({
          page: searchParams.get("page") || "0",
          size: searchParams.get("size") || DEFAULT_PAGE_SIZE.toString(),
          ...filterToParams(
            filter,
            field,
            sortDirection === SortDirection.DESC
              ? SortDirection.ASC
              : SortDirection.DESC
          ),
        });
      });
    } else {
      startTransition(() => {
        setSearchParams({
          page: searchParams.get("page") || "0",
          size: searchParams.get("size") || DEFAULT_PAGE_SIZE.toString(),
          ...filterToParams(filter, field, SortDirection.DESC),
        });
      });
    }
  };

  return (
    <>
      <CreateOfferSheet
        onClose={() => setIsCreateOfferSheetOpen(false)}
        isOpen={isCreateOfferSheetOpen}
      />
      <TableContainer sx={{ flex: 1 }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Header</TableCell>
              {sortableHeaders.map((header) => (
                <SortedTableCell
                  key={header.id}
                  field={header.id}
                  currentSortField={sortField}
                  currentSortDirection={sortDirection}
                  onSortChange={handleSortChange}
                  align={header.align}
                >
                  {header.label}
                </SortedTableCell>
              ))}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody
            sx={{
              opacity: isPendingTransition ? 0.5 : 1,
              transition: "opacity 0.15s ease-in-out",
            }}
          >
            {data?.content?.map((offer) => (
              <OfferRow key={offer.offerId} offer={offer} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, DEFAULT_PAGE_SIZE, 50, 100]}
        component="div"
        count={data?.totalElements || 0}
        rowsPerPage={data?.size || DEFAULT_PAGE_SIZE}
        page={data?.number || 0}
        onPageChange={(_, page) => {
          startTransition(() => {
            setSearchParams({
              size: searchParams.get("size") || DEFAULT_PAGE_SIZE.toString(),
              page: page.toString(),
              ...filterToParams(filter, sortField, sortDirection),
            });
          });
        }}
        onRowsPerPageChange={(event) => {
          startTransition(() => {
            setSearchParams({
              page: searchParams.get("page") || "0",
              size: event.target.value,
              ...filterToParams(filter, sortField, sortDirection),
            });
          });
        }}
      />
      <Fab
        color="primary"
        sx={{ position: "absolute", bottom: 60, right: 20 }}
        variant="extended"
        onClick={() => setIsCreateOfferSheetOpen(true)}
        size="large"
      >
        <AddIcon sx={{ mr: 1 }} /> new offer
      </Fab>
    </>
  );
};

const sortableHeaders: {
  id: keyof Offer;
  label: string;
  align?: TableCellProps["align"];
}[] = [
  {
    id: "createdBy",
    label: "User",
    align: "left",
  },
  {
    id: "paid",
    label: "Paid",
    align: "right",
  },
  {
    id: "closed",
    label: "Completed",
    align: "right",
  },
  {
    id: "updated",
    label: "Updated",
    align: "right",
  },
  {
    id: "created",
    label: "Created",
    align: "right",
  },
];

const PageSkeleton = () => {
  return (
    <>
      <Skeleton variant="rounded" height={30} />
      {[...Array(5)].map((_, i) => (
        <Skeleton key={i} variant="rounded" height={50} />
      ))}
    </>
  );
};

const filterToParams = (
  filter: BaseOfferFilters,
  sortField?: keyof Offer,
  sortDirection?: SortDirection
): Record<string, string> => ({
  ...(sortField && { sortField }),
  ...(sortDirection && { sortDirection }),
  ...Object.fromEntries(
    Object.entries(filter)
      .filter(([, value]) => value !== undefined)
      .map(([key, value]) => [key, value.toString()])
  ),
});
