import { Stepper, Step, StepLabel, Box, Typography } from "@mui/material";
import { FunctionComponent } from "react";
import { FormattedTime } from "../../../components/FormattedTime/FormattedTime";
import { BaseOrder } from "../../../api/orderAPI";

interface Props {
  order: BaseOrder;
}

export const OrderStepperDisplay: FunctionComponent<Props> = ({ order }) => {
  return (
    <Stepper alternativeLabel>
      <Step completed={!!order.created}>
        <StepLabel>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography variant="inherit" gutterBottom>
              Created
            </Typography>
            {order.created && (
              <Typography variant="caption" color="text.secondary">
                <FormattedTime value={order.created} />
              </Typography>
            )}
          </Box>
        </StepLabel>
      </Step>
      <Step completed={!!order.paid} active={!!order.created && !order.paid}>
        <StepLabel>
          <Typography variant="inherit" gutterBottom>
            Paid
          </Typography>
          {order.paid && (
            <Typography variant="caption" color="text.secondary">
              <FormattedTime value={order.paid} />
            </Typography>
          )}
        </StepLabel>
      </Step>
      <Step completed={!!order.printed} active={!!order.paid}>
        <StepLabel>
          <Typography variant="inherit" gutterBottom>
            Printed
          </Typography>
          {order.printed && (
            <Typography variant="caption" color="text.secondary">
              <FormattedTime value={order.printed} />
            </Typography>
          )}
        </StepLabel>
      </Step>
      <Step completed={!!order.sentToPost} active={!!order.printed}>
        <StepLabel>
          <Typography variant="inherit" gutterBottom>
            Sent
          </Typography>
          {order.sentToPost && (
            <Typography variant="caption" color="text.secondary">
              <FormattedTime value={order.sentToPost} />
            </Typography>
          )}
        </StepLabel>
      </Step>
    </Stepper>
  );
};
