import {
  TableRow,
  TableCell,
  Box,
  IconButton,
  Typography,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { LoadingButton } from "@mui/lab";
import { Campaign, campaignAPI } from "../../../api/campaignAPI";
import { FormattedDate } from "../../../components/FormattedDate/FormattedDate";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { MuiLink } from "../../../components/MuiLink/MuiLink";
import { generatePath } from "react-router";
import { CAMPAIGN_PAGE_ROUTE } from "../CampaignPage";
import { Link } from "react-router-dom";
import { ConfirmIconButton } from "../../../components/ConfirmButton/ConfirmButton";

interface Props {
  campaign: Campaign;
  onUpdate: (campaign: Campaign) => void;
}

export const CampaignRow: React.FC<Props> = ({ campaign, onUpdate }) => {
  const { mutate: deleteCampaign, isPending: isDeletePending } = useMutation({
    mutationFn: () => campaignAPI.deleteCampaign(campaign.campaignId),
  });

  return (
    <TableRow>
      <TableCell>
        <MuiLink
          component={Link}
          to={generatePath(CAMPAIGN_PAGE_ROUTE, {
            campaignId: campaign.campaignId,
          })}
        >
          {campaign.campaignId}
        </MuiLink>
      </TableCell>
      <TableCell>{campaign.displayName}</TableCell>
      <TableCell align="right">{campaign.rebatePercentage}%</TableCell>
      <TableCell align="right">{campaign.count}</TableCell>
      <TableCell align="right">{campaign.amount}</TableCell>
      <TableCell align="right">
        <Typography noWrap variant="caption">
          <FormattedDate value={campaign.created} />
        </Typography>
      </TableCell>
      <TableCell align="right">
        <Typography noWrap variant="caption">
          <FormattedDate value={campaign.expiry} />
        </Typography>
      </TableCell>
      <TableCell align="right">
        <Box sx={{ display: "flex", gap: 2, justifyContent: "flex-end" }}>
          <IconButton
            color="info"
            size="small"
            onClick={() => onUpdate(campaign)}
          >
            <ModeEditIcon fontSize="inherit" />
          </IconButton>
          <ConfirmIconButton
            onConfirm={deleteCampaign}
            confirmText="Are you sure you want to delete this campaign?"
            color="error"
            size="small"
            disabled={campaign.count !== 0}
          >
            {isDeletePending ? (
              <LoadingButton loading />
            ) : (
              <DeleteOutlineIcon fontSize="inherit" />
            )}
          </ConfirmIconButton>
        </Box>
      </TableCell>
    </TableRow>
  );
};
