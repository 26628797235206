import {
  TableRow,
  TableCell,
  Typography,
  IconButton,
  Box,
  Tooltip,
} from "@mui/material";
import { User, userAPI } from "../../../api/userAPI";
import { FormattedTime } from "../../../components/FormattedTime/FormattedTime";
import { useMutation } from "@tanstack/react-query";
import CheckIcon from "@mui/icons-material/Check";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { ConfirmLoadingButton } from "../../../components/ConfirmButton/ConfirmButton";
import ContentCopy from "@mui/icons-material/ContentCopy";
import { LOGIN_PAGE_ROUTE } from "../../Auth/LoginPage";
import { useSnackbar } from "../../../hooks/useSnackbar";

interface Props {
  user: User;
  onUpdate: (user: User) => void;
}

export const UserRow: React.FC<Props> = ({ user, onUpdate }) => {
  const { showSnackbar } = useSnackbar();
  const {
    mutate: resetAuth,
    isSuccess: resetAuthSuccess,
    isPending: resetAuthPending,
  } = useMutation({
    mutationFn: () => userAPI.deleteUserAuth(user.userId),
  });

  return (
    <TableRow>
      <TableCell>{user.displayName}</TableCell>
      <TableCell>{user.email}</TableCell>
      <TableCell align="right">
        <Typography noWrap variant="caption">
          <FormattedTime value={user.lastLogin} />
        </Typography>
      </TableCell>
      <TableCell align="right">
        <Box
          sx={{
            display: "flex",
            gap: 2,
            justifyContent: "flex-end",
          }}
        >
          <IconButton color="info" size="small" onClick={() => onUpdate(user)}>
            <ModeEditIcon fontSize="inherit" />
          </IconButton>

          <Tooltip title="Copy login link" placement="top">
            <IconButton
              color="info"
              size="small"
              onClick={() => {
                try {
                  navigator.clipboard.writeText(getLoginLink(user));
                  showSnackbar("Link copied to clipboard");
                } catch (error) {
                  showSnackbar("Failed to copy link to clipboard", "error");
                }
              }}
            >
              <ContentCopy fontSize="inherit" />
            </IconButton>
          </Tooltip>

          <ConfirmLoadingButton
            confirmText="Are you sure you want to reset this user's auth?"
            variant="outlined"
            size="small"
            onConfirm={resetAuth}
            loading={resetAuthPending}
            startIcon={resetAuthSuccess ? <CheckIcon /> : undefined}
            disabled={resetAuthSuccess}
          >
            {resetAuthSuccess ? "Reset" : "Reset auth"}
          </ConfirmLoadingButton>
        </Box>
      </TableCell>
    </TableRow>
  );
};

const getLoginLink = (user: User) => {
  return `${window.location.origin}${LOGIN_PAGE_ROUTE}?email=${user.email}`;
};
