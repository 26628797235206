import {
  Backdrop,
  Badge,
  BadgeProps,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid2 as Grid,
  InputLabel,
  MenuItem,
  Popover,
  Select,
  Switch,
} from "@mui/material";
import { FunctionComponent, useState } from "react";
import { BaseOrderFilters, Origin, orderAPI } from "../../../api/orderAPI";
import { countryFromCountryCode } from "../../../utils";
import { useQuery } from "@tanstack/react-query";
import { campaignAPI } from "../../../api/campaignAPI";

interface Props {
  onChange: (filter: BaseOrderFilters) => void;
  filter: BaseOrderFilters;
}

export const OrderFilters: FunctionComponent<Props> = ({
  onChange,
  filter,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "filter-popover" : undefined;

  const activeFilterCount = Object.values(filter).filter((v) => !!v).length;

  return (
    <>
      <Badge
        badgeContent={activeFilterCount}
        color="primary"
        slotProps={{
          badge: {
            sx: {
              cursor: "pointer",
              "&:hover": { backgroundColor: "error.main" },
              transition: "background-color 0.2s",
            },
            onClick: () => onChange({}),
          } as BadgeProps,
        }}
      >
        <Button
          aria-describedby={id}
          variant="outlined"
          color={activeFilterCount > 0 ? "primary" : "inherit"}
          onClick={handleClick}
          sx={{ zIndex: (theme) => (open ? theme.zIndex.drawer + 2 : "auto") }}
        >
          Filters
        </Button>
      </Badge>
      <Backdrop
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <Popover
          onClick={(e) => {
            e.stopPropagation();
          }}
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          slotProps={{
            paper: {
              sx: {
                width: (theme) => ({
                  xs: "100%",
                  sm: theme.breakpoints.values.sm / 1.5,
                }),
              },
            },
          }}
        >
          <Box sx={{ p: 2 }}>
            <OrderFilterView {...{ onChange, filter }} />
          </Box>
        </Popover>
      </Backdrop>
    </>
  );
};

const OrderFilterView: FunctionComponent<Props> = ({ onChange, filter }) => {
  const { data: campaigns } = useQuery(campaignAPI.fetchListOfCampaigns());

  const { data: attributions } = useQuery(orderAPI.fetchAttributions());

  return (
    <div>
      <Grid container spacing={2}>
        <Grid size={12}>
          <FormControl fullWidth>
            <InputLabel id="origin-filter">Origin</InputLabel>
            <Select
              labelId="origin-filter"
              value={filter.origin}
              onChange={(e) => {
                onChange?.({
                  ...filter,
                  origin: e.target.value as Origin,
                });
              }}
            >
              <MenuItem value={undefined}>All origins</MenuItem>
              {ORIGIN_ALTERNATIVES.map((origin) => (
                <MenuItem key={origin} value={origin}>
                  {origin}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid size={12}>
          <FormControl fullWidth>
            <InputLabel id="country-filter">Country</InputLabel>
            <Select
              labelId="country-filter"
              value={filter.country}
              onChange={(e) => {
                onChange?.({
                  ...filter,
                  country: e.target.value as string,
                });
              }}
            >
              <MenuItem value={undefined}>All countries</MenuItem>
              {COUNTRIES.map((country) => (
                <MenuItem key={country} value={country}>
                  {countryFromCountryCode(country)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid size={12}>
          <FormControl fullWidth>
            <InputLabel id="campaign-filter">Campaign</InputLabel>
            <Select
              labelId="campaign-filter"
              value={filter.campaignId}
              onChange={(e) => {
                onChange?.({
                  ...filter,
                  campaignId: e.target.value,
                });
              }}
            >
              <MenuItem value={undefined}>All</MenuItem>
              {campaigns?.map(({ campaignId, displayName }) => (
                <MenuItem key={campaignId} value={campaignId}>
                  {displayName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid size={12}>
          <FormControl fullWidth>
            <InputLabel id="campaign-filter">Attribution</InputLabel>
            <Select
              labelId="attribution-filter"
              value={filter.attribution}
              onChange={(e) => {
                onChange?.({
                  ...filter,
                  attribution: e.target.value,
                });
              }}
            >
              <MenuItem value={undefined}>All</MenuItem>
              {attributions?.map(({ name }) => (
                <MenuItem key={name} value={name}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid size={12}>
          <FormControlLabel
            control={
              <Switch
                checked={filter.includeUnpaid || false}
                onChange={(e) => {
                  onChange?.({
                    ...filter,
                    includeUnpaid: e.target.checked,
                  });
                }}
              />
            }
            label="Include unpaid orders"
          />
        </Grid>
        <Grid size={12}>
          <Button
            variant="outlined"
            color="primary"
            fullWidth
            onClick={() => {
              onChange({});
            }}
          >
            Reset filters
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

// TODO: replace with non-hardcoded values
const COUNTRIES = ["LV", "LT", "EE", "FI", "SE", "NO", "DK"];

// TODO: replace with non-hardcoded values from BE
const ORIGIN_ALTERNATIVES = Object.values(Origin).filter(
  (v) => import.meta.env.DEV || v !== Origin.LOCAL_DEV
);
