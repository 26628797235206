import { Box, Card, CardContent, CardMedia, Typography } from "@mui/material";
import {
  MimeType,
  OfferAttachment,
  OfferId,
  offerAPI,
} from "../../../api/offerAPI";
import { MuiLink } from "../../../components/MuiLink/MuiLink";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import PhotoIcon from "@mui/icons-material/Photo";
import { ConfirmIconButton } from "../../../components/ConfirmButton/ConfirmButton";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Link } from "react-router-dom";
import { BACKEND } from "../../../api/network/API";

interface Props {
  disableAll: boolean;
  offerId: OfferId;
  attachment: OfferAttachment;
}

export const OfferAttachmentCard: React.FC<Props> = ({
  disableAll,
  offerId,
  attachment,
}) => {
  const queryClient = useQueryClient();

  const { mutate, isPending } = useMutation({
    mutationFn: () => offerAPI.deleteAttachment(offerId, attachment.id),
    onSuccess: () => {
      queryClient.invalidateQueries(offerAPI.getAttachments(offerId));
    },
  });

  return (
    <Card
      sx={{
        display: "flex",
        opacity: isPending ? 0.5 : 1,
        pointerEvents: isPending ? "none" : "auto",
      }}
    >
      <CardMedia
        sx={{
          width: "100%",
          maxWidth: 120,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: (theme) => theme.palette.primary.main + "50",
        }}
      >
        {attachment.mimeType === MimeType.PDF ? (
          <PictureAsPdfIcon fontSize="large" />
        ) : (
          <PhotoIcon fontSize="large" />
        )}
      </CardMedia>
      <CardContent
        sx={{
          flex: 1,
        }}
      >
        <Typography variant="body1" gutterBottom noWrap>
          {attachment.title}
        </Typography>

        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            display: "-webkit-box",
            overflow: "hidden",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 3,
          }}
        >
          {attachment.description || "No description"}
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <MuiLink
            target="_blank"
            rel="noopener noreferrer"
            to={BACKEND + attachment.url}
            component={Link}
            variant="caption"
          >
            View attachment
          </MuiLink>
          <ConfirmIconButton
            disabled={disableAll}
            size="small"
            title="Are you sure you want to delete this attachment?"
            onConfirm={() => mutate()}
            color="error"
            confirmText="Are you sure you want to delete this attachment?"
          >
            <DeleteOutlineIcon fontSize="small" />
          </ConfirmIconButton>
        </Box>
      </CardContent>
    </Card>
  );
};
