import { Box, Typography } from "@mui/material";
import { motion } from "framer-motion";
import { useState } from "react";
import { Layout } from "../../layout/Layout";
import { LoginViewMobile } from "./components/LoginViewMobile";
import { LoginViewDesktop } from "./components/LoginViewDesktop";
import { isMobile } from "react-device-detect";
import { useSearchParams } from "react-router-dom";

export const LOGIN_PAGE_ROUTE = "/login";

export const LoginPage = () => {
  const [searchParams] = useSearchParams();
  const email = searchParams.get("email") || undefined;

  const [greeting] = useState(
    GREETINGS[Math.floor(Math.random() * GREETINGS.length)]
  );

  return (
    <Layout fullScreen fullHeight title="Login">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: {
            xs: "stretch",
            sm: "center",
          },
          flex: 1,
          m: 4,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            maxWidth: {
              xs: "100%",
              sm: "350px",
            },
            width: "100%",
          }}
        >
          <Box
            sx={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
              component={motion.div}
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              transition={{
                type: "spring",
                stiffness: 100,
                damping: 8,
              }}
            >
              <Typography
                initial={{ rotate: 0 }}
                animate={{
                  rotate: [0, 15, -15, 15, -15, 15, -15, 0],
                }}
                transition={{
                  repeat: Infinity,
                  repeatType: "mirror",
                  duration: 10,
                }}
                sx={{
                  transformOrigin: "bottom right",
                }}
                component={motion.div}
                variant="h2"
                align="center"
                gutterBottom
              >
                👋
              </Typography>
            </Box>
            <Typography
              variant="body1"
              align="center"
              fontWeight={600}
              marginBottom={3}
            >
              {greeting.firstPart}
            </Typography>
            <Typography variant="body2" align="center" color="textSecondary">
              {greeting.secondPart}
            </Typography>
          </Box>
          {isMobile ? (
            <LoginViewMobile email={email} />
          ) : (
            <LoginViewDesktop email={email} />
          )}
        </Box>
      </Box>
    </Layout>
  );
};

const GREETINGS = [
  {
    firstPart: "Welcome to a world of color and creativity!",
    secondPart: "Sign in and start sticking to your dreams!",
  },
  {
    firstPart: "Ready to make your mark with some sticker magic?",
    secondPart: "Sign in and let's create something unforgettable!",
  },
  {
    firstPart: "Step into a world where your ideas stick!",
    secondPart: "Sign in and unleash your inner artist!",
  },
  {
    firstPart: "Get ready to peel, stick, and dazzle!",
    secondPart: "Sign in and join the fun of sticker storytelling!",
  },
  {
    firstPart: "Unlock the treasure chest of stickers!",
    secondPart: "Sign in and explore an array of adhesive art!",
  },
  {
    firstPart: "Sticker enthusiasts, assemble!",
    secondPart: "Sign in and let the sticking spree begin!",
  },
  {
    firstPart: "Ready to turn your visions into vinyls?",
    secondPart: "Sign in now and start your sticker journey!",
  },
];
