import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { Layout } from "../../layout/Layout";
import { useSuspenseQuery } from "@tanstack/react-query";
import { useMemo, useState } from "react";
import { User, userAPI } from "../../api/userAPI";
import { CreateUserSheet } from "./components/CreateUserSheet";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Fab,
  Skeleton,
} from "@mui/material";
import { UserRow } from "./components/UserRow";
import { SortDirection } from "../../api/network/Page";
import { SortedTableCell } from "../../components/Table/SortedTableCell";
import { createSortFunction } from "../../utils";
import { UpdateUserSheet } from "./components/UpdateUserSheet";

export const USERS_PAGE_ROUTE = "/users";

export const UsersPage = () => {
  return (
    <Layout
      fullHeight
      title="Users"
      skeleton={<PageSkeleton />}
      skeletonGap={2}
    >
      <InnerUsersPage />
    </Layout>
  );
};

const InnerUsersPage = () => {
  const [isCreateUserSheetOpen, setIsCreateUserSheetOpen] = useState(false);
  const [updateUser, setUpdateUser] = useState<User>();

  const { data: unsortedData } = useSuspenseQuery(userAPI.fetchUsers());

  const [sortField, setSortField] = useState<keyof User>("created");
  const [sortDirection, setSortDirection] = useState<SortDirection>(
    SortDirection.DESC
  );

  const data = useMemo(
    () => unsortedData?.sort(createSortFunction(sortField, sortDirection)),
    [unsortedData, sortField, sortDirection]
  );

  const handleSortChange = (field: keyof User) => {
    if (field === sortField) {
      setSortDirection(
        sortDirection === SortDirection.DESC
          ? SortDirection.ASC
          : SortDirection.DESC
      );
    } else {
      setSortField(field);
      setSortDirection(SortDirection.DESC);
    }
  };

  return (
    <>
      <CreateUserSheet
        onClose={() => setIsCreateUserSheetOpen(false)}
        isOpen={isCreateUserSheetOpen}
      />
      <UpdateUserSheet
        onClose={() => setUpdateUser(undefined)}
        user={updateUser}
      />
      <TableContainer sx={{ flex: 1 }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <SortedTableCell
                field="displayName"
                currentSortField={sortField}
                currentSortDirection={sortDirection}
                onSortChange={handleSortChange}
              >
                Display name
              </SortedTableCell>
              <SortedTableCell
                field="email"
                currentSortField={sortField}
                currentSortDirection={sortDirection}
                onSortChange={handleSortChange}
              >
                Email
              </SortedTableCell>
              <SortedTableCell
                field="lastLogin"
                currentSortField={sortField}
                currentSortDirection={sortDirection}
                onSortChange={handleSortChange}
                align="right"
              >
                Last login
              </SortedTableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((user) => (
              <UserRow key={user.userId} user={user} onUpdate={setUpdateUser} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Fab
        color="primary"
        sx={{ position: "absolute", bottom: 20, right: 20 }}
        variant="extended"
        onClick={() => setIsCreateUserSheetOpen(true)}
        size="medium"
      >
        <PersonAddIcon sx={{ mr: 1 }} /> new user
      </Fab>
    </>
  );
};

const PageSkeleton = () => {
  return (
    <>
      <Skeleton variant="rounded" height={30} />
      {[...Array(5)].map((_, i) => (
        <Skeleton key={i} variant="rounded" height={50} />
      ))}
    </>
  );
};
