import { LoadingButton, LoadingButtonProps } from "@mui/lab";
import {
  Button,
  ButtonProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  FabProps,
  IconButton,
  IconButtonProps,
} from "@mui/material";
import { ReactNode, useState } from "react";

interface ConfirmButtonProps extends ButtonProps {
  confirmText: string;
  onConfirm: () => void;
  children: ReactNode;
}

export const ConfirmButton = ({
  confirmText,
  onConfirm,
  children,
  ...props
}: ConfirmButtonProps) => {
  const [open, setOpen] = useState<boolean>(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleConfirm = () => {
    onConfirm();
    handleClose();
  };

  return (
    <>
      <Button {...props} onClick={handleOpen}>
        {children}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="confirm-modal-title"
        aria-describedby="confirm-modal-description"
      >
        <DialogTitle>Please confirm</DialogTitle>
        <DialogContent>{confirmText}</DialogContent>

        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleConfirm}>Ok</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

interface ConfirmLoadingButtonProps extends LoadingButtonProps {
  confirmText: string;
  onConfirm: () => void;
  children: ReactNode;
}

export const ConfirmLoadingButton = ({
  confirmText,
  onConfirm,
  children,
  ...props
}: ConfirmLoadingButtonProps) => {
  const [open, setOpen] = useState<boolean>(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleConfirm = () => {
    onConfirm();
    handleClose();
  };

  return (
    <>
      <LoadingButton {...props} onClick={handleOpen}>
        {children}
      </LoadingButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="confirm-modal-title"
        aria-describedby="confirm-modal-description"
      >
        <DialogTitle>Please confirm</DialogTitle>
        <DialogContent>{confirmText}</DialogContent>

        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleConfirm}>Ok</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

interface ConfirmIconButtonProps extends IconButtonProps {
  confirmText: string;
  onConfirm: () => void;
  children: ReactNode;
}

export const ConfirmIconButton = ({
  confirmText,
  onConfirm,
  children,
  ...props
}: ConfirmIconButtonProps) => {
  const [open, setOpen] = useState<boolean>(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleConfirm = () => {
    onConfirm();
    handleClose();
  };

  return (
    <>
      <IconButton {...props} onClick={handleOpen}>
        {children}
      </IconButton>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Please confirm</DialogTitle>
        <DialogContent>{confirmText}</DialogContent>

        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleConfirm}>Ok</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

interface ConfirmFabButtonProps extends FabProps {
  confirmText: string;
  onConfirm: () => void | Promise<unknown>;
  children: ReactNode;
}

export const ConfirmFab = ({
  confirmText,
  onConfirm,
  children,
  ...props
}: ConfirmFabButtonProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleConfirm = async () => {
    setLoading(true);
    try {
      await onConfirm();
    } finally {
      setLoading(false);
      handleClose();
    }
  };

  return (
    <>
      <Fab {...props} onClick={handleOpen}>
        {children}
      </Fab>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Please confirm</DialogTitle>
        <DialogContent>{confirmText}</DialogContent>

        <DialogActions>
          <LoadingButton loading={loading} onClick={handleClose}>
            Cancel
          </LoadingButton>
          <LoadingButton loading={loading} onClick={handleConfirm}>
            Ok
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};
