import { TableRow, TableCell, Typography } from "@mui/material";
import { FormattedDate } from "../../../components/FormattedDate/FormattedDate";
import { MuiLink } from "../../../components/MuiLink/MuiLink";
import { generatePath } from "react-router";
import { Link } from "react-router-dom";
import { OFFER_PAGE_ROUTE } from "../OfferPage";
import { Offer } from "../../../api/offerAPI";

interface Props {
  offer: Offer;
}

export const OfferRow: React.FC<Props> = ({ offer }) => {
  return (
    <TableRow>
      <TableCell>
        <MuiLink
          component={Link}
          to={generatePath(OFFER_PAGE_ROUTE, {
            offerId: offer.offerId,
          })}
        >
          {offer.header}
        </MuiLink>
      </TableCell>
      <TableCell>{offer.createdBy}</TableCell>
      <TableCell align="right">
        <Typography noWrap variant="caption">
          <FormattedDate value={offer.paid} />
        </Typography>
      </TableCell>
      <TableCell align="right">
        <Typography noWrap variant="caption">
          <FormattedDate value={offer.closed} />
        </Typography>
      </TableCell>
      <TableCell align="right">
        <Typography noWrap variant="caption">
          <FormattedDate value={offer.updated} />
        </Typography>
      </TableCell>
      <TableCell align="right">
        <Typography noWrap variant="caption">
          <FormattedDate value={offer.created} />
        </Typography>
      </TableCell>
    </TableRow>
  );
};
