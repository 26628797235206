import {
  AuthenticationPublicKeyCredential,
  CredentialCreationOptionsJSON,
  CredentialRequestOptionsJSON,
  RegistrationPublicKeyCredential,
} from "@github/webauthn-json/browser-ponyfill";
import { API, RequiredQueryOptions } from "./network/API";
import { UserRight } from "./userAPI";

export type AuthTokenId = string & { isAuthTokenId: true };
export type AuthRequestId = string & { isAuthRequestId: true };

export enum AuthTokenStatus {
  PENDING = "PENDING",
  CONFIRMED = "CONFIRMED",
  ABORTED = "ABORTED",
  GONE = "GONE",
}

enum AuthFlow {
  ASSERTION = "ASSERTION",
  REGISTRATION = "REGISTRATION",
}

enum LoginType {
  LOCAL,
  TOKEN,
}

interface AuthResponse {
  displayName: string;
  loginType: LoginType;
}

export interface AuthTokenSession {
  tokenId: AuthTokenId;
  status: AuthTokenStatus;
}

export interface AuthLoginSession extends CredentialRequestOptionsJSON {
  requestId: AuthRequestId;
  flow: AuthFlow.ASSERTION;
}

interface AuthRegisterSession extends CredentialCreationOptionsJSON {
  requestId: AuthRequestId;
  flow: AuthFlow.REGISTRATION;
}

type AuthSession = AuthRegisterSession | AuthLoginSession;

export type AuthSessionRequest =
  | {
      email: string;
    }
  | { tokenId: AuthTokenId };

interface User {
  displayName: string;
  rights: UserRight[];
}

export const isLoginSession = (
  session: AuthSession
): session is AuthLoginSession => session.flow === AuthFlow.ASSERTION;

export const isRegisterSession = (
  session: AuthSession
): session is AuthRegisterSession => session.flow === AuthFlow.REGISTRATION;

const QUERY_KEY = "auth";

export const authAPI = {
  QUERY_KEY: QUERY_KEY,

  whoami: (): RequiredQueryOptions<User> => ({
    queryKey: [QUERY_KEY, "whoami"],
    queryFn: () => API.get<User>(`/admin/whoami`),
    refetchOnWindowFocus: false,
  }),

  initWebAuthNSession: (request: AuthSessionRequest) =>
    API.post<AuthSession>(`/auth/start`, {
      ...request,
    }),

  initRemoteAuthSession: (email: string) =>
    API.post<AuthTokenSession>(`/auth/token`, {
      email,
    }),

  getRemoteAuthSession: (
    authTokenId: AuthTokenId
  ): RequiredQueryOptions<AuthTokenSession> => ({
    queryKey: [QUERY_KEY, { authTokenId: authTokenId }],
    queryFn: () => API.get<AuthTokenSession>(`/auth/token/${authTokenId}`),
    staleTime: 0,
  }),

  login: (
    requestId: AuthRequestId,
    credential: AuthenticationPublicKeyCredential
  ) =>
    API.post<AuthResponse>(`/auth/login`, {
      requestId,
      credential,
    }),

  register: (
    requestId: AuthRequestId,
    credential: RegistrationPublicKeyCredential
  ) =>
    API.post<AuthResponse>(`/auth/register`, {
      requestId,
      credential,
    }),

  logout: () => API.post(`/auth/logout`),
};
