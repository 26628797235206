import { Typography, Button, TextField, Grid, Box } from "@mui/material";
import { useNavigate } from "react-router";
import { AnimatePresence, motion } from "framer-motion";
import { useCallback } from "react";
import FingerprintIcon from "@mui/icons-material/Fingerprint";
import { useWebAuthNSession } from "../hooks/useWebAuthNSession";
import { useForm } from "react-hook-form";

interface Props {
  email?: string;
}

export const LoginViewMobile: React.FC<Props> = ({ email }) => {
  const navigate = useNavigate();

  const { startAuth, state } = useWebAuthNSession({
    onFinished: () => {
      navigate("/");
    },
  });

  const { handleSubmit, register } = useForm<{ email: string }>({
    defaultValues: {
      email: email || localStorage.getItem("email") || "",
    },
  });

  const onSubmit = useCallback(
    (data: { email: string }) => {
      localStorage.setItem("email", data.email);
      startAuth({ email: data.email });
    },
    [startAuth]
  );

  return (
    <>
      <Box sx={{ mt: "auto" }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container direction="column" spacing={4}>
            <Grid item>
              <TextField
                fullWidth
                label="Email"
                {...register("email", {
                  required: true,
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: "Entered value does not match email format",
                  },
                })}
                autoComplete="email webauthn"
                type="email"
                required
              />
            </Grid>
            <Grid item>
              <Button type="submit" size="large" variant="contained" fullWidth>
                sign in <FingerprintIcon sx={{ ml: 1 }} />
              </Button>
            </Grid>
          </Grid>
        </form>
        <AnimatePresence>
          {state === "ERROR" && (
            <Typography
              align="center"
              variant="body2"
              color="error"
              sx={{ mt: 2 }}
              component={motion.div}
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: "auto" }}
              exit={{ opacity: 0, height: 0 }}
            >
              That didn't work out... please try again.
            </Typography>
          )}
        </AnimatePresence>
      </Box>
    </>
  );
};
