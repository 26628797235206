import {
  keepPreviousData,
  useQuery,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { BaseOrder, BaseOrderFilters, orderAPI } from "../api/orderAPI";
import { SortDirection } from "../api/network/Page";

export const useOrders = (
  page: number,
  size: number,
  direction: SortDirection,
  field: keyof BaseOrder,
  filters?: BaseOrderFilters
) => {
  return useQuery({
    ...orderAPI.fetchOrders(page, size, direction, field, filters),
    placeholderData: keepPreviousData,
  });
};

export const useSuspendedOrders = (
  page: number,
  size: number,
  direction: SortDirection,
  field: keyof BaseOrder,
  filters?: BaseOrderFilters
) => {
  return useSuspenseQuery(
    orderAPI.fetchOrders(page, size, direction, field, filters)
  );
};
