import { useContext } from "react";
import { GlobalSearchContext } from "../state/GlobalSearchContext";

export const useGlobalSearch = () => {
  const context = useContext(GlobalSearchContext);

  if (!context) {
    throw new Error(
      "useGlobalSearch must be used within an GlobalSearchProvider"
    );
  }

  return context;
};
