import { Box, Typography } from "@mui/material";
import { Layout } from "../../layout/Layout";

export const PRICING_PAGE_ROUTE = "/pricing";

export const PricingPage = () => {
  return (
    <Layout fullHeight title="Pricing">
      <InnerPricingPage />
    </Layout>
  );
};

const InnerPricingPage = () => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          my: 2,
          mt: 10,
          gap: 2,
        }}
      >
        <Typography variant="h2" component="h1">
          🥺
        </Typography>
        <Typography variant="h5" component="h1">
          Nothing here yet
        </Typography>
        <Typography variant="overline" component="h2" color="text.secondary">
          Check back later
        </Typography>
      </Box>
    </>
  );
};
