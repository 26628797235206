import { useMemo } from "react";

const canVibrate = window.navigator.vibrate as unknown as boolean;

export enum FeedbackSound {
  BLIP = "/blip.mp3",
  KACHING = "/kaching.mp3",
}

export const useFeedback = (sound?: FeedbackSound) => {
  return useMemo(
    () => () => {
      if (sound) {
        new Audio(sound).play();
      }
      if (canVibrate) {
        navigator.vibrate(100);
      }
    },
    [sound]
  );
};
