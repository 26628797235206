import { FunctionComponent, Suspense } from "react";
import {
  Alert,
  Box,
  Button,
  Skeleton,
  SwipeableDrawer,
  Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { orderAPI, OrderId } from "../../../api/orderAPI";
import { useSuspenseOrder } from "../../../hooks/useOrder";
import { ErrorBoundary } from "react-error-boundary";
import {
  QueryErrorResetBoundary,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import { generatePath, useNavigate } from "react-router";
import { ORDER_PAGE_ROUTE } from "../../Order/OrderPage";
import { Flag } from "../../../components/Flag";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "../../../hooks/useSnackbar";
import { FeedbackSound, useFeedback } from "../../../hooks/useFeedback";

interface Props {
  onClose: () => void;
  onMarkAsSent: (orderId: OrderId) => void;
  orderId: OrderId;
}

const Puller = () => {
  return (
    <Box
      sx={{
        width: 30,
        height: 6,
        backgroundColor: grey[300],
        borderRadius: 3,
        position: "absolute",
        top: 8,
        left: "calc(50% - 15px)",
      }}
    />
  );
};

const drawerBleeding = 56;

export const ScannedOrderSheet: FunctionComponent<
  Pick<Props, "onClose" | "onMarkAsSent"> & Partial<Pick<Props, "orderId">>
> = ({ onClose, onMarkAsSent, orderId }) => {
  const isOpen = !!orderId;

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={isOpen}
      onClose={onClose}
      onOpen={() => {
        // do nothing
      }}
      swipeAreaWidth={drawerBleeding}
      disableSwipeToOpen={true}
      PaperProps={{
        sx: {
          borderTopLeftRadius: 10,
          borderTopRightRadius: 10,
        },
      }}
    >
      <Box
        sx={{
          maxWidth: 600,
          margin: "0 auto",
          position: "relative",
          width: "100%",
        }}
      >
        <Puller />
        <Box sx={{ p: 2 }}>
          <QueryErrorResetBoundary>
            {({ reset }) => (
              <>
                <ErrorBoundary
                  key={location.pathname}
                  onReset={reset}
                  fallbackRender={({ resetErrorBoundary }) => (
                    <>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Typography variant="h5" gutterBottom>
                          Oops, something went wrong
                        </Typography>
                        <Typography variant="subtitle1" gutterBottom>
                          We couldn't load the order or it wasn't found.
                        </Typography>
                        <Button
                          onClick={resetErrorBoundary}
                          variant="outlined"
                          size="medium"
                          sx={{ mt: 2 }}
                        >
                          Try again
                        </Button>
                      </Box>
                    </>
                  )}
                >
                  <Suspense
                    fallback={
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 2,
                        }}
                      >
                        <Skeleton variant="rounded" height={40} />
                        <Skeleton variant="rounded" height={200} />
                      </Box>
                    }
                  >
                    {isOpen && (
                      <InnerSheet {...{ onClose, orderId, onMarkAsSent }} />
                    )}
                  </Suspense>
                </ErrorBoundary>
              </>
            )}
          </QueryErrorResetBoundary>
        </Box>
      </Box>
    </SwipeableDrawer>
  );
};

const InnerSheet: FunctionComponent<Props> = ({
  onClose,
  onMarkAsSent,
  orderId,
}) => {
  const queryClient = useQueryClient();
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const feedback = useFeedback(FeedbackSound.BLIP);

  const { data } = useSuspenseOrder(orderId);

  const { mutate: markAsSent, isPending } = useMutation({
    mutationFn: orderAPI.markAsSent,
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [orderAPI.QUERY_KEY],
      });
    },
    onError: () => {
      showSnackbar("Failed to mark order as sent", "error");
    },
    onSuccess: () => {
      feedback();
      onMarkAsSent(data.orderId);
      showSnackbar(`Order ${data.orderId} marked as sent!`, "success");
      onClose();
    },
  });

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
        height: "100%",
        pt: 2,
        gap: 2,
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          pt: 0,
          pl: 1,
          fontSize: 32,
        }}
      >
        <Flag country={data.country} />
      </Box>

      <Typography variant="h4" fontWeight={700} color="primary">
        {data.orderId}
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography fontSize={32}></Typography>

        <Typography fontWeight={500}>{data.address?.name}</Typography>

        <Typography>{data.address?.city}</Typography>
        <Typography>{data.address?.street}</Typography>
      </Box>
      <Button
        variant="text"
        size="large"
        onClick={() => {
          navigate(
            generatePath(ORDER_PAGE_ROUTE, {
              orderId,
            })
          );
          onClose();
        }}
      >
        View order
      </Button>
      {data.sentToPost ? (
        <Alert
          variant="filled"
          severity="success"
          icon={false}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          Order was marked as sent
        </Alert>
      ) : data.paid ? (
        <LoadingButton
          variant="contained"
          onClick={() => markAsSent(data.orderId)}
          size="large"
          loading={isPending}
          fullWidth
        >
          Mark as sent
        </LoadingButton>
      ) : (
        <Alert
          variant="filled"
          severity="warning"
          icon={false}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          Order is awaiting payment
        </Alert>
      )}
    </Box>
  );
};
