import { API, RequiredQueryOptions } from "./network/API";
import { OrderId, UTCDate } from "./orderAPI";

const QUERY_KEY = "dashboard";

export interface DashboardOrder {
  orderId: OrderId;
  country: string;
  paid: UTCDate;
  city: string;
  finalPrice: number;
  currency: string;
  attribution?: string;
  campaign?: string;
}

export interface DashboardStatisticValues {
  orderValue: number;
  orderCount: number;
}

export interface DashboardStatistics {
  today: DashboardStatisticValues;
  yesterday: DashboardStatisticValues;
  thisMonth: DashboardStatisticValues;
  previousMonth: DashboardStatisticValues;
}

export interface DashboardDailyStatistics {
  date: UTCDate;
  orderValue: number;
  orderCount: number;
}

export const dashboardAPI = {
  QUERY_KEY: QUERY_KEY,

  fetchLatestOrder: (size: number): RequiredQueryOptions<DashboardOrder[]> => ({
    queryKey: [QUERY_KEY, "orders", { size }],
    queryFn: () => API.get(`/admin/dashboard/latest-orders?size=${size}`),
  }),

  fetchStatistics: (): RequiredQueryOptions<DashboardStatistics> => ({
    queryKey: [QUERY_KEY, "stats"],
    queryFn: () => API.get("/admin/dashboard/stats"),
  }),

  fetchWeeklyGraph: (): RequiredQueryOptions<DashboardDailyStatistics[]> => ({
    queryKey: [QUERY_KEY, "graph", { type: "weekly" }],
    queryFn: () => API.get("/admin/dashboard/graph"),
  }),
};
