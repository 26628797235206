import { useParams } from "react-router";
import { Layout } from "../../layout/Layout";
import { RemoteAuthView } from "./components/RemoteAuthView";
import { AuthTokenId } from "../../api/authAPI";
import { NotFoundPage } from "../../routes/NotFoundPage";

export const REMOTE_AUTH_ROUTE = "/auth/:authTokenId";

export const RemoteAuthPage = () => {
  const { authTokenId } = useParams<{ authTokenId: AuthTokenId }>();

  if (!authTokenId) {
    return <NotFoundPage />;
  }

  return (
    <Layout fullHeight title="Authenticate login">
      <RemoteAuthView {...{ authTokenId }} />
    </Layout>
  );
};
