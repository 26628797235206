import { FunctionComponent, useCallback } from "react";
import {
  Autocomplete,
  Box,
  Checkbox,
  Grid2 as Grid,
  SwipeableDrawer,
  TextField,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "../../../hooks/useSnackbar";
import {
  CreateUser,
  USER_RIGHT_ALTERNATIVES,
  userAPI,
} from "../../../api/userAPI";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const Puller = () => {
  return (
    <Box
      sx={{
        width: 30,
        height: 6,
        backgroundColor: grey[300],
        borderRadius: 3,
        position: "absolute",
        top: 8,
        left: "calc(50% - 15px)",
      }}
    />
  );
};

const drawerBleeding = 56;

export const CreateUserSheet: FunctionComponent<Props> = ({
  isOpen,
  onClose,
}) => {
  return (
    <SwipeableDrawer
      anchor="bottom"
      open={isOpen}
      onClose={onClose}
      onOpen={() => {
        // do nothing
      }}
      swipeAreaWidth={drawerBleeding}
      disableSwipeToOpen={true}
      PaperProps={{
        sx: {
          borderTopLeftRadius: 10,
          borderTopRightRadius: 10,
        },
      }}
    >
      <Box
        sx={{
          maxWidth: 600,
          margin: "0 auto",
          position: "relative",
        }}
      >
        <Puller />
        <Box sx={{ p: 2 }}>
          <InnerSheet {...{ onClose, isOpen }} />
        </Box>
      </Box>
    </SwipeableDrawer>
  );
};

const InnerSheet: FunctionComponent<Props> = ({ onClose }) => {
  const queryClient = useQueryClient();
  const { showSnackbar } = useSnackbar();

  const {
    handleSubmit,
    reset: resetForm,
    control,
    formState: { errors },
  } = useForm<CreateUser>({
    defaultValues: {
      displayName: "",
      email: "",
      rights: [],
    },
  });

  const { mutate: createUser, isPending: isSaving } = useMutation({
    mutationFn: userAPI.createUser,
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [userAPI.QUERY_KEY],
      });
    },
  });

  const onSubmit = useCallback(
    (data: CreateUser) => {
      createUser(data, {
        onSuccess: () => {
          onClose();
          resetForm();
          showSnackbar("Created user", "success");
        },
        onError: () => {
          showSnackbar("Failed to create user", "error");
        },
      });
    },
    [createUser, onClose, resetForm, showSnackbar]
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        pt: 4,
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid
            size={{
              xs: 12,
              sm: 6,
            }}
          >
            <Controller
              name="email"
              control={control}
              rules={{
                required: true,
                pattern: {
                  value: /\S+@\S+\.\S+/,
                  message: "Entered value does not match email format",
                },
              }}
              render={({ field }) => (
                <TextField
                  fullWidth
                  label="Email"
                  id="email"
                  error={!!errors[field.name]}
                  helperText={
                    (errors[field.name]?.message || errors[field.name]?.type) ??
                    " "
                  }
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid
            size={{
              xs: 12,
              sm: 6,
            }}
          >
            <Controller
              name="displayName"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  fullWidth
                  label="Display Name"
                  id="displayName"
                  error={!!errors[field.name]}
                  helperText={
                    (errors[field.name]?.message || errors[field.name]?.type) ??
                    " "
                  }
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid size={12}>
            <Controller
              name="rights"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, name, value } }) => (
                <Autocomplete
                  onChange={(_, item) => {
                    onChange(item.map((i) => i.value));
                  }}
                  value={(value || [])
                    .map((v) =>
                      USER_RIGHT_ALTERNATIVES.find((a) => a.value === v)
                    )
                    .filter(
                      (v): v is (typeof USER_RIGHT_ALTERNATIVES)[number] =>
                        v !== undefined
                    )}
                  multiple
                  options={USER_RIGHT_ALTERNATIVES}
                  fullWidth
                  disableCloseOnSelect
                  getOptionLabel={(option) => option.label}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.label}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Rights"
                      placeholder="List of rights"
                      error={!!errors[name]}
                      helperText={
                        (errors[name]?.message || errors[name]?.type) ?? " "
                      }
                    />
                  )}
                />
              )}
            />
          </Grid>
          <Grid size={12}>
            <LoadingButton
              fullWidth
              type="submit"
              variant="contained"
              color="primary"
              loading={isSaving}
              size="large"
            >
              Create user
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};
