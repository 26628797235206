import { API, RequiredQueryOptions } from "./network/API";
import { Page, SortDirection } from "./network/Page";
import { UTCDate } from "./orderAPI";

export type PrintItemId = string & { isPrintItemId: true };

export interface PrintItem {
  id: PrintItemId;
  created: UTCDate;
  printed: UTCDate;
  totalItems: number;
}

const QUERY_KEY = "print";

export const printAPI = {
  QUERY_KEY: QUERY_KEY,
  fetchPrintQueue: (
    page: number,
    size: number,
    direction: SortDirection,
    field: string
  ): RequiredQueryOptions<Page<PrintItem>> => ({
    queryKey: [QUERY_KEY, page, size, direction, field],
    queryFn: () =>
      API.get<Page<PrintItem>>(
        `/admin/print?page=${page}&size=${size}&direction=${direction}&field=${field}`
      ),
  }),
};
