import { TableRow, TableCell, Typography } from "@mui/material";
import { FormattedDate } from "../../../components/FormattedDate/FormattedDate";
import { Attribution } from "../../../api/attributionAPI";
import { Flag } from "../../../components/Flag";
import { FormattedPrice } from "../../../components/FormattedPrice/FormattedPrice";
import { MuiLink } from "../../../components/MuiLink/MuiLink";
import { generatePath } from "react-router";
import { ORDER_LIST_PAGE_ROUTE_WITH_FILTER } from "../../Order/OrderListPage";
import { Link } from "react-router-dom";

interface Props {
  attribution: Attribution;
}

export const AttributionRow: React.FC<Props> = ({ attribution }) => {
  return (
    <TableRow>
      <TableCell>
        <MuiLink
          component={Link}
          to={generatePath(
            ORDER_LIST_PAGE_ROUTE_WITH_FILTER({
              attribution: attribution.name,
            })
          )}
        >
          {attribution.name}
        </MuiLink>
      </TableCell>
      <TableCell>
        <Flag country={attribution.country} />{" "}
      </TableCell>
      <TableCell align="right">{attribution.orderCount}</TableCell>
      <TableCell align="right">
        <FormattedPrice value={attribution.orderValue} />{" "}
      </TableCell>
      <TableCell align="right">
        <Typography noWrap variant="caption">
          <FormattedDate value={attribution.lastOrder} />
        </Typography>
      </TableCell>
    </TableRow>
  );
};
