import { Alert, AlertColor, Box, Snackbar } from "@mui/material";
import React, { createContext, useCallback } from "react";

export type SnackbarContextActions = {
  showSnackbar: (
    text: string,
    typeColor?: AlertColor,
    timeout?: number
  ) => void;
};

export const SnackbarContext = createContext({} as SnackbarContextActions);

interface SnackbarData {
  id: number;
  message: string;
  typeColor: AlertColor;
  timeout: number;
}

interface SnackbarContextProviderProps {
  children: React.ReactNode;
}

export const SnackbarProvider: React.FC<SnackbarContextProviderProps> = ({
  children,
}) => {
  const [snackbars, setSnackbars] = React.useState<SnackbarData[]>([]);

  const showSnackbar = useCallback(
    (text: string, color: AlertColor = "info", timeout = 3000) => {
      const newSnackbar = {
        id: new Date().getTime(), // Unique ID for the snackbar
        message: text,
        typeColor: color,
        timeout: timeout,
      };
      setSnackbars((prevSnackbars) => [...prevSnackbars, newSnackbar]);
    },
    []
  );

  const handleClose = useCallback((id: number) => {
    setSnackbars((prevSnackbars) =>
      prevSnackbars.filter((snackbar) => snackbar.id !== id)
    );
  }, []);

  return (
    <SnackbarContext.Provider value={{ showSnackbar }}>
      <Box
        sx={{
          display: "flex",
        }}
      >
        {snackbars.map((snackbar) => (
          <Snackbar
            key={snackbar.id}
            open
            autoHideDuration={snackbar.timeout}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            onClose={() => handleClose(snackbar.id)}
          >
            <Alert
              variant="filled"
              onClose={() => handleClose(snackbar.id)}
              severity={snackbar.typeColor}
            >
              {snackbar.message}
            </Alert>
          </Snackbar>
        ))}
      </Box>
      {children}
    </SnackbarContext.Provider>
  );
};
