import { FunctionComponent, useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  ListItemText,
  SxProps,
  TextField,
  Theme,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { LoadingButton } from "@mui/lab";
import { BaseOrder, orderAPI } from "../../api/orderAPI";
import { useDebounce } from "../../hooks/useDebounce";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { Flag } from "../Flag";
import { useBreakpointDown } from "../../hooks/useBreakpoint";

interface Props {
  onSelect: (order: BaseOrder) => void;
  onSearchError?: (error: Error) => void;
  sx?: SxProps<Theme>;
  additionalElements?: React.ReactNode;
}

export const Search: FunctionComponent<Props> = ({
  onSelect,
  onSearchError,
  sx,
  additionalElements,
}) => {
  const [search, setSearch] = useState<string>("");
  const isMobile = useBreakpointDown("sm");

  const debouncedSearch = useDebounce<string>(search, 250);

  const {
    data: results,
    error,
    isLoading,
    isFetched,
    refetch: triggerSearch,
  } = useQuery({
    ...orderAPI.searchOrders(0, 999, debouncedSearch),
    enabled: debouncedSearch.length > 0,
    placeholderData: keepPreviousData,
  });

  useEffect(() => {
    if (error) {
      onSearchError?.(error as Error);
    }
  }, [error, onSearchError]);

  return (
    <Box sx={sx}>
      <form
        onSubmit={(event) => {
          event.preventDefault();
          triggerSearch();
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "stretch",
            gap: 2,
            p: 1,
          }}
        >
          <Autocomplete
            freeSolo
            autoHighlight
            openOnFocus
            disableCloseOnSelect
            filterOptions={(options) => {
              return options;
            }}
            size={isMobile ? "small" : "medium"}
            options={results?.content ?? []}
            getOptionLabel={(option) =>
              typeof option === "string" ? option : option.orderId
            }
            renderOption={(props, option) => (
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              <Box {...props}>
                <ListItemText
                  primary={
                    <>
                      <Flag country={option.country} /> {option.orderId}
                    </>
                  }
                  secondary={[option.email, option.city]
                    .filter(Boolean)
                    .join(" - ")}
                />
              </Box>
            )}
            onChange={(_, value) => {
              if (!value || typeof value === "string") {
                return;
              }
              onSelect(value);
            }}
            sx={{ flex: 1 }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Search..."
                onChange={(event) => {
                  setSearch(event.target.value);
                }}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <Typography
                      variant="overline"
                      color="text.secondary"
                      sx={{
                        whiteSpace: "nowrap",
                        px: 1,
                      }}
                    >
                      {isFetched &&
                        results?.numberOfElements === 0 &&
                        "Not found"}
                      {search && results && results.numberOfElements > 0 && (
                        <span>{results.numberOfElements} hits</span>
                      )}
                    </Typography>
                  ),
                }}
              />
            )}
          />

          <LoadingButton
            variant="outlined"
            color="inherit"
            size="medium"
            type="submit"
            sx={{
              height: "auto",
              flexShrink: 0,
              "& .MuiButton-startIcon": {
                mr: 0,
              },
            }}
            startIcon={<SearchIcon />}
            loading={isLoading}
          />

          {additionalElements}
        </Box>
      </form>
    </Box>
  );
};
