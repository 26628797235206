import { FunctionComponent, useState } from "react";
import { Typography, Button, Box } from "@mui/material";
import { motion } from "framer-motion";
import FingerprintIcon from "@mui/icons-material/Fingerprint";
import { AuthTokenId } from "../../../api/authAPI";
import { useWebAuthNSession } from "../hooks/useWebAuthNSession";

interface Props {
  authTokenId: AuthTokenId;
  onFinished?: () => void;
}

export const RemoteAuthView: FunctionComponent<Props> = ({ authTokenId }) => {
  const { startAuth, state } = useWebAuthNSession({});

  const [greeting] = useState(
    GREETINGS[Math.floor(Math.random() * GREETINGS.length)]
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        flex: 1,
        m: 5,
      }}
    >
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
          component={motion.div}
          key={state}
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{
            type: "spring",
            stiffness: 100,
            damping: 8,
          }}
        >
          <Typography
            initial={{ rotate: 0 }}
            key="state"
            animate={{
              rotate:
                state === "PENDING" ? [0, 15, -15, 15, -15, 15, -15, 0] : 0,
            }}
            transition={{
              repeat: Infinity,
              repeatType: "mirror",
              duration: 10,
            }}
            sx={{
              transformOrigin: state === "PENDING" ? "bottom right" : "center",
            }}
            component={motion.div}
            variant="h2"
            align="center"
            gutterBottom
          >
            {state === "PENDING" ? "👋" : state === "FINISHED" ? "🎉" : "😵"}
          </Typography>
        </Box>
        {state === "FINISHED" && (
          <>
            <Typography
              variant="body1"
              align="center"
              fontWeight={600}
              marginBottom={3}
            >
              Sticky success!
            </Typography>
            <Typography variant="body2" align="center" color="textSecondary">
              You can now return to your other device.
            </Typography>
          </>
        )}
        {state === "PENDING" && (
          <>
            <Typography
              variant="body1"
              align="center"
              fontWeight={600}
              marginBottom={3}
            >
              {greeting.firstPart}
            </Typography>
            <Typography variant="body2" align="center" color="textSecondary">
              Authenticate to sign in on your other device.
            </Typography>
            <Box sx={{ my: 5 }}>
              <Button
                onClick={() => startAuth({ tokenId: authTokenId })}
                size="large"
                variant="contained"
                fullWidth
              >
                Authenticate login <FingerprintIcon sx={{ ml: 1 }} />
              </Button>
            </Box>
          </>
        )}
        {state === "ERROR" && (
          <>
            <Typography
              variant="body1"
              align="center"
              fontWeight={600}
              marginBottom={3}
            >
              Oops! Something went wrong.
            </Typography>
            <Typography variant="body2" align="center" color="textSecondary">
              Make sure this device is authenticated or try again.
            </Typography>
          </>
        )}
      </Box>
    </Box>
  );
};

const GREETINGS = [
  {
    firstPart: "Welcome to a world of color and creativity!",
    secondPart: "Sign in and start sticking to your dreams!",
  },
  {
    firstPart: "Ready to make your mark with some sticker magic?",
    secondPart: "Sign in and let's create something unforgettable!",
  },
  {
    firstPart: "Step into a world where your ideas stick!",
    secondPart: "Sign in and unleash your inner artist!",
  },
  {
    firstPart: "Get ready to peel, stick, and dazzle!",
    secondPart: "Sign in and join the fun of sticker storytelling!",
  },
  {
    firstPart: "Unlock the treasure chest of stickers!",
    secondPart: "Sign in and explore an array of adhesive art!",
  },
  {
    firstPart: "Sticker enthusiasts, assemble!",
    secondPart: "Sign in and let the sticking spree begin!",
  },
  {
    firstPart: "Ready to turn your visions into vinyls?",
    secondPart: "Sign in now and start your sticker journey!",
  },
];
