import {
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Grid2 as Grid,
  InputAdornment,
  MenuItem,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { Control, Controller, FieldErrors, useWatch } from "react-hook-form";
import { UpdateOfferRequest } from "../../../api/offerAPI";
import { currencyValidator } from "../../../utils";

interface Props {
  disableAll: boolean;
  control: Control<UpdateOfferRequest, unknown>;
  errors: FieldErrors<UpdateOfferRequest>;
  currency?: string;
}

export const OfferForm: React.FC<Props> = ({
  disableAll,
  control,
  errors,
  currency,
}) => {
  const includesVat = useWatch({ control, name: "includesVat" });
  const companyId = useWatch({ control, name: "companyId" });

  const [corporateCustomer, setCorporateCustomer] = useState<boolean>(
    companyId !== undefined
  );

  return (
    <>
      <Grid container spacing={2}>
        <Grid
          size={{
            xs: 12,
            lg: 8,
          }}
        >
          <Controller
            name="header"
            disabled={disableAll}
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                fullWidth
                size="medium"
                variant="standard"
                label="Project title"
                id="header"
                error={!!errors[field.name]}
                helperText={
                  (errors[field.name]?.message || errors[field.name]?.type) ??
                  " "
                }
                {...field}
              />
            )}
          />
        </Grid>
        <Grid
          size={{
            xs: 12,
            lg: 4,
          }}
        >
          <Controller
            name="language"
            disabled={disableAll}
            control={control}
            render={({ field }) => (
              <TextField
                fullWidth
                select
                defaultValue={"lv"}
                label="Language"
                id="language"
                error={!!errors[field.name]}
                helperText={
                  (errors[field.name]?.message || errors[field.name]?.type) ??
                  " "
                }
                {...field}
              >
                {LANGUAGES.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        </Grid>
        <Grid size={12}>
          <Controller
            name="description"
            control={control}
            disabled={disableAll}
            render={({ field }) => (
              <TextField
                multiline
                minRows={4}
                maxRows={16}
                fullWidth
                label="Description"
                id="description"
                error={!!errors[field.name]}
                helperText={
                  (errors[field.name]?.message || errors[field.name]?.type) ??
                  " "
                }
                {...field}
              />
            )}
          />
        </Grid>
        <Grid
          size={{
            xs: 6,
            lg: 3,
          }}
        >
          <Controller
            name="currency"
            control={control}
            disabled={disableAll}
            render={({ field }) => (
              <TextField
                fullWidth
                select
                defaultValue={"EUR"}
                label="Currency"
                id="currency"
                error={!!errors[field.name]}
                helperText={
                  (errors[field.name]?.message || errors[field.name]?.type) ??
                  " "
                }
                {...field}
              >
                {CURRENCIES.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        </Grid>
        <Grid
          size={{
            xs: 6,
            lg: 5,
          }}
        >
          <Controller
            name="price"
            control={control}
            disabled={disableAll}
            rules={{
              required: "Must specifiy price",
              validate: currencyValidator,
            }}
            render={({ field }) => (
              <TextField
                fullWidth
                label="Price"
                id="price"
                error={!!errors[field.name]}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">{currency}</InputAdornment>
                  ),
                }}
                helperText={
                  (errors[field.name]?.message || errors[field.name]?.type) ??
                  " "
                }
                {...field}
                onChange={(e) => {
                  const price = e.target.value.replaceAll(/[^\d,.]+/g, "");
                  const correctFormat = price.replace(",", ".");
                  field.onChange(correctFormat);
                }}
              />
            )}
          />
        </Grid>
        <Grid
          size={{
            xs: 6,
            lg: 4,
          }}
        >
          <Controller
            name="shippingCost"
            control={control}
            defaultValue={0}
            disabled={disableAll}
            rules={{
              validate: currencyValidator,
            }}
            render={({ field }) => (
              <TextField
                fullWidth
                label="Shipping cost"
                id="shippingCost"
                error={!!errors[field.name]}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">{currency}</InputAdornment>
                  ),
                }}
                helperText={
                  (errors[field.name]?.message || errors[field.name]?.type) ??
                  " "
                }
                {...field}
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} mt={1}>
        <Grid
          size={{
            xs: 12,
            lg: 6,
          }}
        >
          <Controller
            name="email"
            control={control}
            defaultValue={""}
            disabled={disableAll}
            rules={{ required: "Email is required" }}
            render={({ field }) => (
              <TextField
                fullWidth
                label="Customer email"
                id="email"
                type="email"
                error={!!errors[field.name]}
                helperText={
                  (errors[field.name]?.message || errors[field.name]?.type) ??
                  " "
                }
                {...field}
              />
            )}
          />
        </Grid>
        <Grid
          size={{
            xs: 12,
            lg: 6,
          }}
        >
          <Controller
            name="phone"
            control={control}
            defaultValue={""}
            disabled={disableAll}
            render={({ field }) => (
              <TextField
                fullWidth
                label="Customer phone"
                id="phone"
                type="tel"
                error={!!errors[field.name]}
                helperText={
                  (errors[field.name]?.message || errors[field.name]?.type) ??
                  " "
                }
                {...field}
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} mt={1}>
        <Grid
          size={{
            xs: 6,
            lg: 6,
          }}
        >
          <FormControlLabel
            value="end"
            disabled={disableAll}
            control={
              <Checkbox
                id="corporateCustomer"
                onChange={(e) => {
                  setCorporateCustomer(e.target.checked);
                }}
                checked={corporateCustomer}
              />
            }
            label="Corporate customer"
          />
        </Grid>
        <Grid
          size={{
            xs: 6,
            lg: 6,
          }}
        >
          <div>
            <FormControlLabel
              label="VAT included"
              control={
                <Controller
                  name="includesVat"
                  control={control}
                  disabled={disableAll}
                  render={({ field }) => (
                    <Checkbox
                      id="includesVat"
                      checked={field.value}
                      {...field}
                      disabled={!corporateCustomer}
                    />
                  )}
                />
              }
            />
            <FormHelperText error={!!errors.includesVat}>
              {(errors.includesVat?.message || errors.includesVat?.type) ??
                "VAT number is required if VAT is not included"}
            </FormHelperText>
          </div>
        </Grid>

        <Grid
          size={{
            xs: 12,
            lg: 12,
          }}
        >
          <Controller
            name="companyName"
            control={control}
            defaultValue={""}
            rules={{ required: corporateCustomer }}
            disabled={disableAll || !corporateCustomer}
            render={({ field }) => (
              <TextField
                fullWidth
                label="Company name"
                id="companyName"
                error={!!errors[field.name]}
                helperText={
                  (errors[field.name]?.message || errors[field.name]?.type) ??
                  " "
                }
                {...field}
              />
            )}
          />
        </Grid>
        <Grid
          size={{
            xs: 6,
            lg: 6,
          }}
        >
          <Controller
            name="companyId"
            control={control}
            defaultValue={""}
            rules={{ required: corporateCustomer && !includesVat }}
            disabled={disableAll || !corporateCustomer}
            render={({ field }) => (
              <TextField
                fullWidth
                label="Company reg"
                id="companyId"
                error={!!errors[field.name]}
                helperText={
                  (errors[field.name]?.message || errors[field.name]?.type) ??
                  " "
                }
                {...field}
              />
            )}
          />
        </Grid>
        <Grid
          size={{
            xs: 6,
            lg: 6,
          }}
        >
          <Controller
            name="vatNumber"
            control={control}
            defaultValue={""}
            disabled={disableAll || !corporateCustomer}
            rules={{ required: !includesVat }}
            render={({ field }) => (
              <TextField
                fullWidth
                label="VAT Nr"
                id="vatNumber"
                error={!!errors[field.name]}
                helperText={
                  (errors[field.name]?.message || errors[field.name]?.type) ??
                  " "
                }
                {...field}
              />
            )}
          />
        </Grid>
      </Grid>
    </>
  );
};

const CURRENCIES = [
  {
    value: "EUR",
    label: "EUR",
  },
  {
    value: "SEK",
    label: "SEK",
  },
  {
    value: "NOK",
    label: "NOK",
  },
  {
    value: "DKK",
    label: "DKK",
  },
];

const LANGUAGES = [
  { value: "lv", label: "Latvian" },
  { value: "en", label: "English" },
];
