import { Card, CardContent, Typography } from "@mui/material";
import { FunctionComponent, useMemo } from "react";

interface Props {
  label: string;
  value: number;
  type?: "currency" | "number" | "percent";
  decimals?: number;
  comparisonValue?: number;
  comparisonLabel?: string;
}

export const StatisticsCard: FunctionComponent<Props> = ({
  label,
  value,
  comparisonValue,
  comparisonLabel,
  type = "number",
  decimals = 2,
}) => {
  const formattedValue = useMemo(() => {
    if (type === "currency") {
      return Intl.NumberFormat("sv-SE", {
        style: "currency",
        currency: "EUR",
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals,
      }).format(value);
    } else if (type === "percent") {
      return `${value}%`;
    } else {
      return value.toLocaleString("sv-SE", {
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals,
      });
    }
  }, [type, value, decimals]);

  const change = comparisonValue
    ? ((value - comparisonValue) / comparisonValue) * 100
    : 0;

  return (
    <Card sx={{ height: "100%" }}>
      <CardContent>
        <Typography
          sx={{ fontSize: 16 }}
          color="text.secondary"
          fontWeight={700}
          gutterBottom
        >
          {label}
        </Typography>
        <Typography variant="h4" fontWeight={700}>
          {formattedValue}
        </Typography>
        {change !== 0 && (
          <>
            <Typography
              variant="caption"
              component="span"
              sx={{
                color: (theme) =>
                  change > 0
                    ? theme.palette.success.main
                    : theme.palette.error.main,
              }}
              fontWeight={500}
            >
              {change > 0 ? "+" : ""}
              {change.toFixed(0)}%{" "}
            </Typography>
            {comparisonValue && (
              <Typography
                variant="caption"
                color="text.secondary"
                component="span"
                fontWeight={500}
              >
                {comparisonLabel}
              </Typography>
            )}
          </>
        )}
      </CardContent>
    </Card>
  );
};
