import { useParams } from "react-router";
import { OfferId } from "../api/offerAPI";

type Param = {
  offerId: OfferId;
};

export function useOfferId() {
  const { offerId } = useParams<Param>();
  return offerId as OfferId;
}
