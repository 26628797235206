import { CampaignId } from "./campaignAPI";
import { API, RequiredQueryOptions } from "./network/API";
import { Page, SortDirection } from "./network/Page";
import { Voucher } from "./voucherAPI";

export type UTCDate = string & { isUTCDate: true };
export type InternalOrderId = string & { isInternalOrderId: true };
export type OrderId = string & { isOrderId: true };
export type OrderItemId = string & { isOrderItemId: true };
// export type Origin = string & { isOrigin: true };

export enum Origin {
  LOCAL_DEV = "LOCAL_DEV",
  SWEDEN = "SWEDEN",
  LATVIA = "LATVIA",
  INTERNATIONAL = "INTERNATIONAL",
}

export enum ProductType {
  NAME_STICKER = "NAME_STICKER",
  MINI_STICKER = "MINI_STICKER",
  DOOR_STICKER = "DOOR_STICKER",
}

export interface OrderItem {
  id: OrderItemId;
  count: number;
  price: number;
  // would be nice to rename to productType
  stickerType: ProductType;
  productImage?: string; //base64bild
  // TODO: TBD type this
  design?: Record<string, string>;
}

export interface DetailedOrder extends BaseOrder {
  /**
   * @description Alltid med, men kommer vara false om email inte finns
   */
  approvedMarketing: boolean;
  originalPrice: number;
  address?: Address;
  items: OrderItem[];
  itemCount: number;
  payment?: PaymentInfo;
  vouchers?: Voucher[];
}

export interface Address {
  name: string;
  street: string;
  streetAdditional?: string;
  postalCode: string;
  city: string;
  /**
   * @description Alpha2
   */
  country: string;
}

export interface PaymentInfo {
  transactionId: string;
  status: PaymentStatus;
}

export interface BaseOrder {
  id: InternalOrderId;
  orderId: OrderId;
  created: UTCDate;
  finalPrice: number;
  currency: string;
  campaignId?: CampaignId;
  paid?: UTCDate;
  printed?: UTCDate;
  sentToPost?: UTCDate;
  email: string;
  /**
   * @description The country of the order. Only available for completed orders.
   * @example "SE"
   */
  country?: string;
  /**
   * @description The city name. Only available for completed orders.
   */
  city?: string;
  /**
   * @description The domain from which the order was placed.
   */
  origin: Origin;
  /**
   * @description The attribution of the order e.g. ad network, affiliate, etc.
   */
  attribution?: string;
}

export type BaseOrderFilters = Partial<{
  country: string;
  includeUnpaid: boolean;
  campaignId: string;
  origin: string;
  attribution: string;
}>;

interface Attribution {
  name: string;
  lastOrder: UTCDate;
  orderCount: number;
}

export enum PaymentStatus {
  OPEN = "OPEN",
  SUCCESS = "SUCCESS",
  FAILED = "FAILED",
  PENDING = "PENDING",
}

export const PAYMENT_STATUS_LABEL: Record<PaymentStatus, string> = {
  [PaymentStatus.OPEN]: "Open",
  [PaymentStatus.SUCCESS]: "Success",
  [PaymentStatus.FAILED]: "Failed",
  [PaymentStatus.PENDING]: "Pending",
};

const QUERY_KEY = "orders";

export const orderAPI = {
  QUERY_KEY: QUERY_KEY,
  fetchOrder: (orderId: OrderId): RequiredQueryOptions<DetailedOrder> => ({
    queryKey: [QUERY_KEY, { orderId }],
    queryFn: () => API.get<DetailedOrder>(`/admin/order/${orderId}`),
  }),

  fetchOrders: (
    page: number,
    size: number,
    direction: SortDirection,
    field: keyof BaseOrder,
    filters?: BaseOrderFilters
  ): RequiredQueryOptions<Page<BaseOrder>> => ({
    queryKey: [QUERY_KEY, { page, size, direction, field, filters }],
    queryFn: () => {
      const filterParams = Object.entries(filters ?? {})
        .filter(([, value]) => value !== undefined)
        .map(([key, value]) => {
          return `${key}=${value}`;
        });

      return API.get<Page<BaseOrder>>(
        `/admin/order?page=${page}&size=${size}&direction=${direction}&field=${field}&${filterParams.join(
          "&"
        )}`
      );
    },
  }),

  fetchOrdersByEmail: (
    page: number,
    size: number,
    direction: SortDirection,
    field: keyof BaseOrder,
    email: string
  ): RequiredQueryOptions<Page<BaseOrder>> => ({
    queryKey: [QUERY_KEY, { page, size, direction, field, email }],
    queryFn: () => {
      return API.get<Page<BaseOrder>>(
        `/admin/order?page=${page}&size=${size}&direction=${direction}&field=${field}&email=${email}`
      );
    },
  }),

  fetchAttributions: (): RequiredQueryOptions<Attribution[]> => ({
    queryKey: [QUERY_KEY, "attributions"],
    queryFn: () => API.get<Attribution[]>("/admin/order/attribution"),
  }),

  searchOrders: (
    page: number,
    size: number,
    query: string
  ): RequiredQueryOptions<Page<BaseOrder>> => ({
    queryKey: [QUERY_KEY, "search", { page, size, query }],
    queryFn: () =>
      API.get<Page<BaseOrder>>(
        `/admin/order/search?page=${page}&size=${size}&keyword=${query}`
      ),
    staleTime: 1000 * 60,
  }),

  markAsSent: (orderId: OrderId) =>
    API.post<void>(`/admin/order/${orderId}/sent-to-post`),

  getOrderPdf: (orderId: OrderId, itemId: OrderItemId) =>
    API.getBlob(`/admin/order/${orderId}/reprint/download?itemId=${itemId}`),
};
