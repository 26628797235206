import React, { useMemo } from "react";
import { UTCDate } from "../../api/orderAPI";

interface Props {
  value: string | UTCDate | undefined;
}

export const FormattedDate: React.FC<Props> = ({ value }) => {
  const formattedDate = useMemo(
    () =>
      value
        ? new Date(value).toLocaleDateString("sv-SE", {
            year: "numeric",
            month: "numeric",
            day: "numeric",
          })
        : "-",
    [value]
  );

  return <>{formattedDate}</>;
};
