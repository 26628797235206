import { useSuspenseQueries } from "@tanstack/react-query";
import { useCampaignId } from "../../hooks/useCampaignId";
import { Layout } from "../../layout/Layout";
import { campaignAPI } from "../../api/campaignAPI";
import { Helmet } from "../../components/Helmet";
import { LayoutBreadcrumbs } from "../../components/Breadcrumbs/LayoutBreadcrumbs";
import { CAMPAIGN_LIST_PAGE_ROUTE } from "./CampaignListPage";
import {
  Box,
  Card,
  CardContent,
  Grid2 as Grid,
  Typography,
} from "@mui/material";
import { MuiButtonLink } from "../../components/MuiLink/MuiLink";
import { generatePath } from "react-router";
import { ORDER_LIST_PAGE_ROUTE_WITH_FILTER } from "../Order/OrderListPage";
import { PropsWithChildren, ReactNode } from "react";
import { FormattedPrice } from "../../components/FormattedPrice/FormattedPrice";
import { useBreakpointDown } from "../../hooks/useBreakpoint";

export const CAMPAIGN_PAGE_ROUTE = "/campaigns/:campaignId";

export const CampaignPage = () => {
  return (
    <Layout title="Campaign">
      <Inner />
    </Layout>
  );
};

const Inner = () => {
  const campaignId = useCampaignId();

  const [{ data: campaign }] = useSuspenseQueries({
    queries: [
      campaignAPI.fetchCampaign(campaignId),
      // ... do more related campaign queries if needed
    ],
  });

  return (
    <>
      <Helmet title={campaign.campaignId} />
      <LayoutBreadcrumbs
        breadcrumbs={[
          { label: "Campaigns", to: CAMPAIGN_LIST_PAGE_ROUTE },
          { label: campaign.campaignId },
        ]}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          my: 4,
          gap: 2,
          flexDirection: { xs: "column", sm: "row" },
        }}
      >
        <Typography variant="h5" component="h1" fontWeight={600}>
          Campaign{" "}
          <Typography variant="inherit" color="primary" display="inline">
            {campaign.campaignId}
          </Typography>
        </Typography>
        <MuiButtonLink
          to={generatePath(
            ORDER_LIST_PAGE_ROUTE_WITH_FILTER({
              campaignId,
            })
          )}
          variant="outlined"
          size="small"
        >
          View orders by campaign
        </MuiButtonLink>
      </Box>
      <Grid container spacing={2}>
        <Grid container spacing={2} size={12}>
          <Grid
            size={{
              xs: 6,
              sm: 3,
            }}
          >
            <FeatureCard label="Total orders">{campaign.count}</FeatureCard>
          </Grid>
          <Grid
            size={{
              xs: 6,
              sm: 3,
            }}
          >
            <FeatureCard label="Total order value">
              <FormattedPrice currency="EUR" value={campaign.amount} />
            </FeatureCard>
          </Grid>
        </Grid>
        <Grid container spacing={2} size={12}>
          <Grid size={12}>
            <Card>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Campaign information
                </Typography>
                <Typography>Nothing here yet</Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

const FeatureCard = ({
  label,
  // icon,
  children,
}: PropsWithChildren<{
  label: string;
  icon?: ReactNode;
}>) => {
  const isMobile = useBreakpointDown("sm");

  return (
    <Card>
      <CardContent>
        <Typography
          variant="overline"
          color="text.secondary"
          lineHeight={1}
          noWrap
          display="block"
        >
          {label}
        </Typography>
        <Typography
          variant={isMobile ? "h5" : "h4"}
          component="h4"
          fontWeight={600}
          sx={{
            mt: 1,
          }}
        >
          {children}
        </Typography>
      </CardContent>
    </Card>
  );
};
