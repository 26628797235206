import { useState, useCallback } from "react";
import {
  AuthSessionRequest,
  authAPI,
  isLoginSession,
  isRegisterSession,
} from "../../../api/authAPI";
import {
  parseRequestOptionsFromJSON,
  parseCreationOptionsFromJSON,
  get,
  create,
} from "@github/webauthn-json/browser-ponyfill";

interface UseAuthProps {
  onFinished?: () => void;
}

const initFn = authAPI.initWebAuthNSession;

export const useWebAuthNSession = ({ onFinished }: UseAuthProps) => {
  const [state, setState] = useState<"FINISHED" | "PENDING" | "ERROR">(
    "PENDING"
  );

  const startAuth = useCallback(
    async (request: AuthSessionRequest) => {
      setState("PENDING");
      try {
        const session =
          "tokenId" in request
            ? await initFn({
                tokenId: request.tokenId,
              })
            : await initFn({
                email: request.email,
              });

        if (isLoginSession(session)) {
          const options = parseRequestOptionsFromJSON(session);
          const credential = await get(options);

          await authAPI.login(session.requestId, credential);
        }
        if (isRegisterSession(session)) {
          const options = parseCreationOptionsFromJSON(session);
          const credential = await create(options);

          await authAPI.register(session.requestId, credential);
        }

        setState("FINISHED");
        onFinished?.();
      } catch (err) {
        setState("ERROR");
        console.log(err);
      }
    },
    [onFinished]
  );

  const reset = useCallback(() => {
    setState("PENDING");
  }, []);

  return { startAuth, state, reset };
};
