import { useTheme } from "@mui/material";
import { useEffect } from "react";

export const ThemeFix = () => {
  const theme = useTheme();

  useEffect(() => {
    const themeColor = theme.palette.background.default;
    const metaThemeColor = document.querySelector("meta[name=theme-color]");
    metaThemeColor?.setAttribute("content", themeColor);
  }, [theme.palette]);

  useEffect(() => {
    const html = document.querySelector("html");
    html?.style.setProperty("color-scheme", theme.palette.mode);
  }, [theme.palette]);

  return null;
};
