import { Button, Skeleton, Box } from "@mui/material";
import { QueryErrorResetBoundary } from "@tanstack/react-query";
import React, { PropsWithChildren, Suspense } from "react";
import { ErrorBoundary } from "react-error-boundary";

interface Props {
  skeleton: React.ReactNode;
  gap?: number;
}

export const SuspenseWrapper: React.FC<PropsWithChildren<Props>> = ({
  children,
  skeleton,
  gap = 0,
}) => {
  return (
    <QueryErrorResetBoundary>
      {({ reset }) => (
        <>
          <ErrorBoundary
            onReset={reset}
            fallbackRender={({ resetErrorBoundary }) => (
              <>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                  }}
                >
                  <Button onClick={resetErrorBoundary} sx={{ mt: 2 }}>
                    Try again
                  </Button>
                </Box>
              </>
            )}
          >
            <Suspense
              fallback={
                skeleton ? (
                  <Box sx={{ display: "flex", flexDirection: "column", gap }}>
                    {skeleton}
                  </Box>
                ) : (
                  <>
                    <h1>
                      <Skeleton variant="text" />
                    </h1>
                    <div>
                      <Skeleton variant="rectangular" />
                      <Skeleton variant="rectangular" height={120} />
                    </div>
                  </>
                )
              }
            >
              {children}
            </Suspense>
          </ErrorBoundary>
        </>
      )}
    </QueryErrorResetBoundary>
  );
};
