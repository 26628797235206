import { SortDirection } from "./api/network/Page";
import { OrderId } from "./api/orderAPI";

const numberAndStringSorter = <T>(a: T, b: T, direction: SortDirection) => {
  if (a === b) return 0;
  if (a === undefined) return direction === SortDirection.DESC ? 1 : -1;
  if (b === undefined) return direction === SortDirection.DESC ? -1 : 1;

  if (typeof a === "number" && typeof b === "number") {
    return direction === SortDirection.DESC ? b - a : a - b;
  }

  if (typeof a === "string" && typeof b === "string") {
    if (Date.parse(a) && Date.parse(b)) {
      const dateA = new Date(a).getTime();
      const dateB = new Date(b).getTime();
      return direction === SortDirection.DESC ? dateB - dateA : dateA - dateB;
    }
    return direction === SortDirection.DESC
      ? b.localeCompare(a)
      : a.localeCompare(b);
  }

  return 0;
};

export const createSortFunction = <T>(
  sortField: keyof T,
  sortDirection: SortDirection
) => {
  return (a: T, b: T): number => {
    const aValue = a[sortField];
    const bValue = b[sortField];
    return numberAndStringSorter(aValue, bValue, sortDirection);
  };
};

const intlRegionNames = new Intl.DisplayNames(["en"], { type: "region" });

export const countryFromCountryCode = (code: string) => {
  return intlRegionNames.of(code);
};

export function isValidOrderId(orderId: OrderId): boolean {
  const cleaned = orderId.replace(/[^A-Za-z0-9]/g, "").toUpperCase();
  if (cleaned.length !== 8) {
    return false;
  }

  let base = (cleaned.charCodeAt(0) - 65) * 26;
  base += cleaned.charCodeAt(1) - 65;
  //Vi justerar upp så att bokstäverna alltid är dom högre siffrorna
  base *= 100000;
  base += parseInt(cleaned.substring(2, 7));
  const checksum = parseInt(cleaned.charAt(7));
  const calculated = calculateLuhn(base);

  return checksum === calculated;
}

function calculateLuhn(value: number): number {
  const valueAsStr = "" + value;

  const length = valueAsStr.length;
  let sum = 0;
  let alternate = false;

  for (let i = length - 1; i >= 0; i--) {
    let digit = parseInt(valueAsStr.charAt(i));
    if (alternate) {
      digit *= 2;
      if (digit > 9) {
        digit -= 9;
      }
    }

    sum += digit;
    alternate = !alternate;
  }
  return sum % 10;
}

export const numberValidator = (value?: string | number): string | true => {
  if (typeof value === "undefined") {
    return true;
  }

  if (isNaN(Number(value))) {
    return "Must be a number";
  }

  if (!/^\d+$/.test(value.toString())) {
    return "Must be an integer";
  }

  if (Number(value) % 1 !== 0) {
    return "Must be an integer";
  }

  return true;
};

export const currencyValidator = (value?: string | number): string | true => {
  if (typeof value === "undefined") {
    return true;
  }

  if (isNaN(Number(value))) {
    return "Must be a number";
  }

  return true;
};