import { QueryKey, UseQueryOptions } from "@tanstack/react-query";
import { LOGIN_PAGE_ROUTE } from "../../pages/Auth/LoginPage";

export const BACKEND = import.meta.env.VITE_REACT_APP_TARGET_BACKEND;

function actAndDelagate<T>(err: ServerError<T>) {
  if (err.status === 401) {
    if (window.location.pathname !== LOGIN_PAGE_ROUTE) {
      window.location.href = LOGIN_PAGE_ROUTE;
    }
  }
  return Promise.reject(err);
}

export interface RequiredQueryOptions<T>
  extends UseQueryOptions<T, unknown, T, QueryKey> {
  queryKey: QueryKey;
  queryFn: () => Promise<T>;
}

const sharedHeaders = {};

export class ServerError<T> extends Error {
  public status: number;
  public statusText: string;
  public data: T;

  constructor(status: number, statusText: string, data: T) {
    super(`Server error - ${status}: ${statusText}`);
    this.status = status;
    this.statusText = statusText;
    this.data = data;
  }
}

async function handleResponse<T>(response: Response): Promise<T> {
  if (!response.ok) {
    if ((response as unknown as Error).message === "Failed to fetch") {
      throw new ServerError<T>(0, "Failed to fetch", {} as T);
    }
    const resolvedData: string = await response.text();

    throw new ServerError<string>(
      response.status,
      response.statusText,
      resolvedData
    );
  }

  const contentType = response.headers.get("content-type");
  let data;

  if (contentType && contentType.indexOf("application/json") !== -1) {
    data = response.json();
  } else if (contentType && contentType.indexOf("application/pdf") !== -1)
    data = response.blob();
  else {
    data = response.text();
  }

  return data;
}

class Api {
  static fetch<T>(url: string, options: RequestInit = {}): Promise<T> {
    return fetch(Api.getUrl(url), {
      mode: "cors",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        ...sharedHeaders,
      },
      ...options,
    }).then(handleResponse<T>);
  }

  static get<T>(url: string, options: RequestInit = {}): Promise<T> {
    return fetch(Api.getUrl(url), {
      credentials: "include",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        ...sharedHeaders,
      },
      ...options,
    }).then(handleResponse<T>);
  }

  static getBlob(url: string): Promise<BlobResponse> {
    return fetch(Api.getUrl(url), {
      credentials: "include",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        ...sharedHeaders,
      },
    }).then((response) => {
      const contentDisposition = response.headers.get("Content-Disposition");
      console.log(response.headers);
      console.log(response.headers.get("content-type"));

      const resolveFilename = (contentDisposition: string | null) => {
        if (!contentDisposition) {
          return undefined;
        }
        const startOfFilname = contentDisposition.indexOf('filename="') + 10;
        const endOfFilename = contentDisposition.indexOf('"', startOfFilname);
        return contentDisposition.substring(startOfFilname, endOfFilename);
      };
      const filename = resolveFilename(contentDisposition);
      return response.blob().then((blob) => {
        return {
          blob,
          filename,
        } as BlobResponse;
      });
    });
  }

  static postFormData<T>(
    url: string,
    data: FormData,
    options: RequestInit = {}
  ): Promise<T> {
    return fetch(Api.getUrl(url), {
      method: "POST",
      mode: "cors",
      credentials: "include",
      body: data,
      headers: {
        //"Content-Type": undefined,
        ...sharedHeaders,
      },
      ...options,
    }).then(handleResponse<T>);
  }

  static post<T>(
    url: string,
    data?: object,
    options: RequestInit = {}
  ): Promise<T> {
    return fetch(Api.getUrl(url), {
      method: "POST",
      mode: "cors",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        ...sharedHeaders,
      },
      body: JSON.stringify(data),
      ...options,
    }).then(handleResponse<T>);
  }

  static put<T>(
    url: string,
    data?: object,
    options: RequestInit = {}
  ): Promise<T> {
    return fetch(Api.getUrl(url), {
      method: "PUT",
      mode: "cors",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        ...sharedHeaders,
      },
      body: JSON.stringify(data),
      ...options,
    }).then(handleResponse<T>);
  }

  static delete<T>(
    url: string,
    data?: object,
    options: RequestInit = {}
  ): Promise<T> {
    return fetch(Api.getUrl(url), {
      method: "DELETE",
      mode: "cors",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        ...sharedHeaders,
      },
      body: JSON.stringify(data),
      ...options,
    }).then(handleResponse<T>);
  }

  static getUrl(url: string) {
    if (url.indexOf("http") === 0) {
      return url;
    }

    return (BACKEND || "") + url;
  }
}

export const API = {
  get: <T>(url: string, options?: RequestInit) => {
    return Api.get<T>(url, options).catch(actAndDelagate);
  },

  getWithoutAuth: Api.get,

  post: <T, Y extends object = NonNullable<unknown>>(url: string, body?: Y) => {
    return Api.post<T>(url, body).catch(actAndDelagate);
  },

  postFormData: <T>(url: string, body: FormData) => {
    return Api.postFormData<T>(url, body).catch(actAndDelagate);
  },

  delete: <T>(url: string, body?: object) => {
    return Api.delete<T>(url, body).catch(actAndDelagate);
  },

  getBlob: Api.getBlob,
};

export const ping = () => API.get("/");
// export const ping = () => Promise.resolve();

// Stale time 3 minutes, can be overridden by passing staleTime to query options
// By default we use 0 stale time, so that we always fetch the latest data
// All queries are however cached by default but will trigger a refetch on mount
// This means we display the cached data on mount and then refetch the latest data and replace
// the cached data with the latest data once available
export const STALE_TIME = 1000 * 60 * 3;

export interface BlobResponse {
  blob: Blob;
  filename?: string;
}
