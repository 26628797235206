import { API, RequiredQueryOptions } from "./network/API";
import { UTCDate } from "./orderAPI";

export type UserId = string & { isUserId: true };

export enum UserRight {
  PRINT = "PRINT",
  REBATE = "REBATE",
  USERS = "USERS",
  VOUCHER = "VOUCHER",
  OFFERS = "OFFERS",
}

const USER_RIGHT_LABELS: Record<UserRight, string> = {
  [UserRight.PRINT]: "Print",
  [UserRight.REBATE]: "Rebate",
  [UserRight.USERS]: "Users",
  [UserRight.VOUCHER]: "Vouchers",
  [UserRight.OFFERS]: "Offers",
};

export const USER_RIGHT_ALTERNATIVES = Object.entries(USER_RIGHT_LABELS).map(
  ([value, label]) => ({ value, label })
);

export interface User {
  userId: UserId;
  email: string;
  displayName: string;
  rights: UserRight[];
  created: UTCDate;
  lastLogin?: UTCDate;
}

export type CreateUser = Pick<User, "email" | "displayName" | "rights">;

export type UpdateUser = Pick<
  User,
  "email" | "displayName" | "rights" | "userId"
>;

const QUERY_KEY = "users";

export const userAPI = {
  QUERY_KEY,
  fetchUsers: (): RequiredQueryOptions<User[]> => ({
    queryKey: [QUERY_KEY],
    queryFn: () => API.get<User[]>(`/admin/users`),
  }),

  createUser: (newUser: CreateUser) =>
    API.post<User[]>(`/admin/users`, newUser),

  updateUser: (updatedUser: UpdateUser) =>
    API.post<User[]>(`/admin/users/update`, updatedUser),

  deleteUserAuth: (userId: UserId) =>
    API.delete(`/admin/users/${userId}/login`),

  // deleteUser: (userId: UserId) => API.delete(`/admin/users/${userId}`),
};
