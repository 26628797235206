import { Box, useMediaQuery, useTheme } from "@mui/material";
import { useState } from "react";
import { Outlet } from "react-router";
import { Sidebar, DRAWER_WIDTH } from "../layout/Sidebar";
import { BottomBar } from "../layout/BottomBar";
import { ThemeFix } from "../layout/ThemeFix";
import { GlobalSearchProvider } from "../state/GlobalSearchContext";
import { ScrollRestoration } from "react-router-dom";
import { DASHBOARD_PAGE_ROUTE } from "../pages/Dashboard/DashboardPage";

export const AppRoot = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const theme = useTheme();
  const showSidebar = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <>
      <ScrollRestoration
        getKey={(location) => {
          const paths = [DASHBOARD_PAGE_ROUTE];
          return paths.includes(location.pathname)
            ? location.pathname
            : // everything else by location like the browser
              location.key;
        }}
      />
      <Box
        sx={{
          ml: showSidebar ? `${DRAWER_WIDTH}px` : 0,
        }}
      >
        <GlobalSearchProvider>
          <ThemeFix />
          <Sidebar
            isOpen={isSidebarOpen}
            onClose={() => setIsSidebarOpen(false)}
            isNarrow={!showSidebar}
          />
          <Outlet />
          {!showSidebar && (
            <BottomBar
              isMobile={!showSidebar}
              onOpenSidebar={() => setIsSidebarOpen(true)}
            />
          )}
        </GlobalSearchProvider>
      </Box>
    </>
  );
};
