import { API, RequiredQueryOptions } from "./network/API";
import { UTCDate } from "./orderAPI";

export interface Attribution {
  name: string;
  country: string;
  lastOrder: UTCDate;
  orderCount: number;
  orderValue: number;
}

const QUERY_KEY = "attributions";

export const attributionAPI = {
  QUERY_KEY: QUERY_KEY,

  fetchAttributions: ({
    from,
    to,
  }: {
    from?: string;
    to?: string;
  }): RequiredQueryOptions<Attribution[]> => ({
    queryKey: [QUERY_KEY, from, to],
    queryFn: () => {
      const params = new URLSearchParams();
      if (from) params.append("from", from);
      if (to) params.append("to", to);
      const queryString = params.toString() ? `?${params.toString()}` : "";
      return API.get<Attribution[]>(
        `/admin/attribution/overview${queryString}`
      );
    },
  }),
};
