import {
  Typography,
  useTheme,
  Box,
  TextField,
  Button,
  Grid2 as Grid,
} from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useCallback, useEffect, useMemo } from "react";
import { useNavigate, generatePath } from "react-router";
import { AuthTokenStatus, authAPI } from "../../../api/authAPI";
import { REMOTE_AUTH_ROUTE } from "../RemoteAuthPage";
import { QRCode } from "react-qrcode-logo";
import { useForm } from "react-hook-form";
import { LoadingButton } from "@mui/lab";

const POLLING_INTERVAL = 3000;

interface Props {
  email?: string;
}

export const LoginViewDesktop: React.FC<Props> = ({ email }) => {
  const navigate = useNavigate();
  const { palette } = useTheme();

  const {
    data,
    mutate: initRemoteAuthSession,
    reset: resetRemoteAuthSession,
    isPending: isLoadingSession,
  } = useMutation({
    mutationFn: authAPI.initRemoteAuthSession,
  });

  const { data: result } = useQuery({
    ...(data?.tokenId
      ? authAPI.getRemoteAuthSession(data.tokenId)
      : { queryKey: [] }),
    enabled: typeof data?.tokenId !== "undefined",
    refetchInterval: POLLING_INTERVAL,
  });

  useEffect(() => {
    if (result?.status === AuthTokenStatus.CONFIRMED) {
      navigate("/");
    }
  }, [result, navigate]);

  const url = useMemo(() => {
    if (!data?.tokenId) {
      return undefined;
    }
    return (
      window.location.origin +
      generatePath(REMOTE_AUTH_ROUTE, {
        authTokenId: data.tokenId,
      })
    );
  }, [data]);

  const { handleSubmit, register } = useForm<{ email: string }>({
    defaultValues: { email: email || "" },
  });

  const onSubmit = useCallback(
    (data: { email: string }) => {
      initRemoteAuthSession(data.email);
    },
    [initRemoteAuthSession]
  );

  // for debugging
  useEffect(() => {
    if (!url) return;
    console.info("Authentication url: ", url);
  }, [url]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: data?.tokenId ? "center" : "stretch",
        justifyContent: "center",
        flex: 1,
      }}
    >
      {data?.tokenId ? (
        <>
          <QRCode
            qrStyle="dots"
            value={url}
            size={300}
            fgColor={palette.text.primary}
            bgColor="transparent"
            eyeColor={{
              inner: palette.text.primary,
              outer: palette.secondary.light,
            }}
            eyeRadius={[
              [30, 30, 10, 30],
              [30, 30, 30, 10],
              [30, 10, 30, 30],
            ]}
          />

          <Typography
            variant="overline"
            align="center"
            gutterBottom
            sx={{ mt: 4 }}
          >
            Please scan the QR code above on your authenticated phone to sign in
          </Typography>

          <Button
            variant="text"
            color="secondary"
            onClick={() => {
              resetRemoteAuthSession();
            }}
            sx={{ mt: 4 }}
            fullWidth
          >
            Cancel
          </Button>

          {/* For debugging */}
          {/* <MuiLink
            align="center"
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            color="secondary"
            sx={{ fontSize: "0.8rem", mt: 4 }}
          >
            {url}
          </MuiLink> */}
        </>
      ) : (
        <>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container direction="column" spacing={4}>
              <Grid>
                <TextField
                  fullWidth
                  label="Email"
                  id="email"
                  {...register("email", {
                    required: true,
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: "Entered value does not match email format",
                    },
                  })}
                  type="email"
                  required
                />
              </Grid>
              <Grid>
                <LoadingButton
                  fullWidth
                  type="submit"
                  variant="contained"
                  color="primary"
                  loading={isLoadingSession}
                >
                  Login
                </LoadingButton>
              </Grid>
            </Grid>
          </form>
        </>
      )}
    </Box>
  );
};
